import {ProjectUploadModel} from "../../../../../../models/responses/project.model";
import {Button, Icon, Modal} from "semantic-ui-react";
import React from "react";
import {useProjectListSelector, useProjectUploadSelector} from "../../../../../../store/selectors/project.selectors";
import fetch from "node-fetch";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../../../firebase/constants";


export default function NavBarUploadModal(props: {
    upload: ProjectUploadModel,
    setShowUploadModal: any
}) {

    const uploads = useProjectUploadSelector()
    const upload = uploads.find((upload) => upload.uploadId === props.upload.uploadId)
    const projectList = useProjectListSelector();

    const handleRetry = async (uploadId: string) => {
        console.log("Retrying...");
        let clientId: any = null;
        if (upload!.projectId) {
            const projectData = projectList.find((project) => project.projectId === upload!.projectId)
            if (projectData) {
                clientId = projectData.clientId
            }
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECT_UPLOADS, uploadId, {
            status: 'pending',
            message: 'Upload pending',
            progress: 0,
            cancelled: false
        })
        try {
            fetch(`https://project-parser-854414998693.us-central1.run.app/?uploadId=${uploadId}&clientId=${clientId || null}`)
                .catch(e => console.log(e))
        } catch (e) {
            console.error(e)
        }
    }

    const handleCancel = async (uploadId: string) => {
        console.log("Cancelling...");
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECT_UPLOADS, uploadId, {
            status: 'cancelled',
            message: 'Upload cancelled',
            progress: 0,
            cancelled: true
        })
    }

    const handleDelete = async (uploadId: string) => {
        console.log("Deleting...");
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECT_UPLOADS, uploadId, {
            status: 'deleted',
        })
        props.setShowUploadModal(null)
    }

    return (
        upload ? <Modal closeIcon open={true} onClose={() => props.setShowUploadModal(null)}>
            <Modal.Header>
                {upload.fileName}
            </Modal.Header>
            <Modal.Content>
                <div>
                    {upload.status === 'pending' && <strong>Waiting for upload to start</strong>}
                    {upload.status === 'inProgress' && <p>Upload in progress</p>}
                    {upload.status === 'complete' && <p>Upload complete</p>}
                    {upload.status === 'error' && <p>Upload failed</p>}
                    {upload.status === 'cancelled' && <strong>Upload cancelled</strong>}
                    {upload.status === 'inProgress' &&
                        <div className={'upload-modal-progress-bar'}>
                           <div
                                 className={'upload-modal-progress-bar-fill'}
                                style={{width: `${upload.progress}%`}}></div>
                        </div>
                        }
                    {upload.status === 'pending' && <div className={'upload-loader'}></div>}
                    {upload.status === 'inProgress' && <div className={'upload-modal-status'}>
                        <p>{upload.message}</p>
                    </div>}
                    {upload.status === 'pending' && <div className={'upload-modal-status'}>
                        <p>Updates will appear here when the upload commences</p>
                    </div>}
                    {upload.status === 'error' && <div className={'upload-modal-status error'}>
                        <p>{upload.message}</p>
                    </div>}
                    {upload.status === 'cancelled' && <div className={'upload-modal-status error'}>
                        <p>User has cancelled upload. Please delete or retry the upload process.</p>
                    </div>}
                </div>
            </Modal.Content>
            <Modal.Actions>
                {upload.status === 'cancelled' || upload.status === 'error' ?
                    <Button onClick={() => handleRetry(upload.uploadId)} positive><Icon name={'redo'}/>Retry</Button>
                    : null
                }
                {upload.status === 'cancelled' || upload.status === 'error' ?
                    <Button negative
                            onClick={() => handleDelete(upload.uploadId)}
                    ><Icon name={'trash'}
                    />Delete</Button>
                    : null
                }
                {upload.status === 'inProgress' || upload.status === 'pending' ?
                    <Button negative
                            onClick={() => handleCancel(upload.uploadId)}
                    ><Icon name={'cancel'}
                    />Cancel</Button>
                    : null
                }
                <Button>Close</Button>
            </Modal.Actions>
        </Modal>
        : null
    )
}