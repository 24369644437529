import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useTypedSelector} from "../../../../../store/selectors/selectors.utils";
import {TaskPredecessors} from "./TaskPredecessors";
import {TaskSuccessors} from "./TaskSuccessors";
import {TaskListSection} from "./TaskListSection/TaskListSection";
import {TaskListSectionModel} from "../../../../../models/task-list-section.model";
import InfiniteScroll from "react-infinite-scroller";
import {useDispatch} from "react-redux";
import * as taskActions from "../../../../../store/actions/task.actions";
import * as projectActions from "../../../../../store/actions/project.actions";
import {Button, Dropdown, Loader} from "semantic-ui-react";
import {
    useActiveDataVersionSelector,
    useActiveProjectCalendarsSelector,
    useActiveProjectSelector,
    useCpmMapSelector,
    useDataVersionsSelector,
    useProjectViewSelector, useSprintDataSelector,
    useViewWidthSelector
} from "../../../../../store/selectors/project.selectors";
import {
    useIncludeMilestonesSelector,
    useSearchParamatersSelector
} from "../../../../../store/selectors/search.selectors";
import TaskModel from "../../../../../models/responses/task.model";
import GoogleMapVisGL from "../../../components/GoogleMap/GoogleMapVisGL";
import filterTaskList from "../../../../../utils/task-filtering";
import TaskStatusModel from "../../../../../models/responses/task-status.model";
import {
    useAllTaskListSelector,
    useNextSnapshotSelector,
    useTaskSnapshotSelector
} from "../../../../../store/selectors/task/task.selectors";
import {subsManager} from "../../../../../store/middleware/subs-manager/subs-manager";
import {TaskType} from "../../../../../models/task-type";
import {convertIndexToSeconds} from "../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonRunningFast, faCirclePlay, faCircleStop, faChevronLeft, faChevronRight} from "@fortawesome/pro-solid-svg-icons";
import {faAngleDown, faAngleUp} from "@fortawesome/pro-light-svg-icons";
import moment from "moment";
import {SprintCategory, SprintDataModel} from "../../../../../models/sprint.model";
import classNames from "classnames";
import WeeklySprintChart from "../../../components/SprintChart/WeeklySprintChart";
import {faChartSimple} from "@fortawesome/free-solid-svg-icons";
import useCountdown from "../../../../../utils/useCountdown";

export const SprintTaskList = () => {
    const [view, setView] = useState<"future" | "past">("future");
    const [sortCriteria, setSortCriteria] = useState<"index" | "deadline" | "target">("index");
    const activeProject = useActiveProjectSelector();
    const dispatch = useDispatch();
    const projectView = useProjectViewSelector();

    let now = new Date();
    now.setMinutes(0, 0, 0);

    const loading = useTypedSelector((state) => state.task.taskListLoading);
    const moreTasks = useTypedSelector((state) => state.task.moreTasks);
    const searchParams = useSearchParamatersSelector();
    const allTasks = useAllTaskListSelector();
    const includeMilestones = useIncludeMilestonesSelector();
    const dataVersions = useDataVersionsSelector();
    const viewWidth = useViewWidthSelector();

    const [filteredArchivedTaskList, setFilteredArchivedTaskList] = useState<TaskModel[]>([]);
    const [filteredDeclaredTaskList, setFilteredDeclaredTaskList] = useState<TaskModel[]>([]);
    const [filteredInProgressTaskList, setFilteredInProgressTaskList] = useState<TaskModel[]>([]);
    const [filteredQueuedTaskList, setFilteredQueuedTaskList] = useState<TaskModel[]>([]);
    const [archivedOpen, setArchivedOpen] = useState(false);
    const [declaredOpen, setDeclaredOpen] = useState(false);
    const [inProgressOpen, setInProgressOpen] = useState(true);
    const [queuedOpen, setQueuedOpen] = useState(false);
    const [sprintPopup, setSprintPopup] = useState<any>(null);
    const [archivedTaskCount, setArchivedTaskCount] = useState(0);
    const [declaredTaskCount, setDeclaredTaskCount] = useState(0);
    const [inProgressTaskCount, setInProgressTaskCount] = useState(0);
    const [queuedTaskCount, setQueuedTaskCount] = useState(0);
    const [tasksDueToStartCount, setTasksDueToStartCount] = useState(0);
    const [tasksDueToFinishCount, setTasksDueToFinishCount] = useState(0);
    const [tasksStartedCount, setTasksStartedCount] = useState(0);
    const [sprintStartedAheadCount, setSprintStartedAheadCount] = useState(0);
    const [sprintFinishedAheadCount, setSprintFinishedAheadCount] = useState(0);
    const [tasksFinishedCount, setTasksFinishedCount] = useState(0);
    const [unplannedStartedCount, setUnplannedStartedCount] = useState(0);
    const [unplannedFinishedCount, setUnplannedFinishedCount] = useState(0);
    const [unplannedStartedAheadCount, setUnplannedStartedAheadCount] = useState(0);
    const [unplannedFinishedAheadCount, setUnplannedFinishedAheadCount] = useState(0);
    const [selectedSprintFilter, setSelectedSprintFilter] = useState<any>([]);
    const [filtersLoaded, setFiltersLoaded] = useState(false);
    const [showChart, setShowChart] = useState<string | null>(null);
    const taskSnapshot = useTaskSnapshotSelector();
    const nextSnapshot = useNextSnapshotSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    const cpmMap = useCpmMapSelector();
    const activeDataVersion = useActiveDataVersionSelector();
    const sprintData = useSprintDataSelector();
    const [liveSprint, setLiveSprint] = useState<boolean>(false);
    const [allFilteredTasks, setAllFilteredTasks] = useState<TaskModel[]>([]);

    const [didNotStart, setDidNotStart] = useState<TaskModel[]>([]);
    const [started, setStarted] = useState<TaskModel[]>([]);
    const [finished, setFinished] = useState<TaskModel[]>([]);
    const [didNotFinish, setDidNotFinish] = useState<TaskModel[]>([]);
    const [progressed, setProgressed] = useState<TaskModel[]>([]);
    const [didNotProgress, setDidNotProgress] = useState<TaskModel[]>([]);
    const [showLists, setShowLists] = useState<any>({
        s: false,
        dns: false,
        f: false,
        dnf: false,
        p: false,
        dnp: false
    })
    // const [interactionData, setInteractionData] = useState<any>(null);

    let topOfContent = 0;
    const content = document.getElementById('sprint-tasks');
    if (content) {
        topOfContent = content.getBoundingClientRect().top;
    }

    const sprintDataMap: any = useMemo(() => {
        let sprintDataMap = new Map<string, SprintDataModel>();
        if (sprintData.length > 0) {
            sprintData.forEach((sprint: SprintDataModel) => {
                let tasksMap = new Map<string, SprintCategory>();
                sprint.sprintTasks.forEach((task) => {
                    tasksMap.set(task.taskCode, task.type);
                });
                sprintDataMap.set(sprint.dataVersionId, {
                    ...sprint, sprintTasks: tasksMap
                });
            });
        }
        return sprintDataMap;
    }, [sprintData]);

    useEffect(() => {
        if (activeDataVersion && activeDataVersion.index === 0) {
            setLiveSprint(true);
        } else {
            setLiveSprint(false);
        }
    }, [activeDataVersion]);

    // 1500 min width for showing other information on tasks
    useEffect(() => {
        subsManager.subscribeSprintData(activeProject?.contractId || "");
    }, []);

    useEffect(() => {
        if (sprintDataMap.size > 0) {
            const periodStart = new Date((Math.floor(activeDataVersion.version.dataVersionDate.seconds / 86400) * 86400) * 1000);
            const cutOffDate = new Date(periodStart.getTime() + (7 * 86400000));
            let archivedTaskList: TaskModel[] = [];
            let declaredTaskList: TaskModel[] = [];
            let inProgressTaskList: TaskModel[] = [];
            let archivedTaskCount = 0;
            let declaredTaskCount = 0;
            let inProgressTaskCount = 0;
            let tasksStartCount = 0;
            let tasksFinishCount = 0;
            let startedTasksCount = 0;
            let finishedTasksCount = 0;
            let sprintFinishedAhead = 0;
            let unplannedFinished = 0;
            let unplannedStarted = 0;
            let unplannedFinishedAhead = 0;
            let sprintStartedAhead = 0;
            let unplannedStartedAhead = 0;
            let queuedTaskList: TaskModel[] = [];
            let queuedTaskCount = 0;
            let interactionCount = 0;
            let allTasksCount = 0;
            let s: TaskModel[] = []
            let dns: TaskModel[] = []
            let f: TaskModel[] = []
            let dnf: TaskModel[] = []
            let p: TaskModel[] = []
            let dnp: TaskModel[] = []
            let liveSprint = activeDataVersion.index === 0;

            const allTasksList = filterTaskList(allTasks, searchParams, cpmMap, projectCalendars)
            const activeSprint = sprintDataMap.get(activeDataVersion.version.dataVersionId);

            // let sprintTasksPrint: any = []
            // activeSprint.sprintTasks.forEach((task, key) => {
            //     sprintTasksPrint.push(key);
            // })
            //
            // console.log(sprintTasksPrint)

            allTasksList
                .filter((task: TaskModel) => {
                        const taskInSprint = liveSprint ?
                            activeProject?.sprintLocked ?
                                task.sprint === activeDataVersion.version.dataVersionId : !!cpmMap.get(task.task_id).sprintCategory :
                            activeSprint && activeSprint.sprintTasks.has(task.task_code)
                        if (!taskInSprint
                            && !(task.act_end_date && task.act_end_date.toDate().getTime() >= periodStart.getTime() && task.act_end_date.toDate().getTime() < cutOffDate.getTime())
                            && !(task.act_start_date && task.act_start_date.toDate().getTime() >= periodStart.getTime() && task.act_start_date.toDate().getTime() < cutOffDate.getTime())
                            && !(task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.toDate().getTime() >= periodStart.getTime() && task.declaredCompleteTimestamp.toDate().getTime() < cutOffDate.getTime())
                            && !(task.finishSprintId && task.finishSprintId === activeDataVersion.version.dataVersionId)
                            && !(task.startSprintId && task.startSprintId === activeDataVersion.version.dataVersionId)
                        ) return false;
                        return !(!includeMilestones && task.task_type !== TaskType.TT_RSRC && task.task_type !== TaskType.TT_TASK);
                    }
                )
                .forEach((task: TaskModel) => {
                    let included = false;
                    const cpmTask = cpmMap.get(task.task_id);
                    const taskInSprint = liveSprint ?
                        activeProject?.sprintLocked ?
                        task.sprint === activeDataVersion.version.dataVersionId : !!cpmTask.sprintCategory :
                            activeSprint && activeSprint.sprintTasks.has(task.task_code);
                    const sprintCategory = liveSprint ?
                        activeProject?.sprintLocked ?
                            task.sprintCategory : cpmTask.sprintCategory :
                        activeSprint?.sprintTasks.get(task.task_code);

                    const taskActualEndDate = task.act_end_date ? task.act_end_date : task.declaredCompleteTimestamp ? task.declaredCompleteTimestamp : null;
                    const taskActualStartDate = task.act_start_date

                    if (taskInSprint) {
                        if (sprintCategory === SprintCategory.START || sprintCategory === SprintCategory.START_AND_FINISH) {
                            tasksStartCount++;
                            if (taskActualStartDate && taskActualStartDate.toDate().getTime() >= periodStart.getTime() &&
                                taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                                startedTasksCount++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('start')) {
                                    included = true;
                                }
                            } else if (taskActualStartDate && taskActualStartDate.toDate().getTime() < periodStart.getTime()) {
                                sprintStartedAhead++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('sprint-start-early')) {
                                    included = true;
                                }
                            } else {
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('incomplete-start')) {
                                    included = true;
                                }
                            }
                            if (sprintCategory !== SprintCategory.START_AND_FINISH && taskActualEndDate &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                unplannedFinished++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish-unplanned')) {
                                    included = !selectedSprintFilter.length || !included;
                                }
                            }
                        }
                        if (sprintCategory === SprintCategory.FINISH || sprintCategory === SprintCategory.START_AND_FINISH) {
                            tasksFinishCount++;
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() >= periodStart.getTime() &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime()) {
                                finishedTasksCount++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish')) {
                                    included = true;
                                }
                            } else if (taskActualEndDate && taskActualEndDate.toDate().getTime() < periodStart.getTime()) {
                                sprintFinishedAhead++;
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('sprint-finish-early')) {
                                    included = true;
                                }
                            } else {
                                if (!selectedSprintFilter.length || selectedSprintFilter.includes('incomplete-finish')) {
                                    included = true;
                                }
                            }
                        }
                    } else {
                        if (taskActualStartDate && taskActualStartDate.toDate().getTime() < periodStart.getTime()
                            && task.startSprintId === activeDataVersion.version.dataVersionId) {
                            unplannedStartedAhead++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('unplanned-start-early')) {
                                included = true;
                            }
                        } else if (taskActualStartDate && taskActualStartDate.toDate().getTime() >= periodStart.getTime() &&
                            taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                            unplannedStarted++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('start-unplanned')) {
                                included = !(task.sprint === activeDataVersion.version.dataVersionId);
                            }
                        }
                        if (taskActualEndDate &&
                            taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                            taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                            unplannedFinished++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('finish-unplanned')) {
                                included = !selectedSprintFilter.length || !included;
                            }
                        }
                        else if (taskActualEndDate && taskActualEndDate.toDate().getTime() < periodStart.getTime() &&
                            task.finishSprintId && task.finishSprintId === activeDataVersion.version.dataVersionId) {
                            unplannedFinishedAhead++;
                            if (!selectedSprintFilter.length || selectedSprintFilter.includes('unplanned-finish-early')) {
                                included = true;
                            }
                        }
                    }

                    if (selectedSprintFilter.length === 0) {
                        included = true;
                    }

                    if (!included) return;

                    allTasksCount++;
              
                    // check if task.interation is of type array
                    // if (task.interaction && !Array.isArray(task.interaction)) {
                    //     // console.log(task)
                    //     interactionCount++
                    // } else if (task.interaction && task.interaction.includes(activeDataVersion.version.dataVersionId)) {
                    //     interactionCount++;
                    // }


                    // add tasks to the correct list
                    if (liveSprint) {
                        if (task.status === TaskStatusModel.COMPLETE) {
                            archivedTaskList.push(task);
                            archivedTaskCount++;
                        } else if (task.status === TaskStatusModel.DECLARED_COMPLETE) {
                            declaredTaskList.push(task);
                            declaredTaskCount++;
                        } else if (cpmTask.flow || task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                            inProgressTaskList.push(task);
                            inProgressTaskCount++;
                        } else {
                            queuedTaskList.push(task);
                            queuedTaskCount++;
                        }
                        if (sortCriteria === "index") {
                            inProgressTaskList = inProgressTaskList.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                            queuedTaskList = queuedTaskList.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                        } else if (sortCriteria === "deadline") {
                            inProgressTaskList.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                            queuedTaskList.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                        } else if (sortCriteria === "target") {
                            inProgressTaskList = inProgressTaskList.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                            queuedTaskList = queuedTaskList.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                        }
                    } else {
                        if (sprintCategory === SprintCategory.START) {
                            if (taskActualStartDate && taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                                s.push(task);
                            } else {
                                dns.push(task);
                            }
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                f.push(task);
                            }
                        } else if (sprintCategory === SprintCategory.START_AND_FINISH) {
                            if (taskActualStartDate && taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                                s.push(task);
                            } else {
                                dns.push(task);
                            }
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                f.push(task);
                            } else {
                                dnf.push(task);
                            }
                        } else if (sprintCategory === SprintCategory.FINISH) {
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                f.push(task);
                            } else {
                                dnf.push(task);
                            }
                        } else if (sprintCategory === SprintCategory.PROGRESS) {
                            if ((task.progress && task.progress.filter(p =>
                                p.date.seconds * 1000 >= periodStart.getTime() &&
                                p.date.seconds * 1000 < cutOffDate.getTime()).length > 0) ||
                                (taskActualEndDate && taskActualEndDate.toDate().getTime() >= periodStart.getTime() &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime())
                            ) {
                                p.push(task);
                            } else {
                                dnp.push(task);
                            }
                        } else if (taskActualStartDate && taskActualStartDate.toDate().getTime() < cutOffDate.getTime() &&
                            taskActualStartDate.toDate().getTime() >= periodStart.getTime()) {
                            s.push(task);
                            if (taskActualEndDate &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime()) {
                                f.push(task);
                            }
                        } else if (task.startSprintId === activeDataVersion.version.dataVersionId) {
                            s.push(task);
                            if (task.finishSprintId === activeDataVersion.version.dataVersionId) {
                                f.push(task);
                            } else if (taskActualEndDate && taskActualEndDate.toDate().getTime() >= periodStart.getTime() &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime()) {
                                f.push(task);
                            }
                        } else if (taskActualEndDate && taskActualEndDate.toDate().getTime() >= periodStart.getTime() &&
                            taskActualEndDate.toDate().getTime() < cutOffDate.getTime()) {
                            f.push(task);
                        } else if (task.finishSprintId === activeDataVersion.version.dataVersionId) {
                            f.push(task);
                        }
                        if (sortCriteria === "index") {
                            s = s.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                            dns = dns.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                            dnf = dnf.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                            p = p.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                            dnp = dnp.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                if (!aTask || !bTask) return 0;
                                if (!aTask.index && bTask.index) {
                                    return -1;
                                } else if (aTask.index && !bTask.index) {
                                    return 1;
                                } else if (aTask.index && bTask.index) {
                                    return aTask.index - bTask.index;
                                }
                                return 0;
                            });
                        } else if (sortCriteria === "deadline") {
                            s = s.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                            dns = dns.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                            dnf = dnf.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                            p = p.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                            dnp = dnp.sort((a, b) => {
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aLateEndDate = convertIndexToSeconds(aTask.lf, aTask.cal_id, projectCalendars);
                                const bLateEndDate = convertIndexToSeconds(bTask.lf, bTask.cal_id, projectCalendars);
                                return aLateEndDate - bLateEndDate;
                            });
                        } else if (sortCriteria === "target") {
                            s = s.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                            dns = dns.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                            dnf = dnf.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                            p = p.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                            dnp = dnp.sort((a, b) => {
                                if (a.targetFinishDate) {
                                    if (b.targetFinishDate) {
                                        return a.targetFinishDate.seconds - b.targetFinishDate.seconds;
                                    }
                                    return -1;
                                }
                                if (b.targetFinishDate) {
                                    return 1;
                                }
                                const aTask = cpmMap.get(a.task_id);
                                const bTask = cpmMap.get(b.task_id);
                                const aEarly = convertIndexToSeconds(aTask.ef, aTask.cal_id, projectCalendars);
                                const bEarly = convertIndexToSeconds(bTask.ef, bTask.cal_id, projectCalendars);
                                return aEarly - bEarly;
                            });
                        }

                    }
                });

            setAllFilteredTasks(allTasksList);
            setFilteredArchivedTaskList(archivedTaskList);
            setFilteredDeclaredTaskList(declaredTaskList);
            setFilteredInProgressTaskList(inProgressTaskList);
            setFilteredQueuedTaskList(queuedTaskList);
            setArchivedTaskCount(archivedTaskCount);
            setDeclaredTaskCount(declaredTaskCount);
            setInProgressTaskCount(inProgressTaskCount);
            setQueuedTaskCount(queuedTaskCount);
            setTasksDueToStartCount(tasksStartCount);
            setTasksDueToFinishCount(tasksFinishCount);
            setTasksStartedCount(startedTasksCount);
            setTasksFinishedCount(finishedTasksCount);
            setSprintStartedAheadCount(sprintStartedAhead);
            setSprintFinishedAheadCount(sprintFinishedAhead);
            setUnplannedStartedAheadCount(unplannedStartedAhead);
            setUnplannedFinishedAheadCount(unplannedFinishedAhead);
            setUnplannedStartedCount(unplannedStarted);
            setUnplannedFinishedCount(unplannedFinished);
            setFiltersLoaded(true);
            setDidNotStart(dns);
            setStarted(s);
            setFinished(f);
            setDidNotFinish(dnf);
            setProgressed(p);
            setDidNotProgress(dnp);
            // setInteractionData({
            //     allTasksCount,
            //     interactionCount
            // })
        }
    }, [searchParams, sortCriteria, taskSnapshot, nextSnapshot, allTasks, selectedSprintFilter, sprintDataMap, activeDataVersion, activeProject]);

    const openPredecessors = useTypedSelector(
        (state) => state.task.predecessors.open
    );
    const openSuccessors = useTypedSelector(
        (state) => state.task.successors.open
    );
    const loadMoreHandler = useCallback(
        (page: number) => {
            if (view === "future") {
                if (!loading.QueuedIsLoading && moreTasks.queued) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.QUEUED
                        )
                    );
                }
            } else {
                if (
                    !loading.ConfirmedCompleteIsLoading &&
                    moreTasks.confirmedComplete
                ) {
                    dispatch(
                        taskActions.Actions.loadMoreTaskList(
                            page,
                            TaskListSectionModel.CONFIRMED_COMPLETE
                        )
                    );
                }
            }
        },
        [
            dispatch,
            loading.ConfirmedCompleteIsLoading,
            loading.QueuedIsLoading,
            moreTasks.confirmedComplete,
            moreTasks.queued,
            view,
        ]
    );

    if (openPredecessors) {
        return <TaskPredecessors />;
    }

    if (openSuccessors) {
        return <TaskSuccessors />;
    }

    const totalStartCount = Math.max(
        tasksDueToStartCount + sprintStartedAheadCount + unplannedStartedCount + unplannedStartedAheadCount,
        tasksStartedCount + sprintStartedAheadCount + unplannedStartedCount
    );
    const totalFinishCount = Math.max(
        tasksDueToFinishCount + sprintFinishedAheadCount + unplannedFinishedCount + unplannedFinishedAheadCount,
        tasksStartedCount + sprintFinishedAheadCount + unplannedFinishedCount
    );

    // const interactionPercentage = interactionData ? Math.round((interactionData.interactionCount / interactionData.allTasksCount) * 100) : 0

    const timePercentage = Math.round((new Date().getTime() - activeDataVersion.version.dataVersionDate.toDate().getTime()) / (7 * 86400000) * 100);
    // const timePercentage = 0

    const {countdown} = useCountdown(activeProject?.sprintLockTimestamp && !activeProject?.sprintLocked ? Math.floor(((activeProject.sprintLockTimestamp.toDate().getTime() - new Date().getTime())) / 1000) : 0);

    function handleChangeOfActiveDataVersion (i: number, direction: string) {
        const index = i + (direction === "back" ? 1 : -1);
        const dataVersion = dataVersions[index];
        if (dataVersion) dispatch(projectActions.Actions.setActiveDataVersion({version: dataVersion, index: index}));
    }

    return (
        <div className={"sprint-page-container"} style={{minWidth: "100%"}} id={"sprint-page"}>
            {countdown > 0 && !activeProject?.sprintLocked && <div className={"sprint-lockdown-container"}>Sprint will lockdown in {Math.floor(countdown / 60 / 60).toString().padStart(2, "0")}:
                {Math.floor(countdown / 60 % 60).toString().padStart(2, "0")}:{Math.floor(countdown % 60).toString().padStart(2, "0")} please make any changes to your planned workflow now
            </div>}
            <div className="sprint-summary-container">
                <span className="sprint-summary-figures">
                    <div className="sprint-summary-total">
                        Start {<FontAwesomeIcon
                        icon={faCirclePlay}
                        style={{color: "#0F9D58FF", marginLeft: "5px", fontSize: "20px"}}
                        />}
                        <div className="sprint-total-div">
                            {sprintStartedAheadCount > 0 &&
                                <div className={classNames("sprint-progress-bar-finish-early", {
                                    'active-filter': selectedSprintFilter.includes('sprint-start-early')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: true,
                                         early: true,
                                         count: sprintStartedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('sprint-start-early') ? prevState.filter((el: string) =>
                                             el !== 'sprint-start-early') : [...prevState, 'sprint-start-early'])}
                                     style={{
                                         minWidth: `${Math.min(100, sprintStartedAheadCount / totalStartCount) * 100}%`,
                                     }}>
                                    <p className="progress-total-inside">{sprintStartedAheadCount}</p>
                                </div>}
                            {tasksStartedCount > 0 &&
                                <div className={classNames("sprint-progress-bar-start", {
                                    'active-filter': selectedSprintFilter.includes('start')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: true,
                                         count: tasksStartedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('start') ? prevState.filter((el: string) =>
                                             el !== 'start') : [...prevState, 'start'])}
                                     style={{
                                         minWidth: `${Math.min(100, tasksStartedCount / totalStartCount * 100)}%`,
                                     }}>
                                    <p className="progress-total-inside">{tasksStartedCount}</p>
                                </div>}
                            {tasksStartedCount < tasksDueToStartCount || tasksDueToStartCount === 0 ?
                                <div className={classNames("incomplete-sprint", {
                                    'active-filter': selectedSprintFilter.includes('incomplete-start')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         incomplete: true,
                                         count: tasksDueToStartCount - tasksStartedCount - sprintStartedAheadCount,
                                         start: true,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('incomplete-start') ? prevState.filter((el: string) =>
                                             el !== 'incomplete-start') : [...prevState, 'incomplete-start'])}>
                                    {tasksDueToStartCount - tasksStartedCount - sprintStartedAheadCount > 0 &&
                                        <p className="progress-total-inside">{tasksDueToStartCount - tasksStartedCount - sprintStartedAheadCount}</p>}
                                </div> : null
                            }
                            {unplannedStartedCount > 0 &&
                                <div className={classNames("sprint-progress-bar-start-unplanned", {
                                    'active-filter': selectedSprintFilter.includes('start-unplanned')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: false,
                                         count: unplannedStartedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('start-unplanned') ? prevState.filter((el: string) =>
                                             el !== 'start-unplanned') : [...prevState, 'start-unplanned'])}
                                     style={{
                                         minWidth: `${Math.min(100, (unplannedStartedCount) / totalStartCount * 100)}%`
                                     }}>
                                    <p className="progress-total-inside">{unplannedStartedCount}</p>
                                </div>}
                            {unplannedStartedAheadCount > 0 &&
                                <div className={classNames("non-sprint-progress-bar-finish-early", {
                                    'active-filter': selectedSprintFilter.includes('unplanned-start-early')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: false,
                                         planned: false,
                                         early: true,
                                         count: unplannedStartedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('unplanned-start-early') ? prevState.filter((el: string) =>
                                             el !== 'unplanned-start-early') : [...prevState, 'unplanned-start-early'])}
                                     style={{
                                         minWidth: `${Math.min(100, unplannedStartedAheadCount / totalStartCount) * 100}%`,
                                     }}>
                                    <p className="progress-total-inside">{unplannedStartedAheadCount}</p>
                                </div>}

                            {/*<div className="finish-line">*/}
                            {/*    <span>*/}
                            {/*        <p>{`${tasksDueToStartCount}|`}</p>*/}
                            {/*        <FontAwesomeIcon icon={faGun}*/}
                            {/*                         className="finish-line-icon"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="sprint-summary-total"
                         // style={{
                         //     marginLeft: "-20px",
                         //     paddingRight: "15px"
                         // }}
                    >
                        Complete {<FontAwesomeIcon
                        icon={faCircleStop}
                        style={{color: "#4285F4FF", marginLeft: "5px", fontSize: "20px"}}
                    />}
                        <div className="sprint-total-div">
                            {sprintFinishedAheadCount > 0 &&
                                <div className={classNames("sprint-progress-bar-finish-early", {
                                    'active-filter': selectedSprintFilter.includes('sprint-finish-early')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: true,
                                         early: true,
                                         count: sprintFinishedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('sprint-finish-early') ? prevState.filter((el: string) =>
                                             el !== 'sprint-finish-early') : [...prevState, 'sprint-finish-early'])}
                                     style={{
                                         minWidth: `${Math.min(100, (sprintFinishedAheadCount / totalFinishCount) * 100)}%`,
                                     }}>
                                    <p className="progress-total-inside">{sprintFinishedAheadCount}</p>
                                </div>}
                            {tasksFinishedCount > 0 &&
                                <div className={classNames("sprint-progress-bar-finish", {
                                    'active-filter': selectedSprintFilter.includes('finish')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: true,
                                         count: tasksFinishedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('finish') ? prevState.filter((el: string) =>
                                             el !== 'finish') : [...prevState, 'finish'])}
                                     style={{
                                         minWidth: `${Math.min(100, tasksFinishedCount / totalFinishCount * 100)}%`,
                                     }}>
                                    <p className="progress-total-inside">{tasksFinishedCount}</p>
                                </div>}
                            {tasksFinishedCount < tasksDueToFinishCount || tasksDueToFinishCount === 0 ?
                                <div className={classNames("incomplete-sprint", {
                                    'active-filter': selectedSprintFilter.includes('incomplete-finish')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         incomplete: true,
                                         count: tasksDueToFinishCount - tasksFinishedCount - sprintFinishedAheadCount,
                                         start: false,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('incomplete-finish') ? prevState.filter((el: string) =>
                                             el !== 'incomplete-finish') : [...prevState, 'incomplete-finish'])}>
                                    {tasksDueToFinishCount - tasksFinishedCount - sprintFinishedAheadCount > 0 &&
                                        <p className="progress-total-inside">{tasksDueToFinishCount - tasksFinishedCount - sprintFinishedAheadCount}</p>}
                                </div> : null
                            }
                            {unplannedFinishedCount > 0 &&
                                <div className={classNames("sprint-progress-bar-finish-unplanned", {
                                    'active-filter': selectedSprintFilter.includes('finish-unplanned')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: false,
                                         count: unplannedFinishedCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('finish-unplanned') ? prevState.filter((el: string) =>
                                             el !== 'finish-unplanned') : [...prevState, 'finish-unplanned'])}
                                     style={{
                                         minWidth: `${Math.min(100, unplannedFinishedCount / totalFinishCount * 100)}%`,
                                     }}>
                                    <p className="progress-total-inside">{unplannedFinishedCount}</p>
                                </div>}
                            {unplannedFinishedAheadCount > 0 &&
                                <div className={classNames("non-sprint-progress-bar-finish-early", {
                                    'active-filter': selectedSprintFilter.includes('unplanned-finish-early')
                                })}
                                     onMouseOver={(e) => setSprintPopup({
                                         finish: true,
                                         planned: false,
                                         early: true,
                                         count: unplannedFinishedAheadCount,
                                         x: e.clientX,
                                         y: e.clientY
                                     })}
                                     onMouseOut={() => setSprintPopup(null)}
                                     onClick={() => setSelectedSprintFilter(prevState =>
                                         prevState.includes('unplanned-finish-early') ? prevState.filter((el: string) =>
                                             el !== 'unplanned-finish-early') : [...prevState, 'unplanned-finish-early'])}
                                     style={{
                                         minWidth: `${Math.min(100, (unplannedFinishedAheadCount / totalFinishCount) * 100)}%`,
                                     }}>
                                    <p className="progress-total-inside">{unplannedFinishedAheadCount}</p>
                                </div>}
                            {/*<div className="finish-line">*/}
                            {/*    <span>*/}
                            {/*        <p>{`${tasksDueToFinishCount}|`}</p>*/}
                            {/*        <FontAwesomeIcon icon={faFlagCheckered}*/}
                            {/*                         className="finish-line-icon"*/}
                            {/*        />*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </span>
            </div>
            <div className="sprint-summary-container">
                <div className="sprint-timeline-container">
                    <div className={"sprint-timeline"}>
                        {/*{((new Date().getTime() - activeDataVersion.version.dataVersionDate.toDate().getTime()) / 6048000) < 100 ?*/}
                        {/*    <div className="sprint-timeline-progress"*/}
                        {/*         style={{width: `calc(${(new Date().getTime() - activeDataVersion.version.dataVersionDate.toDate().getTime()) / 6048000}% - 20px)`}}></div> :*/}
                        {/*    <div className="sprint-timeline-progress full" style={{width: `calc(100% - 20px)`}}></div>}*/}
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).format('ddd DD/MM/YYYY')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(1, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(1, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(2, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(2, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(3, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(3, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(4, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(4, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(5, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(5, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-day"}>
                            <p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(6, 'day').format('ddd DD')}</p>
                            {/*<p>{moment(activeDataVersion.version.dataVersionDate.toDate().toString()).add(6, 'day').format('DD')}</p>*/}
                        </div>
                        <div className={"sprint-back"}
                             onClick={() => handleChangeOfActiveDataVersion(activeDataVersion.index, "back")}>
                            <FontAwesomeIcon icon={faChevronLeft}/></div>
                        {activeDataVersion.index > 0 && <div className={"sprint-forward"}
                                                            onClick={() => handleChangeOfActiveDataVersion(activeDataVersion.index, "forward")}
                        ><FontAwesomeIcon icon={faChevronRight}/></div>}
                    </div>
                    {/*<div className="interaction-gauge">*/}
                    {/*    <div className="interaction-total"*/}
                    {/*         style={{width: `${(interactionPercentage > 100 ? 100 : interactionPercentage)}%`,*/}
                    {/*             opacity: 0.7,*/}
                    {/*             borderRight: interactionPercentage < 100 ? '1px solid #000000' : 'none',*/}
                    {/*             backgroundColor: interactionPercentage < 50 ? '#D9001BFF' :*/}
                    {/*                 interactionPercentage > 80 ? '#0F9D58FF' : '#F4B400FF'*/}
                    {/*         }}>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className={"sprint-progress-line"}>
                <div className={"sprint-progress-fill"}
                     style={liveSprint ? {width: `calc(${(timePercentage * viewWidth) / 100}px + 10px)`} : {width: "100%"}}
                ></div>
                {liveSprint && <div
                    className="sprint-rocket-button"
                    style={{left: `calc(${(timePercentage * viewWidth) / 100}px - 2.5px)`}}
                    onClick={() => setShowChart(prevState => prevState ? null : 'start')}>
                    <FontAwesomeIcon
                        className="sprint-button-icon"
                        icon={faPersonRunningFast}
                    />
                </div>}
            </div>
            {showChart && <div className="sprint-accordion">
                <div className="sprint-accordion-title"
                     onClick={() => setShowChart(prevState => prevState ? null : 'start')}>
                    <FontAwesomeIcon className="chevron-icon" icon={showChart ? faAngleUp : faAngleDown}/>
                    <div className="sprint-accordion-label">Weekly Sprint Chart</div>
                    {showChart && <div
                        className="sprint-switch-container"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <span className="sprint-switch-title">Starts</span>
                        <label className="sprint-switch">
                            <input
                                className="sprint-switch-input"
                                type="checkbox"
                                checked={showChart === 'finish'}
                                onChange={() => {
                                    setShowChart(prevState => prevState === 'start' ? 'finish' : 'start')
                                }}
                            />
                            <span className="sprint-switch-slider"></span>
                        </label>
                        <span className="sprint-switch-title">Finishes</span>
                    </div>}
                </div>
                <WeeklySprintChart
                    width={viewWidth}
                    height={260}
                    allTasks={allFilteredTasks}
                    showingStarts={showChart === 'start'}
                />
            </div>}
            {/*<Accordion className="sprint-accordion" panels={[{*/}
            {/*    key: 'sprint-weekly-chart',*/}
            {/*    title: 'Weekly Sprint Chart',*/}
            {/*    content: {content: (*/}
            {/*        <WeeklySprintChart*/}
            {/*            width={viewWidth}*/}
            {/*            height={300}*/}
            {/*            allTasks={allFilteredTasks}*/}
            {/*        />)}*/}
            {/*}]} />*/}
            <div className="tasks-sort-header"
                 style={{
                     // position: "sticky",
                     // top: "0",
                     backgroundColor: "rgb(255 255 255 / 80%)",
                     zIndex: "200"
                 }}>
                <div className="priority-task">
                    <div
                        className={"priority " + (sortCriteria === "index" ? "active" : "")}
                        onClick={() => setSortCriteria("index")}
                    >
                        <span className="priority-text">Priority</span>&nbsp;
                    </div>
                    <div className="task-desc">Deliverable</div>
                </div>
                <div className="right-filters">
                    {viewWidth >= 1200 &&
                        <div className="task-header narrow" style={{textAlign: "center"}}>
                            Start
                        </div>}
                    {viewWidth >= 1200 &&
                        <div className="task-header narrow" style={{textAlign: "center"}}>
                            Finish
                        </div>}
                    <div style={{textAlign: "right"}}
                         className={"narrow time " + (sortCriteria === "target" ? "active" : "")}
                         onClick={() => setSortCriteria("target")}
                    >
                        Target
                    </div>
                    <div style={{textAlign: "right"}}
                         className={"task-header critical " + (sortCriteria === "deadline" ? "active" : "")}
                         onClick={() => setSortCriteria("deadline")}
                    >Deadline
                    </div>
                </div>
            </div>
            {filtersLoaded ?
                <div className="lazy-load-container sprint" id={"sprint-tasks"}
                     style={{maxHeight: `calc(100vh - ${topOfContent}px + 50px)`}}
                >
                    {projectView === "task-map" && <GoogleMapVisGL/>}
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={loadMoreHandler}
                        initialLoad={false}
                        hasMore={
                            view === "future"
                                ? !loading.QueuedIsLoading && moreTasks.queued
                                : !loading.ConfirmedCompleteIsLoading && moreTasks.confirmedComplete
                        }
                        useWindow={false}
                    >
                        {liveSprint ? <div className="lazy-load-content" style={{zIndex: "100"}}>
                            {filteredArchivedTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Archive"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Archive"}
                                    title={
                                        "Archive"
                                    }
                                    taskList={filteredArchivedTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={archivedOpen}
                                    setOpen={setArchivedOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredDeclaredTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Ready for Sign-off"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Ready for Sign-off"}
                                    title={"Ready for Sign-off"}
                                    taskList={filteredDeclaredTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={declaredOpen}
                                    setOpen={setDeclaredOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredInProgressTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Work in progress"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Work in progress"}
                                    title={"Work in progress"}
                                    taskList={filteredInProgressTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={inProgressOpen}
                                    setOpen={setInProgressOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {filteredQueuedTaskList.length > 0 ? (
                                <TaskListSection
                                    text="Queued"
                                    moreTasks={moreTasks.confirmedComplete}
                                    isLoading={false}
                                    headline={"Queued"}
                                    title={"Queued"}
                                    taskList={filteredQueuedTaskList}
                                    view={view}
                                    setView={setView}
                                    total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                    quality={false}
                                    type={"task-sprint"}
                                    open={queuedOpen}
                                    setOpen={setQueuedOpen}
                                    taskSnapshot={taskSnapshot}
                                />
                            ) : null}
                            {(view === "future"
                                ? loading.QueuedIsLoading
                                : loading.ConfirmedCompleteIsLoading) && (
                                <div className="load-more-items-wrap" key={0}>
                                    <Loader active/>
                                </div>
                            )}
                        </div> :
                            <div className="lazy-load-content" style={{zIndex: "100"}}>
                                {started.length > 0 ? (
                                    <TaskListSection
                                        text="Started"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Started"}
                                        title={
                                            "Started"
                                        }
                                        taskList={started}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.s}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, s: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {didNotStart.length > 0 ? (
                                    <TaskListSection
                                        text="Did not start"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Did not start"}
                                        title={"Did not start"}
                                        taskList={didNotStart}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.dns}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, dns: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {finished.length > 0 ? (
                                    <TaskListSection
                                        text="Finished"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Finished"}
                                        title={"Finished"}
                                        taskList={finished}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.f}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, f: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {didNotFinish.length > 0 ? (
                                    <TaskListSection
                                        text="Did not finish"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Did not finish"}
                                        title={"Did not finish"}
                                        taskList={didNotFinish}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.dnf}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, dnf: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {progressed.length > 0 ? (
                                    <TaskListSection
                                        text="Progressed"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Progressed"}
                                        title={"Progressed"}
                                        taskList={progressed}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.p}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, p: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {didNotProgress.length > 0 ? (
                                    <TaskListSection
                                        text="Did not progress"
                                        moreTasks={true}
                                        isLoading={false}
                                        headline={"Did not progress"}
                                        title={"Did not progress"}
                                        taskList={didNotProgress}
                                        view={view}
                                        setView={setView}
                                        total={archivedTaskCount + declaredTaskCount + inProgressTaskCount + queuedTaskCount}
                                        quality={false}
                                        type={"task-sprint"}
                                        open={showLists.dnp}
                                        setOpen={(open: boolean) => setShowLists((prevState: any) => ({...prevState, dnp: open}))}
                                        taskSnapshot={taskSnapshot}
                                    />
                                ) : null}
                                {(view === "future"
                                    ? loading.QueuedIsLoading
                                    : loading.ConfirmedCompleteIsLoading) && (
                                    <div className="load-more-items-wrap" key={0}>
                                        <Loader active/>
                                    </div>
                                )}
                            </div>}
                    </InfiniteScroll>
                </div> : <Loader active content={"Loading sprint..."} size={"large"}/>
            }
            {sprintPopup &&
                <div className="sprint-popup"
                     style={sprintPopup.x / viewWidth < 0.8 ?
                         {top: sprintPopup.y + 20, left: sprintPopup.x} :
                            {top: sprintPopup.y + 20, left: Math.max(0.8 * viewWidth - 200, sprintPopup.x - 200)}
                }
                >
                    <h4><b>{sprintPopup.count} {
                        sprintPopup.incomplete ? sprintPopup.start ? "sprint tasks not started" : "sprint tasks not finished" :
                            sprintPopup.early ?
                                sprintPopup.finish ?
                                    sprintPopup.planned ? "sprint tasks finished before sprint window" : "non-sprint tasks finished before sprint window" :
                                    sprintPopup.planned ? "sprint tasks started before sprint window" : "non-sprint tasks started before sprint window" :
                                sprintPopup.finish ?
                                    sprintPopup.planned ? "sprint tasks finished" : "non-sprint tasks finished" :
                                    sprintPopup.planned ? "sprint tasks started" : "non-sprint tasks started"}</b></h4>
                </div>
            }
        </div>
    );
};


