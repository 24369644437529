import React, { useMemo, useCallback } from 'react';
import {AreaClosed, Line, Bar, LinePath} from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { GridRows, GridColumns } from '@visx/grid';
import { scaleTime, scaleLinear } from '@visx/scale';
import { withTooltip, Tooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { max, extent, bisector } from '@visx/vendor/d3-array';
import { timeFormat } from '@visx/vendor/d3-time-format';
import {AxisBottom} from "@visx/axis";

type TooltipData = any
const format = timeFormat('%b %d');
const formatDate = (date: Date) => format(date);

export const background = '#333333FF';
export const background2 = '#5c5c5c';
export const accentColor = '#edffea';
export const accentColorDark = '#75daad';
const tooltipStyles = {
    ...defaultStyles,
    background,
    border: '1px solid white',
    color: 'white',
};

// accessors
const getDate = (d: any) => new Date(d.date.seconds * 1000);
const getActivityValue = (d: any) => d.cumTasks as number
const bisectDate = bisector((d: any) => new Date(d.date.seconds * 1000)).left;

export type AreaProps = {
    width: number;
    height: number;
    data: any;
    margin?: { top: number; right: number; bottom: number; left: number };
};

export default withTooltip<AreaProps, TooltipData>(
    ({
         width,
         height,
         data,
         margin = { top: 0, right: 0, bottom: 0, left: 0 },
         showTooltip,
         hideTooltip,
         tooltipData,
         tooltipTop = 0,
         tooltipLeft = 0,
     }: AreaProps & WithTooltipProvidedProps<TooltipData>) => {
        if (width < 10) return null;

        // bounds
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        const numMonths = useMemo(() => {
            const bookends = extent(data.late, getDate) as [Date, Date];
            const year1 = bookends[0].getFullYear();
            const year2 = bookends[1].getFullYear();
            const month1 = bookends[0].getMonth();
            const month2 = bookends[1].getMonth();
            return (year2 - year1) * 12 + month2 - month1;
            }, [data]);

        const numYears = useMemo(() => {
            const bookends = extent(data.late, getDate) as [Date, Date];
            const year1 = bookends[0].getFullYear();
            const year2 = bookends[1].getFullYear();
            return year2 - year1;
        }, [data]);

        const maxTasks = useMemo(() => max(data.early, getActivityValue) || 0, [data]);

        // scales
        const dateScale = useMemo(
            () =>
                scaleTime({
                    range: [margin.left, innerWidth + margin.left],
                    domain: extent(data.late, getDate) as [Date, Date],
                }),
            [innerWidth, margin.left, data],
        );

        const activityCountScale = useMemo(
            () => scaleLinear({
                    range: [innerHeight - 42 + margin.top, margin.top],
                    domain: [0, (max(data.late, getActivityValue) || 0)],
                    nice: true,
                }),
            [margin.top, innerHeight, data],
        );

        // tooltip handler
        const handleTooltip = useCallback(
            (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
                const { x } = localPoint(event) || { x: 0, y: 100 };
                const x0 = dateScale.invert(x);
                const index = bisectDate(data.early, x0, 1);
                const dEarly = data.early[index]
                const dLate = data.late[index]
                const dExpected = data.expected[data.expected.length - (data.early.length - index)]
                showTooltip({
                    tooltipData: {
                        date: x0,
                        early: getActivityValue(dEarly),
                        late: getActivityValue(dLate),
                        expected: dExpected ? getActivityValue(dExpected) : null
                    },
                    tooltipLeft: x,
                    tooltipTop: dExpected ? activityCountScale(getActivityValue(dExpected)) : activityCountScale(getActivityValue(dLate)),
                });
            },
            [showTooltip, activityCountScale, dateScale],
        );

        return (
            <div>
                <svg width={width} height={height}>
                    <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill="url(#area-background-gradient)"
                    />
                    {/*<LinearGradient id="area-background-gradient" from={background} to={background2} />*/}
                    <LinearGradient id="area-background-gradient" from={'rgba(15,157,88,0.2)'} to={'rgba(15,157,88,0.4)'} toOpacity={0.1} />
                    {/*<LinearGradient id="area-gradient" from={'#D9001BFF'} to={'#D9001BFF'} toOpacity={0.1} />*/}
                    <GridRows
                        left={margin.left}
                        scale={activityCountScale}
                        width={innerWidth}
                        strokeDasharray="1,3"
                        stroke={accentColor}
                        strokeOpacity={0}
                        pointerEvents="none"
                    />
                    <AreaClosed
                        data={data.early}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        yScale={activityCountScale}
                        fill="white"
                        curve={curveMonotoneX}
                    />
                    <AreaClosed
                        data={data.late}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        yScale={activityCountScale}
                        fill={'#D9001BFF'}
                        fillOpacity={0.15}
                        curve={curveMonotoneX}
                    />
                    <rect
                        x={margin.left}
                        y={innerHeight + margin.top - 42}
                        width={innerWidth}
                        height={42}
                        fill={'#F7DFDF'}
                    />
                    <rect
                        x={margin.left}
                        y={margin.top}
                        width={dateScale(new Date()) - margin.left}
                        height={innerHeight + 42}
                        fill={'#d0dcf1'}
                        fillOpacity={1}
                    />
                    <Line
                        from={{ x: margin.left, y: innerHeight + margin.top - 18 }}
                        to={{ x: innerWidth + margin?.right, y: innerHeight + margin.top - 18 }}
                        stroke={'#bdbdbc'}
                        strokeWidth={0.5}
                        pointerEvents="none"
                    />
                    <Line
                        from={{ x: margin.left, y: innerHeight + margin.top - 42 }}
                        to={{ x: innerWidth + margin?.right, y: innerHeight + margin.top - 42 }}
                        stroke={'#bdbdbc'}
                        strokeWidth={0.5}
                        pointerEvents="none"
                    />
                    <GridColumns
                        top={margin.top}
                        scale={dateScale}
                        height={innerHeight - 20}
                        strokeDasharray="1,3"
                        stroke={'#484643'}
                        strokeOpacity={0.8}
                        pointerEvents="none"
                        numTicks={numMonths || 100}
                    />
                    <GridColumns
                        top={margin.top}
                        scale={dateScale}
                        height={innerHeight}
                        strokeDasharray="3,3"
                        stroke={'#484643'}
                        strokeOpacity={0.7}
                        pointerEvents="none"
                        numTicks={numYears || 1}
                    />
                    <LinePath
                        data={data.expected}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        stroke={'#4285f4'}
                        strokeWidth={1.5}
                        curve={curveMonotoneX}
                        strokeDasharray={'4 4'}
                    />
                    <LinePath
                        data={data.late}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        stroke={'#d9001b'}
                        strokeWidth={1.5}
                        curve={curveMonotoneX}
                    />
                    <LinePath
                        data={data.early}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        stroke={'#0F9D58FF'}
                        strokeWidth={1.5}
                        curve={curveMonotoneX}
                    />
                    <LinePath
                        data={data.early.filter((d: any) => d.date.seconds < new Date().getTime() / 1000)}
                        x={(d) => dateScale(getDate(d)) ?? 0}
                        y={(d) => activityCountScale(getActivityValue(d)) ?? 0}
                        stroke={'#4285f4'}
                        strokeWidth={1.5}
                        curve={curveMonotoneX}
                    />
                    <AxisBottom
                        top={innerHeight + margin.top - 30}
                        scale={dateScale}
                        numTicks={numMonths}
                        stroke={'none'}
                        tickStroke={'none'}
                        // @ts-ignore
                        tickFormat={timeFormat('%b')}
                        // rotate by 90 degrees
                        tickLabelProps={(value, index) => ({
                            transform: 'rotate(270 ' + dateScale(value) + ',0)',
                            textAnchor: 'middle' as const,
                            fontSize: 10,
                            fill: value >= new Date() ? '#959594' : '#4285f4'
                        })}
                        // tickLabelProps={axisBottomTickLabelProps}
                    />
                    <AxisBottom
                        top={innerHeight + margin.top - 23}
                        scale={dateScale}
                        numTicks={numYears + 1}
                        stroke={'none'}
                        tickStroke={'none'}
                        // @ts-ignore
                        tickFormat={timeFormat('%Y')}
                        // move to middle of year
                        // @ts-ignore
                        tickLabelProps={(value: Date, _) => ({
                            x: dateScale(new Date(value.getFullYear(), 6, 1)),
                            textAnchor: 'middle' as const,
                            fontSize: 10,
                            fill: value.getFullYear() >= new Date().getFullYear() ? '#959594' : '#4285f4'
                        })}
                        // tickLabelProps={axisBottomTickLabelProps}
                    />
                    <Bar
                        x={margin.left}
                        y={margin.top}
                        width={innerWidth}
                        height={innerHeight + 42}
                        fill="transparent"
                        rx={14}
                        onTouchStart={handleTooltip}
                        onTouchMove={handleTooltip}
                        onMouseMove={handleTooltip}
                        onMouseLeave={() => hideTooltip()}
                    />
                    <Line
                        from={{ x: dateScale(new Date()), y: margin.top }}
                        to={{ x: dateScale(new Date()), y: innerHeight + margin.top }}
                        stroke={'#4285f4'}
                        strokeWidth={3}
                        pointerEvents="none"
                        // strokeDasharray="5,2"
                    />
                    {tooltipData && (
                        <g>
                            <Line
                                from={{ x: tooltipLeft, y: margin.top }}
                                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                                stroke={'#4285f4'}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                            {tooltipData.date > new Date() && <circle
                                cx={tooltipLeft}
                                cy={activityCountScale(tooltipData.early) + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />}
                            {tooltipData.date > new Date() && <circle
                                cx={tooltipLeft}
                                cy={activityCountScale(tooltipData.early)}
                                r={4}
                                fill={'#0F9D58FF'}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                        />}
                            {tooltipData.date > new Date() && <circle
                                cx={tooltipLeft}
                                cy={activityCountScale(tooltipData.late) + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />}
                            {tooltipData.date > new Date() && <circle
                                cx={tooltipLeft}
                                cy={activityCountScale(tooltipData.late)}
                                r={4}
                                fill={'#D9001BFF'}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                            />}
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop + 1}
                                r={4}
                                fill="black"
                                fillOpacity={0.1}
                                stroke="black"
                                strokeOpacity={0.1}
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <circle
                                cx={tooltipLeft}
                                cy={tooltipTop}
                                r={4}
                                fill={'#4285f4'}
                                stroke="white"
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                        </g>
                    )}
                </svg>
                {tooltipData && (
                    <div>
                        <Tooltip
                        top={innerHeight + margin.top - 32}
                        left={tooltipLeft -10}
                        style={{
                            ...defaultStyles,
                            minWidth: 72,
                            textAlign: 'center',
                            transform: 'translateX(-50%)',
                        }}
                    >
                        {timeFormat('%d %b %Y')(tooltipData.date)}
                    </Tooltip>
                        {tooltipData.date < new Date() ? <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop - 12}
                            left={tooltipLeft + 12}
                            style={tooltipStyles}
                        >
                            Actual: {tooltipData.early} / {maxTasks} ({(tooltipData.early / maxTasks * 100).toFixed(2)}%)
                        </TooltipWithBounds> :
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop - 12}
                            left={tooltipLeft + 12}
                            style={tooltipStyles}
                            offsetTop={30}
                        >
                            <p style={{color: '#0F9D58FF', marginBottom: "5px"}}>Early: {tooltipData.early} / {maxTasks} ({(tooltipData.early / maxTasks * 100).toFixed(2)}%)</p>
                            <p style={{color: '#4285f4', marginBottom: "5px"}}>Optimal: {tooltipData.expected && tooltipData.expected.toFixed(0)} / {maxTasks} ({(tooltipData.expected / maxTasks * 100).toFixed(2)}%)</p>
                            <p style={{color: '#D9001BFF', marginBottom: "0"}}>Late: {tooltipData.late} / {maxTasks} ({(tooltipData.late / maxTasks * 100).toFixed(2)}%)</p>
                        </TooltipWithBounds>}
                    </div>
                )}
            </div>
        );
    },
);