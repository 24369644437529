import React, { useCallback, useState } from 'react';
import { useUserSelector } from '../../store/selectors/authorization.selectors';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dimmer,
  Header,
  List,
  Loader,
} from 'semantic-ui-react';
import { useProjectListSelector } from '../../store/selectors/project.selectors';
import { DeleteProjectModal } from './components/DeleteProjectModal/DeleteProjectModal';
import { Navigate, useNavigate } from 'react-router';
import ProjectTransactions from '../../store/thunk/transactions/project.transactions';

const DeleteProjectPage: React.FC = () => {
  const user = useUserSelector();
  const [loading, setLoading] = useState(false);
  const projects = useProjectListSelector();
  const [projectsIds, setProjectsIds] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const history = useNavigate();

  const isDevEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod' 

  const cancelDeletingHandler = useCallback(() => {
    history('/');
  }, [history]);

  const deleteProjectsHandler = useCallback(async () => {
    setModalOpen(false);
    if (user) {
      setLoading(true);
      await ProjectTransactions.projectDeletion(projectsIds, user);
      // dispatch(ProjectThunk.projectDeletion(projectsIds, user));
    }
    setProjectsIds([]);
    setLoading(false);
    // eslint-disable-next-line
  }, [user, projectsIds]);

  const changeProjectsToDeleteHandler = useCallback(
    (
      _: React.FormEvent<HTMLInputElement>,
      data: CheckboxProps,
      projectId: string
    ) => {
      let ids: string[] = [...projectsIds];
      if (data.checked && ids.every((el) => el !== projectId)) {
        ids.push(projectId);
      } else if (!data.checked && ids.some((el) => el === projectId)) {
        ids = ids.filter((el) => el !== projectId);
      }
      setProjectsIds(ids);
    },
    [projectsIds]
  );

   if (isDevEnv || user?.isDeleteProjects) {
    return (
      <div className="delete-project-page">
        <Header className="header" size="large">
          Choose a project, you want to delete
        </Header>
        <List
          className="list"
          relaxed="very"
          style={{ maxWidth: '75%', maxHeight: '400px', overflowY: 'auto' }}
        >
          {projects.length ? (
            projects.map((project) => {
              return (
                <List.Item className="list-item" key={project.projectId}>
                  <Checkbox
                    onChange={(event, data) =>
                      changeProjectsToDeleteHandler(
                        event,
                        data,
                        project.projectId
                      )
                    }
                    className="checkbox"
                    label={project.name}
                  />
                </List.Item>
              );
            })
          ) : (
            <div>No projects available</div>
          )}
        </List>

        <div className="buttons">
          <Button onClick={cancelDeletingHandler} color="red">
            Cancel Deleting
          </Button>
          {/*<DeleteProjectModal*/}
          {/*  setError={setError}*/}
          {/*  projectsIds={projectsIds}*/}
          {/*  modalOpen={modalOpen}*/}
          {/*  setModalOpen={setModalOpen}*/}
          {/*  deleteProject={deleteProjectsHandler}*/}
          {/*/>*/}
        </div>
        {loading && (
          <Dimmer active>
            <Loader indeterminate>Processing...</Loader>
          </Dimmer>
        )}
        {error && (
          <div style={{ color: 'red' }}>No projects were selected!</div>
        )}
      </div>
    );
  }
  return <Navigate replace to="/" />;
};

export default DeleteProjectPage;
