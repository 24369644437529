import TaskModel, {ProcessedFrom, TaskSnapshot} from "../../../../../../models/responses/task.model";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { TaskContainer } from "../Task/TaskContainer";
import * as taskActions from "../../../../../../store/actions/task.actions";
import { useUserSelector } from "../../../../../../store/selectors/authorization.selectors";
import { TaskListSectionModel } from "../../../../../../models/task-list-section.model";
import { TaskListHeadlineModel } from "../../../../../../models/task-list-headline.model";
import TaskCheckListWrapper from "../TaskChecklistWrapper/TaskCheckListWrapper";
import { useActiveTaskSelector } from "../../../../../../store/selectors/task/task.selectors";
import {
  faLock,
  faAngleUp,
  faAngleDown,
  faCircleCheck, faPersonRunningFast,
} from "@fortawesome/pro-light-svg-icons";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Popup, Modal, Button, Loader, Dimmer } from "semantic-ui-react";
import TaskStatusModel from "../../../../../../models/responses/task-status.model";
import { TaskThunk } from "../../../../../../store/thunk/task.thunk";

import { TaskType } from "../../../../../../models/task-type";
import { MessageType } from "../../../../../../models/responses/message.model";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import { useTypedSelector } from "../../../../../../store/selectors/selectors.utils";
import {LinkContainer} from "../../../QualityPage/components/LinkContainer";
import {
  useActiveProjectCalendarsSelector,
  useCpmMapSelector
} from "../../../../../../store/selectors/project.selectors";

interface InjectedProps {
  title: TaskListSectionModel | string | ProcessedFrom | null;
  headline: string;
  text: string;
  taskList: TaskModel[];
  isLoading: boolean;
  moreTasks: boolean;
  view: "future" | "past";
  setView: React.Dispatch<React.SetStateAction<"future" | "past">>;
  total: number;
  quality: boolean;
  type: string;
  open: boolean;
  setOpen: any;
  taskSnapshot?: Map<string, TaskSnapshot>;
  activeHeadline?: string | null;
  setActiveHeadline?: React.Dispatch<React.SetStateAction<string | null>>;
  activeTaskPage?: number | null;
  setActiveTaskPage?: React.Dispatch<React.SetStateAction<number | null>>;
  setGoToTask?: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToTask?: (task: TaskModel) => void;
}

export const TaskListSection: React.FC<InjectedProps> = (props) => {
  // const [open, setOpen] = useState(
  //   props.view === "future" ? props.headline !== TaskListHeadlineModel.CONFIRMED_COMPLETE && !props.quality : false
  // );
  const dispatch = useDispatch();
  const user = useUserSelector();
  const activeTask = useActiveTaskSelector();
  const refreshTaskList = useTypedSelector(
    (state) => state.task.refreshTaskList
  );
  const [declareAll, setDeclareAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const [qOpen, setQOpen] = useState(false);
  const projectCalendars = useActiveProjectCalendarsSelector();
  const cpmMap = useCpmMapSelector()

  const open = props.quality ? qOpen : props.open;
  const setOpen = props.quality ? setQOpen : props.setOpen;

  const filteredTaskList = props.taskList

  useEffect(() => {
    if (props.activeHeadline === props.headline && props.activeTaskPage) {
      setPage(props.activeTaskPage);
      // scroll to active task
      setTimeout(() => {
        if (activeTask) {
            const taskElement = document.getElementById(activeTask.task_id);
            const flowElement = document.getElementById("flow-container");
            if (taskElement && flowElement) {
              flowElement.scrollTop = taskElement.offsetTop - 100;
            }
        }
        }, 100);
      if (props.setActiveTaskPage && props.setActiveHeadline && props.setGoToTask) {
        props.setActiveTaskPage(null);
        props.setActiveHeadline(null);
        props.setGoToTask(false);
      }
    }
  }, [props.activeTaskPage, props.activeHeadline]);

  const icon = useMemo(() => {
    if (
      props.title === TaskListSectionModel.CONFIRMED_COMPLETE &&
      user &&
      user.trial
    ) {
      return faLock;
    } else {
      if (open) {
        return faAngleUp;
      }
      return faAngleDown;
    }
  }, [user, open, props.title]);

  // useEffect(() => {
  //   if (refreshTaskList) {
  //     const activeTaskCode = activeTask?.task_code;
  //     const taskId = activeTask?.task_id || "";
  //     let activePage = 1;
  //     const activeTaskType = activeTask?.taskListType;
  //     if (
  //       (activeTaskType === TaskListSectionModel.CONFIRMED_COMPLETE &&
  //         props.headline === TaskListHeadlineModel.CONFIRMED_COMPLETE) ||
  //       (activeTaskType === TaskListSectionModel.DECLARED_COMPLETE &&
  //         props.headline === TaskListHeadlineModel.DECLARED_COMPLETE) ||
  //       (activeTaskType === TaskListSectionModel.WORK_IN_PROCESS &&
  //         props.headline === TaskListHeadlineModel.WORK_IN_PROCESS) ||
  //       (activeTaskType === TaskListSectionModel.QUEUED &&
  //         props.headline === TaskListHeadlineModel.QUEUED)
  //     ) {
  //       // check if active task is in the list by activeTaskCode
  //       const activeTaskIndex = props.taskList.findIndex(
  //         (task) => task.task_code === activeTaskCode
  //       );
  //       activePage = Math.ceil((activeTaskIndex + 1) / itemsPerPage);
  //
  //       setTimeout(() => {
  //         const taskElement = document.getElementById(taskId);
  //         if (taskElement) {
  //           taskElement.scrollIntoView({ behavior: "smooth" });
  //           setPage(activePage);
  //         }
  //       }, 400);
  //       dispatch(taskActions.Actions.unSetRefreshTaskList(false));
  //     }
  //   }
  // }, [activeTask, props.taskList, props.headline, dispatch]);

  const totalPages = Math.ceil(filteredTaskList.length / itemsPerPage);

  const renderItems = useMemo(() => {
    if (filteredTaskList.length > itemsPerPage) {
      if (filteredTaskList.length < (itemsPerPage * (page - 1))) {
        setPage(1);
      }
      return filteredTaskList.slice(
        (page - 1) * itemsPerPage,
        (page - 1) * itemsPerPage + itemsPerPage
      );
    }
    setPage(1);
    return filteredTaskList;
  }, [page, filteredTaskList]);

  const tasksCount = filteredTaskList.length;
  const tasksPercentage =
    ((tasksCount / props.total) * 100) % 1 !== 0
      ? ((tasksCount / props.total) * 100).toFixed(2)
      : (tasksCount / props.total) * 100;

  useEffect(() => {
    if (declareAll) {
      setLoading(false);
      setDeclareAll(false);
    }
  }, []);

  async function declareAllTasksBatch() {
    const taskData = filteredTaskList.map((task) => ({
      taskId: task.task_id,
      user: user,
      projectId: task.projectId,
      accepted: true,
      userEmail: (user && user.userEmail) || "",
      type: MessageType.EVL,
      eventTimestamp: task.declaredCompleteTimestamp
        ? task.declaredCompleteTimestamp
        : task.act_end_date
        ? task.act_end_date
        : FirebaseUsage.timestamp(),
      taskListType: task!.taskListType,
    }));
    try {
      // @ts-ignore
      dispatch(TaskThunk.evaluateCompletionTasks(taskData));
      return "success";
    } catch (error) {
      return "error";
    }
  }

  const startItem = (page - 1) * itemsPerPage + 1;
  const endItem = Math.min(page * itemsPerPage, filteredTaskList.length);

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const stopEvents = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return filteredTaskList.length ? (
    <div className="task-list-accordion-wrap">
      <div className="task-list-accordion" style={user?.isAdmin &&
          props.headline === TaskListHeadlineModel.DECLARED_COMPLETE &&
      props.type === "task" ? {padding: "5px 25px 5px 15px"} : {}}>
        <div
            className="task-list-accordion-label"
            onClick={
              props.title === TaskListSectionModel.CONFIRMED_COMPLETE
                  ? user && user.trial
                      ? undefined
                      : () => setOpen(!open)
                  : () => setOpen(!open)
            }
        >
          <div className={"label-chevron-text"}>
            {props.title === TaskListSectionModel.CONFIRMED_COMPLETE &&
            user &&
            user.trial ? (
                <Popup
                    content="Contact sales@flowledger.app to unlock completed tasks"
                    trigger={<FontAwesomeIcon icon={icon} className="chevron-icon"/>}
                />
            ) : (
                <FontAwesomeIcon icon={icon} className="chevron-icon"/>
            )}

            <span className={'label-text-only'}>
              {props.headline}
              <span className="percentage">
                {" "}
                {props.type === "task" && " (" +
                    filteredTaskList.length +
                    " - " +
                    Math.round(+tasksPercentage) +
                    "%)"}{" "}
              </span>
              {/*{props.type === "task-sprint" &&*/}
              {/*    <div className={"sprint-icon"}*/}
              {/*         style={{display: "inline-block", marginLeft: "10px"}}>*/}
              {/*      <FontAwesomeIcon icon={faPersonRunningFast} />*/}
              {/*      {' '}*/}
              {/*      <span className={'percentage'} style={{marginLeft: "10px"}}>*/}
              {/*        {filteredTaskList.length}*/}
              {/*      </span>*/}
              {/*      {' | '}*/}
              {/*      <span className={'percentage'}>*/}
              {/*        {tasksPercentage}%*/}
              {/*      </span>*/}
              {/*    </div>*/}
              {/*}{" "}*/}
            </span>
          </div>
          {!(user?.isAdmin && props.type === "task" &&
              props.headline === TaskListHeadlineModel.DECLARED_COMPLETE) && (
          <div className="custom-pagination" onClick={stopEvents}>
            <div className="number">
              {startItem}-{endItem} of {filteredTaskList.length}
            </div>
            <button onClick={handlePrevClick} disabled={page === 1}>
              <FontAwesomeIcon
                  style={{fontSize: "16px"}}
                  icon={faAngleLeft}
              />
            </button>
            <button onClick={handleNextClick} disabled={page === totalPages}>
              <FontAwesomeIcon
                  style={{fontSize: "16px"}}
                  icon={faAngleRight}
              />
            </button>
          </div>)}
          {user?.isAdmin && props.type === "task" &&
          props.headline === TaskListHeadlineModel.DECLARED_COMPLETE ? (
            <div style={{display: "flex", flexDirection: "row"}}>
                <div className="custom-pagination" onClick={stopEvents}>
                  <div className="number">
                    {startItem}-{endItem} of {filteredTaskList.length}
                  </div>
                  <button onClick={handlePrevClick} disabled={page === 1}>
                    <FontAwesomeIcon
                        style={{fontSize: "16px"}}
                        icon={faAngleLeft}
                    />
                  </button>
                  <button onClick={handleNextClick} disabled={page === totalPages}>
                    <FontAwesomeIcon
                        style={{fontSize: "16px"}}
                        icon={faAngleRight}
                    />
                  </button>
                </div>
              <Popup
                  style={{position: "absolute", right: "0"}}
                  position="bottom right"
                  on={["hover", "click"]}
                  trigger={
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        className="check-complete"
                        onClick={() => setDeclareAll(true)}
                    />
                  }
              >
                <Popup.Content>
                  <div className="popup-content">
                    <h4>Admin Options</h4>
                    <p>Confirm all Declared Complete tasks as Complete</p>
                  </div>
                </Popup.Content>
              </Popup>
           </div>
          ) : null}
          {declareAll && <Modal
              style={{maxWidth: "500px"}}
              open={declareAll}
              closeIcon
              onClose={() => {
                setLoading(false);
                setDeclareAll(false);
              }}
          >
            <Modal.Header>Confirm All Declared Complete Tasks</Modal.Header>
            <Modal.Content>
              <Dimmer active={loading} inverted>
                <Loader inverted>Confirming tasks, please wait</Loader>
              </Dimmer>
              <p>
                Are you sure you want to confirm all Declared Complete tasks as
                Complete? This will set all declared complete tasks to Confirmed
                Complete using the completion dates already specified.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                  negative
                  onClick={() => {
                    setLoading(false);
                    setDeclareAll(false);
                  }}
              >
                Cancel
              </Button>
              <Button
                  positive
                  onClick={() => {
                    setLoading(true);
                    declareAllTasksBatch().then(() => {
                      setLoading(false);
                      setDeclareAll(false);
                    });
                  }}
              >
                Confirm
              </Button>
            </Modal.Actions>
          </Modal>}
          {/* <span onClick={logTaskList} className='section-text'>{props.text}</span> */}
        </div>
      </div>
      <div
        className={classNames("task-list-accordion-content", {
          open:
            (props.title !== TaskListSectionModel.CONFIRMED_COMPLETE &&
              props.view === "future" &&
              open) ||
            (props.title === TaskListSectionModel.CONFIRMED_COMPLETE &&
              props.view === "past" &&
              open),
        })}
      >
        <div>
          {!open
            ? null
            : renderItems.map((task, i) => {
                return (
                  <div
                    className="task-container-wrapper"
                    key={task.task_id}
                    id={task.task_id}
                  >
                    {props.type === "task" || props.type === "task-sprint" ?
                    <TaskContainer
                      task={task}
                      // index={props.type === "task" ? null : props.taskSnapshot?.get(task.task_code)?.index || null}
                        index={null}
                      calendarsMap={projectCalendars}
                      cpmTask={cpmMap.get(task.task_id)}
                      navigateToTask={props.navigateToTask}
                      goToPred={() => {
                        dispatch(taskActions.Actions.setActiveTask(task));
                        dispatch(
                          taskActions.Actions.openRelatedTaskList(
                            task,
                            "predecessors"
                          )
                        );
                      }}
                      goToSuc={() => {
                        dispatch(taskActions.Actions.setActiveTask(task));
                        dispatch(
                          taskActions.Actions.openRelatedTaskList(
                            task,
                            "successors"
                          )
                        );
                      }}
                    /> : <LinkContainer link={task} index={i + 1 + ((page - 1) * itemsPerPage)} />}
                    {activeTask &&
                      task.task_id === activeTask.task_id &&
                      activeTask.status !== TaskStatusModel.COMPLETE && (
                        <TaskCheckListWrapper />
                      )}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  ) : null;
};
