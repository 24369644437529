import * as React from "react";
import {useSelector} from "react-redux";
import {RootStateModel} from "../store/reducers";
import {AuthorizationSelectors} from "../store/reducers/authorization";
import {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import LoaderPage from "../pages/LoaderPage/LoaderPage";
import RouteNameConstants from "../constants/route-name.constants";


function authRequired (props) {
        const checkedAuth = useSelector<RootStateModel, boolean>(state => state.authorization.checkedAuth);
        const unAuthorized = useSelector<RootStateModel, boolean>(state => AuthorizationSelectors.unAuthorized(state.authorization));
        const navigate = useNavigate();
        const Component = props.component;

        console.log(checkedAuth, unAuthorized);

        useEffect(() => {
            if(unAuthorized && checkedAuth) {
                navigate(`/${RouteNameConstants.LOGIN}`);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [unAuthorized, checkedAuth]);

        return checkedAuth ?
            (<Component />) : (<LoaderPage/>)
}

export default authRequired;
