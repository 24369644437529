import parseXERContent from "./functions/parseXERContent";
import runUpdates from "./functions/updateStrategy";
// import {uploadFile} from "../../services/transformXerToJson/uploadFiletoBucket";
import firebaseConfig from "../../config/firebase.config";
import {COLLECTIONS} from "../../firebase/constants";
import {ClientDocModel} from "../../models/responses/client.model";
import FirebaseUsage from "../../firebase/firebase.usage";
import { Buffer } from 'buffer';
import {ProjectUploadModel} from "../../models/responses/project.model";

class XerToJsonController {
    static xerToJson = xerToJson;
    static confirmXerToJson = confirmXER;
    static updateXerToJson = updateXER;
    static confirmUpdateXerToJson = confirmUpdateXER;
    static confirmUpload = confirmUpload;
}
export default XerToJsonController;

async function xerToJson(req: any) {
    try {
        const { content, userId, isUpdate, projectId, fileName, fileBlob } = req;
        const uploadDoc = FirebaseUsage.getBlankDoc(COLLECTIONS.PROJECT_UPLOADS);
        const re = new RegExp(/\w+$/g);
        const extension = fileName.match(re)[0];
        let xerJsonResult = await parseXER({
            content: content,
            user_id: userId,
            file_name: uploadDoc.id,
            fileBlob: fileBlob,
        }).then(() => {
            FirebaseUsage.setDocumentWithDoc(uploadDoc, {
                userId: userId,
                uploadId: uploadDoc.id,
                projectId: projectId,
                fileName: fileName,
                fileType: extension || "unknown",
                status: "deleted",
                message: "Upload pending",
                progress: 0,
                cancelled: false,
                isUpdate: isUpdate,
                uploadTimestamp: FirebaseUsage.timestamp()
            } as ProjectUploadModel)
            return [fileName, uploadDoc.id];
        })
        return {data: xerJsonResult};
    } catch (e: any) {
        console.log(e);
        return {
            data: {
                result: false,
                errorMessage: e.message
                    ? e.message
                    : "Something went wrong updating project",
            },
        };
    }
}

async function confirmUpload(req: any) {
    try {
        const { project_id, is_update, project_name, old_project_id ,clientId} = req;
        let host:any = "";
        // if(clientId && authUser.isProjectsAdmin){
        if (clientId){
            const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["clientId", "==", clientId])
                .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);
            host = client.domain;
        }
        if(!host){
            const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", "localhost"])
                .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);

            if(client){
                host = client.domain;
            }
            else{
                const clientRef = FirebaseUsage.getBlankDoc(COLLECTIONS.CLIENTS)
                await FirebaseUsage.setDocumentWithDoc(clientRef, {
                    domain:"localhost",
                    clientId:clientRef.id,
                    clientName:"localhost"
                })
            }
            host = "localhost"
        }
        console.log("host", host);
        let uploadResult;
        if (is_update) {
            uploadResult = await XerToJsonController.confirmUpdateXerToJson({
                project_id: project_id,
                project_name: project_name,
                old_project_id: old_project_id,
                hostName: host,
            });
        } else {
            uploadResult = await XerToJsonController.confirmXerToJson({
                project_id: project_id,
                project_name: project_name,
                hostName: host,
            });
        }
        console.log(uploadResult, "uploadResult");
        return {
            data: uploadResult,
        };
    } catch (e: any) {
        console.log(e);
        return {
            data: {
                result: false,
                errorMessage: e.message
                    ? e.message
                    : "Something went wrong updating task",
            },
        };
    }
}

async function parseXER(req: {content: string, user_id: string, file_name: string, fileBlob: any}) {
    try {
        console.log('Fetching Data...');
        const { content, user_id, file_name, fileBlob } = req; // get the username from the request
        // const data = await parseXERContent(content, user_id)
        // let buffer = Buffer.from(content, 'utf-8');
        let bufferFromBlob = Buffer.from(fileBlob, 'utf-8');
        console.log('buffer', bufferFromBlob);
        FirebaseUsage.uploadFileToStorage(`${firebaseConfig.xerUploadBucketName}/${file_name}`, fileBlob)
            .then(() => console.log('Base file uploaded successfully'))
            .catch(e => console.error(e))
        return
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function confirmXER(req: {project_id: string, project_name: string, hostName: string}) {
    try {
        const { project_id,
            project_name ,
            hostName
        } = req;
        const db = FirebaseUsage.database()

        const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", hostName])
            .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);

        if(!client){
            console.error("issue  with getting client info");
            return "issue  with getting client info"
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, project_id, {
            uploadState: 'done',
            name: project_name,
            clientId: client.clientId
        });
        return "done"
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function updateXER (req: {content: string, user_id: string, project_id: string,hostName:string}) {
    try {
        const { content, user_id, project_id } = req;
        const data = await parseXERContent(content, user_id)
        await runUpdates(project_id, data[0][0][1], data[1], user_id)
        let buffer = Buffer.from(content, 'utf-8');
        // console.log(data);
        // uploadFile(data[0][0][1], buffer, firebaseConfig.xerUploadBucketName).catch(e => console.error(e))
        FirebaseUsage.uploadFileToStorage(`${firebaseConfig.xerUploadBucketName}/${data[0][0][1]}`, buffer)
            .then(() => console.log('XER base file uploaded successfully'))
            .catch(e => console.error(e))
        return data[0]
    }
    catch (err) {
        console.error(err);
        return err
    }
}

async function confirmUpdateXER (req: {project_id: string,
    project_name: string,
    old_project_id: string,
    hostName:string
}) {
    try {
        const {
            project_id,
            old_project_id,
             hostName
        } = req;
        const oldProject = await FirebaseUsage.getDoc(COLLECTIONS.PROJECTS, old_project_id).then(doc => doc.data())
        const oldProjectData = oldProject;

        const client = await FirebaseUsage.getQuery(COLLECTIONS.CLIENTS, ["domain", "==", hostName])
            .then((data) => data.docs.map((el) => el.data() as ClientDocModel)[0]);
        if(!client || !oldProjectData){
            console.error("issue  with getting client info or previous project data");
            return "issue  with getting client info"
        }
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, project_id, {
            uploadState: 'done',
            name: oldProjectData.name,
            grade: oldProjectData.grade,
            clientId: client.clientId,
        })
        await FirebaseUsage.updateDoc(COLLECTIONS.PROJECTS, old_project_id, {
            uploadState: 'archived',
            archived: true,
            supersededBy: project_id
        })
        return "done"
    }
    catch (err) {
        console.error(err);
        return err
    }
}