import React, {MutableRefObject, useCallback, useMemo, useRef, useState} from "react";
import {useLedgerListSelector} from "../../../../../store/selectors/ledger.selectors";
import MessageModel, {MessageType,} from "../../../../../models/responses/message.model";
import {Dropdown, Form, Header, Input, Loader, SemanticCOLORS} from "semantic-ui-react";
import {useActiveTaskSelector} from "../../../../../store/selectors/task/task.selectors";
import moment from "moment";
import {useUserSelector} from "../../../../../store/selectors/authorization.selectors";
import {
  faCalendar,
  faCheck,
  faFileCirclePlus,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faImage,
  faPause,
  faPlay,
  faStopwatch,
  faTimes,
  faFastForward
} from "@fortawesome/free-solid-svg-icons";
import {faExclamationTriangle, faMessages} from "@fortawesome/pro-solid-svg-icons";
import {faComment} from "@fortawesome/free-regular-svg-icons";
import {faPersonRunningFast} from "@fortawesome/pro-light-svg-icons";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {HeaderLedgerItem} from "./HeaderLedgerItem";
import {Maybe} from "@martin_hotell/rex-tils";
import {useDispatch} from "react-redux";
import {TaskThunk} from "../../../../../store/thunk/task.thunk";
import {faUsers} from "@fortawesome/pro-solid-svg-icons/faUsers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EXIF from "exif-js";
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any";
import ExifReader from "exifreader";
import {useProjectMemberListSelector} from "../../../../../store/selectors/team.selectors";
import UserImage from "../../../../../images/UserImage";


const getFileIcon = (type: string) => {
    switch (type) {
        case "image/jpeg":
        case "image/png":
        return faImage;
        case "application/pdf":
        return faFilePdf;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        case "application/vnd.ms-excel":
        return faFileExcel
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/msword":
        return faFileWord
        default:
        return faFileCirclePlus;
    }
}

function getConfForMsg(message: MessageModel, user: any) {
  let icon: IconDefinition | null = null;
  let text = "";
  let undo = "";
  let color: SemanticCOLORS = "black";
  let operator: string | null = null;
  switch (message.type) {
    case MessageType.STD:
      text = "Task started";
      icon = faPlay;
      undo = "task start";
      break;
    case MessageType.COS:
      text = "Task start confirmed";
      icon = faCheck;
      undo = "task start rejection";
      break;
    case MessageType.UST:
      text = "Task start rejected";
      icon = faTimes;
      undo = "task unstart";
      break;
    case MessageType.RST:
      text = "Task resumed";
      icon = faPlay;
      undo = "task resumption";
      break;
    case MessageType.MSG:
      text = "Comment";
      icon = faComment;
      undo = "comment";
      break;
    case MessageType.DIR:
      text = "Task suspended due to directive";
      icon = faPause;
      undo = "task suspension";
      break;
    case MessageType.INC:
      text = "Task suspended due to incident";
      icon = faPause;
      undo = "task suspension";
      break;
    case MessageType.INF:
      text = "Task suspended due to information";
      icon = faPause;
      undo = "task suspension";
      break;
    case MessageType.REJ:
      text = "Declaration rejected";
      icon = faTimes;
      undo = "task suspension";
      break;
    case MessageType.RES:
      text = "Task suspended due to resource";
      icon = faPause;
      undo = "task suspension";
      break;
    case MessageType.DEC:
      text = "Task declared complete";
      icon = faCheck;
      undo = "declaration";
      break;
    case MessageType.COM:
      text = "Task complete";
      icon = faCheck;
      undo = "completion confirmation";
      break;
    case MessageType.RNM:
      text = "Task renamed";
      icon = faCheck;
      undo = "task rename";
      break;
    case MessageType.SUS:
      text = `Task suspended due to ${message.suspendReason}`;
      icon = faPause;
      undo = "task suspension";
      break;
    case MessageType.ISS:
      text = `Task issue logged due to ${message.suspendReason}`;
      icon = faExclamationTriangle;
      undo = "task suspension";
      break;
    case MessageType.TFJ:
      text =
        user?.userEmail === message.userEmail ? "Taskforce joined"
          : message.userEmail + " has been assigned to this task";
      icon = faUsers;
      undo = "task force join";
      break;
    case MessageType.TFL:
      text = user?.userEmail === message.userEmail ? "Taskforce left" :
          message.userEmail + " has been removed from this task";
      icon = faUsers;
      undo = "task force leave";
      break;
    case MessageType.WTG:
      text = "Task waiting to start";
      icon = faStopwatch;
      undo = "task wait";
      break;
    case MessageType.BLC:
      text = `Task blocked due to ${message.suspendReason}`;
      icon = faStopwatch;
      undo = "task block";
      break;
    case MessageType.UNB:
      text = "Task unblocked";
      icon = faPlay;
      undo = "task unblock";
      break;
    case MessageType.CHK:
      text = "Checklist complete - task is ready to start";
      icon = faCheck;
      undo = "checklist complete";
      break;
    case MessageType.FOR:
      text = "Expected task finish date revised";
      icon = faStopwatch
      undo = "task finish date revision";
      operator = "to";
      break;
    case MessageType.CST:
      text = "Task start constrained";
      icon = faStopwatch;
      undo = "task start constraint";
      operator = "to";
      break;
    case MessageType.PRG:
      text = "Task progressed to " + message.value + "%";
      icon = faFastForward;
      undo = "task progress";
      operator = "at";
      break;
    case MessageType.SPR:
      text = "Task added to sprint";
      icon = faPersonRunningFast;
      undo = "task sprint addition"
      break;
    case MessageType.TOA:
      text = "Task owner added";
      icon = faUsers;
      undo = "task owner addition";
      break;
    case MessageType.TOR:
      text = "Task owner removed";
      icon = faUsers;
      undo = "task owner removal";
      break;
    case MessageType.SPL:
      text = "Task locked into sprint";
      icon = faPersonRunningFast;
      undo = "task sprint lock";
      break;
    case MessageType.TBS:
       text = "This task is behind schedule, status report required";
       icon = faCalendar;
       undo = "task behind schedule";
      break;
    case MessageType.IMG:
      if (message.attachments && message.attachments.length > 1) {
        text = `${message.attachments.length} images attached`;
        icon = faImage;
        undo = "image attachment";
        break;
      }
        else {
        text = "Image attached";
        icon = faImage;
        undo = "image attachment";
        break;
      }
    case MessageType.FIL:
        if (message.attachments && message.attachments.length > 1) {
            text = `${message.attachments.length} files attached`;
            icon = faFileCirclePlus;
            undo = "file attachment";
            break;
        }
        else {
            text = "File attached";
            icon = faFileCirclePlus;
            undo = "file attachment";
            break;
        }
  }

  return { icon, text, color, undo, operator };
}

function getTimeWaypointFormat(message: MessageModel | null): string | null {
  return message
    ? message.logTimestamp ? moment.unix(message.logTimestamp.seconds).format("DD MMM YYYY") :
          moment.unix(message.timestamp.seconds).format("DD MMM YYYY")
    : null;
}

export const HeaderLedger = () => {
  const ledgerList = useLedgerListSelector();
  const activeTask = useActiveTaskSelector();
  const user = useUserSelector();
  const [message, setMessage] = useState<string>("");
  const dispatch = useDispatch();
  const timeWaypoints: string[] = useMemo(() => [], []);
  const imageInput: MutableRefObject<any> = useRef()
  const fileInput: MutableRefObject<any> = useRef()
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false)
  const [imageAttachments, setImageAttachments] = useState<any[]>([])
  const [fileAttachments, setFileAttachments] = useState<any[]>([])
  const fileRef = useRef(null)
  const projectMembers = useProjectMemberListSelector()
  const [openTag, setOpenTag] = useState<boolean>(false)
  const [currentUserSearch, setCurrentUserSearch] = useState<string>("")
  const [taggedUsers, setTaggedUsers] = useState<any[]>([])


  function selectImage () {
    imageInput.current!.click()
  }

  async function imageProcessing (file: any, type: string, index: number, attachmentCount: number, alternativeFile?: any) {
    let attachment: any = {}
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64 = reader.result
      const image = new Image()
      image.src = base64 as string
      image.onload = () => {
        // @ts-ignore
        EXIF.getData(image, function () {
          attachment.geoData = EXIF.getAllTags(image)
        })
      }
    }

    if (type === "heic") {
      const geoData = await ExifReader.load(alternativeFile)
      attachment.geoData = {
        // @ts-ignore
        GPSLatitude: geoData.GPSLatitude?.value.map((el: any[]) => el[0] / el[1]),
        // @ts-ignore
        GPSLongitude: geoData.GPSLongitude?.value.map((el: any[]) => el[0] / el[1]),
        GPSAltitude: geoData.GPSAltitude?.description,
        GPSImgDirection: geoData.GPSImgDirection?.description,
        DateTime: geoData.DateTime?.description,
      }
    } else {
      reader.readAsDataURL(file)
    }

    const binaryReader = new FileReader()
    binaryReader.onloadend = () => {
      attachment.arrayBuffer = binaryReader.result
    }
    binaryReader.readAsArrayBuffer(file)

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
      useWebWorker: true
    }
    const compressedFile = await imageCompression(file, options)

    const compressedReader = new FileReader()
    compressedReader.onloadend = () => {
      attachment.src = compressedReader.result
      attachment.filename = file.name
      attachment.modified = file.lastModified
      attachment.type = file.type

      setImageAttachments(prevState => [...prevState, attachment])
      if (index === attachmentCount) setAttachmentLoading(false)
    }
    compressedReader.readAsDataURL(compressedFile)
    return attachment
  }

  async function handleImage (event: any) {
    event.preventDefault()
    const files = event.target.files
    let attachments: any[] = []
    let index = 0
    for (let file of files) {
      index++
      if (file.type === "image/heic") {
        // convert heic to jpg
        const heicReader = new FileReader()
        heicReader.onloadend = async () => {
          const arrayBuffer = heicReader.result
          const heicBlob = new Blob([new Uint8Array(arrayBuffer as ArrayBuffer)], {type: "image/heic"})
          await heic2any({
              blob: heicBlob,
              toType: "image/jpeg",
              quality: file.size > 1048576 ? 1000000 / file.size : 1
          }).then((jpgBlob) => {
                // @ts-ignore
                const newFile = new File([jpgBlob], file.name.replace(/\.heic|.HEIC/, ".jpg"), {type: "image/jpeg"})
                imageProcessing(newFile, "heic", index, files.length, file).then((attachment) => attachments.push(attachment))
              })
        }
        heicReader.readAsArrayBuffer(file)
      } else {
        await imageProcessing(file, "normal", index, files.length, null).then((attachment) => attachments.push(attachment))
      }
    }
    return attachments
  }

  function selectFile () {
    fileInput.current!.click()
  }

  async function handleFile(event) {
    event.preventDefault()
    const files = event.target.files
    let attachments: any[] = []
    for (const file of files) {
      let attachment: any = {}
      const reader = new FileReader()
      reader.onloadend = (e) => {
        attachment.fileName = file.name
        attachment.arrayBuffer = reader.result
        attachment.modified = file.lastModified
        attachment.type = file.type
        setFileAttachments(prevState => [...prevState, attachment])
      }
      reader.readAsArrayBuffer(file)
    }
    return attachments

  }

  const getTimeWaypoint = useCallback(
    (message: MessageModel): Maybe<string> => {
      const waypoint = getTimeWaypointFormat(message);
      const hasWaypoint = timeWaypoints.some((el) => el === waypoint);
      const now = waypoint === moment().format("DD MMM YYYY");

      if (hasWaypoint || now || !waypoint) {
        return waypoint;
      } else {
        timeWaypoints.push(waypoint);
        return waypoint;
      }
    },
    [timeWaypoints]
  );

  const sendPostHandler = useCallback(() => {
    if (activeTask && user) {
      if (imageAttachments.length > 0) {
        // @ts-ignore
        dispatch(TaskThunk.sendPostWithImages(activeTask.task_id, activeTask, user, message, imageAttachments, activeTask, taggedUsers.map((user) => user.userId)));
        setImageAttachments([])
      }
      if (fileAttachments.length > 0) {
        // @ts-ignore
        dispatch(TaskThunk.sendPostWithFiles(activeTask.task_id, activeTask, user, message, fileAttachments, activeTask, taggedUsers.map((user) => user.userId)));
        setFileAttachments([])
      }
      if (message !== "" && fileAttachments.length === 0 && imageAttachments.length === 0) {
        // @ts-ignore
        dispatch(TaskThunk.sendPost(activeTask.task_id, activeTask, message, user, activeTask!.taskListType, activeTask, taggedUsers.map((user) => user.userId)));
      }
      setMessage("");
    }
  }, [activeTask, dispatch, message, user]);

  // const updateUserTaskSeenHandler = useCallback(() => {
  //   if (activeTask && user) {
  //     // @ts-ignore
  //     dispatch(TaskThunk.updateUserTaskSeen(activeTask.task_id, user, true, activeTask!.taskListType));
  //   }
  // }, [activeTask, dispatch, user]);

  if (!activeTask) {
    return (
      <div style={{ padding: "7px 15px" }}>
        <Header as="h4">PLEASE SELECT A TASK</Header>
      </div>
    );
  }

  if (!activeTask || !user) {
    return null;
  }

  return (
    <div className="header-ledger-content-container">
      <Form
        className="header-ledger-post-commit-form"
        onSubmit={() => { sendPostHandler();  }}>
        <p className="header-ledger-title">Ledger</p>
        <Form.Field style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <Input
            className="full-width"
            value={message}
            onChange={(evt) => {
              setMessage(evt.target.value)
              if (openTag) {
                // @ts-ignore
                setCurrentUserSearch(evt.target.value.split("@").pop())
              }
              if (taggedUsers.length > 0) {
                setTaggedUsers(prevState => prevState.filter((user) => evt.target.value.includes(user.userEmail.substring(0, user.userEmail.indexOf("@")))))
              }
            }}
            onKeyPress={(evt) => {
              if (evt.key === "@") {
                setOpenTag(true)
              }
            }}
            action={{ icon: "send" }}
            placeholder="Post a comment"
          />
          {attachmentLoading ? (
              <div className="attachment-loading">
                <Loader active inline size={"small"}/>
              </div>
          ) :
          <Dropdown icon={"attach"} direction={"left"} style={{marginRight: "15px"}}>
            <Dropdown.Menu>
              {/*<Dropdown.Header icon={faPaperclip} content={"Attach"} />*/}
              <input
                  type="file"
                  style={{"display": "none"}}
                  ref={imageInput}
                  accept={"image/heic, image/*"}
                  multiple
                  onChange={(e) => {
                    setAttachmentLoading(true)
                    handleImage(e).catch(error => console.log(error))}}/>
              <Dropdown.Item onClick={selectImage}>
                <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <FontAwesomeIcon icon={faImage}/>
                  <p style={{marginLeft: "10px"}}>Attach a photo</p>
                </span>
              </Dropdown.Item>
              <input
                  type="file"
                  style={{"display": "none"}}
                  ref={fileInput}
                  multiple
                  onChange={(e) => {
                    setAttachmentLoading(true)
                    handleFile(e).then(() => setAttachmentLoading(false))
                  }}/>
              <Dropdown.Item onClick={selectFile}>
                <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <FontAwesomeIcon icon={faFileCirclePlus}/>
                  <p style={{marginLeft: "10px"}}>Attach a file</p>
                </span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          }
        </Form.Field>
      </Form>
      <Dropdown open={openTag} onClose={() => setOpenTag(false)} icon={null}>
        <Dropdown.Menu className={'ledger-member-dropdown-menu'}>
          {projectMembers
              .filter((member) => member.userEmail.includes(currentUserSearch))
              .filter((member) => !taggedUsers.includes(member))
              .filter((member) => member.grade !== "Groups")
              .map((member, key) => (
                <Dropdown.Item key={key} onClick={() => {
                    setMessage(prevState => prevState.substring(0, prevState.lastIndexOf("@")) + `@${member.userEmail.substring(0, member.userEmail.indexOf("@"))} `)
                    setTaggedUsers(prevState => [...prevState, member])
                    setOpenTag(false)
                    // get focus back to input
                    // @ts-ignore
                    document.querySelector(".header-ledger-post-commit-form input").focus()
                }}>
                    <span style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <UserImage user={member} width={20} height={20}/>
                    <p style={{marginLeft: "0px"}}>{member.userEmail}</p>
                    </span>
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
      {imageAttachments.length > 0 && (
          <div className="image-attachments">
            {imageAttachments.map((image, key) => (
                <div key={key} className="image-attachment">
                  <img src={image.src} alt={`attachment-${key}`}/>
                  <button className="image-attachment-remove"
                          onClick={() => setImageAttachments(
                              prevState => prevState.filter((_, index) => index !== key))}
                  >X</button>
                </div>
            ))}
          </div>
      )}
      {fileAttachments.length > 0 && (
          <div className="image-attachments">
            {fileAttachments.map((image, key) => (
                <div key={key} className="image-attachment">
                  <FontAwesomeIcon icon={getFileIcon(image.type)} size={"3x"}/>
                  <button className="image-attachment-remove"
                          onClick={() => setFileAttachments(
                              prevState => prevState.filter((_, index) => index !== key))}
                  >X</button>
                </div>
            ))}
          </div>
      )}
      {!ledgerList.length && (
          <>
            <div className="start-chat">
              <FontAwesomeIcon icon={faMessages}/>
              <p>Start this task conversation</p>
            </div>
          </>
      )}
      <div className="header-ledger-list-container">
      {ledgerList.map((message, key) => {
          let ledgerUser = projectMembers.find(member => member.userId === message.userId)
          const confForMsg = getConfForMsg(message, ledgerUser ? ledgerUser : user);
          const timeWaypoint =
            getTimeWaypointFormat(message) !==
            getTimeWaypointFormat(ledgerList[key - 1])
              ? getTimeWaypoint(message)
              : null;
          return (
            <React.Fragment key={key}>
              <HeaderLedgerItem
                confForMsg={confForMsg}
                timeWaypoint={timeWaypoint}
                activeTask={activeTask}
                user={user}
                message={message}
                ledgerUser={ledgerUser}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
