import * as teamActions from "../actions/team.actions";
import { ThunkResult } from "../store.types";
import TeamTransactions from "./transactions/team.transactions";
import { MemberOrganizationEnum } from "../../models/member-organization.enum";
import { GateKeeperEum } from "../../models/gate-keeper.enum";
import { subsManager } from "../middleware/subs-manager/subs-manager";
import ProjectMembersModel from "../../models/responses/project-members.model";

export class TeamThunk {
  static makeEvaluatorToggle(memberId: string): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      dispatch(teamActions.Actions.loadingTeamSidebar());
      await TeamTransactions.makeEvaluatorToggle(memberId);
    };
  }

  static removeMember(
    memberId: string,
    projectId: string,
    type: string,
    nominatedMembersList?: { userEmail: string; id: string }[],
    lastSuperActor?: boolean,
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      dispatch(teamActions.Actions.loadingTeamSidebar());
      await TeamTransactions.removeMember(
        memberId,
        projectId,
        type,
        nominatedMembersList,
        lastSuperActor,
      ).then(() => {
        dispatch(teamActions.Actions.loadingTeamSidebar());
      });
    };
  }

  static addNewTaskforceMember(
    userEmail: string,
    organisation: MemberOrganizationEnum
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      const project = getState().project.activeProject;
      if (project) {
        dispatch(teamActions.Actions.loadingTeamSidebar());
        await TeamTransactions.addNewTaskforceMember(
          userEmail,
          project.projectId,
          organisation
        );
      }
    };
  }

  static addNewProjectMember(
    userEmail: string,
    organisation: MemberOrganizationEnum
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      const project = getState().project.activeProject;
      if (project) {
        dispatch(teamActions.Actions.loadingTeamSidebar());
        await TeamTransactions.addNewMember(
          userEmail,
          project.projectId,
          organisation, project.clientId || ""
        ).then(() => {
          dispatch(teamActions.Actions.loadingTeamSidebar());
          subsManager.subscribePendingMemberList(project.projectId);
        });
      }
    };
  }

  static addNewProjectGroup(groupName: string): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      const project = getState().project.activeProject;
      if (project) {
        dispatch(teamActions.Actions.loadingTeamSidebar());
        await TeamTransactions.addNewGroup(groupName, project.projectId).then(
          () => {
            dispatch(teamActions.Actions.loadingTeamSidebar());
          }
        );
      }
    };
  }

  static changeMemberGrade(
    grade: MemberOrganizationEnum,
    member: ProjectMembersModel,
    projectId: string
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      dispatch(teamActions.Actions.loadingMemberGrade());
      await TeamTransactions.changeMemberGrade(grade, member.memberId);
      subsManager.subscribeProjectPermission(member.userId);
      dispatch(teamActions.Actions.loadMemberGrade());
    };
  }

  static changeMemberGateKeeper(
    gateKeeper: GateKeeperEum,
    memberId: string,
    projectId: string
  ): ThunkResult<Promise<void>> {
    return async (dispatch, getState) => {
      dispatch(teamActions.Actions.loadingMemberGateKeeper());
      await TeamTransactions.changeMemberGateKeeper(gateKeeper, memberId);
      dispatch(teamActions.Actions.loadMemberGateKeeper());
    };
  }
}
