import React, {useCallback, useEffect, useMemo, useState} from "react";
import styles from "./TaskContainer.module.scss";
import TaskModel from "../../../../../../models/responses/task.model";
import {TaskIcon} from "../TaskIcon/TaskIcon";
import {Dropdown, Loader} from "semantic-ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faCirclePlay,
    faCircleStop,
    faFastForward,
    faSnooze
} from "@fortawesome/pro-light-svg-icons";
import {
    faCirclePlay as faPlaySolid,
    faCircleStop as faStopSolid,
    faFastForward as faFastForwardSolid,
    faHandshakeAngle
} from "@fortawesome/free-solid-svg-icons";
import {useTypedSelector} from "../../../../../../store/selectors/selectors.utils";
import {TaskOptions} from "./TaskOptions";
import * as taskActions from "../../../../../../store/actions/task.actions";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import {
    useActiveTaskSelector,
    usePredeccessorsViewSelector,
    useSuccessorsViewSelector,
} from "../../../../../../store/selectors/task/task.selectors";
import {Maybe} from "@martin_hotell/rex-tils";
import {useRelationshipsSelector} from "../../../../../../store/selectors/relationships/relations.selectors";
import TaskStatusModel from "../../../../../../models/responses/task-status.model";
import moment from "moment";
import {daysArrayFromDuration,} from "../../../../../../utils/calculations.utils";
import {
    useActiveProjectSelector,
    useProjectViewSelector,
    useViewWidthSelector
} from "../../../../../../store/selectors/project.selectors";
import {TaskType} from "../../../../../../models/task-type";
import {TaskEventModal} from "../TaskEventModal";
import EventTextModel from "../../../../../../models/event-text-model.enum";
import {EvaluationOptions} from "../../../../../../models/evaluation-options.enum";
import {matchPath, useNavigate} from "react-router-dom";
import RouteNameConstants from "../../../../../../constants/route-name.constants";
import {COLLECTIONS} from "../../../../../../firebase/constants";
import FirebaseUsage from "../../../../../../firebase/firebase.usage";
import {CpmTaskModel} from "../../../../../../models/responses/cpm-task.model";
import {
    convertDateToIndex,
    convertIndexToSeconds
} from "../../../../../../utils/cpm-functions/cpm-app/functions/handleEvent";
import {taskNarrative} from "../../../../../../utils/task-narrative.utils";
import {SprintCategory} from "../../../../../../models/sprint.model";
import TaskProgressModal from "../TaskProgressModal";
import ProgressSlider from "./ProgressSlider";

interface InjectedProps {
  task: TaskModel;
  goToPred?: () => void;
  goToSuc?: () => void;
  hidePassBtn?: boolean;
  type?: "pred" | "succ";
  showPredType?: boolean;
  cpmTask: CpmTaskModel;
  calendarsMap: Map<string, any>;
  index?: number | null;
  navigateToTask?: (task: TaskModel) => void;
}

export const TaskContainer: React.FC<InjectedProps> = React.memo(
  ({ goToPred, goToSuc, ...props }) => {
    const [taskInfoClass, setTaskInfoClass] = useState<any>(null);
    const [spinningIcon, setSpinningIcon] = useState<boolean>(true);
    const { task, cpmTask } = props;
    const [sliderValue, setSliderValue] = useState<number>(
          task.progress && task.progress.length ? task.progress.sort((a, b) => b.date.seconds - a.date.seconds)[0].progress : 0);
    const [progressChanged, setProgressChanged] = useState<boolean>(false);
    const [showEventModal, setShowEventModal] = useState<boolean>(false);
    const [showProgressModal, setShowProgressModal] = useState<boolean>(false);
      const openPredecessors = useTypedSelector(
          (state) => state.task.predecessors.open
      );
      const openSuccessors = useTypedSelector(
          (state) => state.task.successors.open
      );
    const currentUser = useTypedSelector((state) => state.authorization.user);
    const dispatch = useDispatch();
    const activeTask = useActiveTaskSelector();
    const activeProject = useActiveProjectSelector();
    const projectView = useProjectViewSelector();
    const viewWidth = useViewWidthSelector();
    const criticalThreshold = 5

      const selectSprintIcon = (task: TaskModel) => {
        if (task.sprint && task.sprint === activeProject!.snapshotDataVersionId) {
            switch (task.sprintCategory) {
                case SprintCategory.START_AND_FINISH:
                    if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.PlannedStartIcon} icon={faPlaySolid}/><FontAwesomeIcon
                            className={styles.PlannedFinishIcon} icon={faStopSolid}/>
                        </div>
                    } else if (task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.PlannedStartIcon} icon={faPlaySolid}/><FontAwesomeIcon
                            icon={faCircleStop}/>
                        </div>
                    } else {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon icon={faCirclePlay}/><FontAwesomeIcon icon={faCircleStop}/>
                        </div>
                    }
                case SprintCategory.START:
                    if (task.status === TaskStatusModel.NOT_STARTED || task.status === TaskStatusModel.BLOCK) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon icon={faCirclePlay}/>
                        </div>
                    } else {
                        if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                            return <div className={styles.SprintIcons}>
                                <FontAwesomeIcon className={styles.PlannedStartIcon} icon={faPlaySolid}/><FontAwesomeIcon
                                className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                            </div>
                        }
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.PlannedStartIcon} icon={faPlaySolid}/>
                        </div>
                    }
                case SprintCategory.FINISH:
                    if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.PlannedFinishIcon} icon={faStopSolid}/>
                        </div>
                    } else {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon icon={faCircleStop}/>
                        </div>
                    }
                case SprintCategory.PROGRESS:
                    if ((task.progress && task.progress.filter(p => p.date.seconds >= activeProject!.lastSnapshotTimestamp!.seconds).length)
                    || (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE)) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.FastForwardIconSolid} icon={faFastForwardSolid}/>
                        </div>
                    } else {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.FastForwardIcon} icon={faFastForward}/>
                        </div>
                    }
                default: // unplanned works
                    if (task.act_start_date && task.act_start_date.seconds > activeProject!.lastSnapshotTimestamp!.seconds) { // unplanned start
                        if (task.act_end_date || task.declaredCompleteTimestamp) {
                            return <div className={styles.SprintIcons}>
                                <FontAwesomeIcon className={styles.UnlannedStartIcon}
                                                 icon={faPlaySolid}/><FontAwesomeIcon
                                className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                            </div>
                        } else {
                            return <div className={styles.SprintIcons}>
                                <FontAwesomeIcon className={styles.UnplannedStartIcon} icon={faPlaySolid}/>
                            </div>
                        }
                    } else if (task.act_end_date && task.act_end_date.seconds > activeProject!.lastSnapshotTimestamp!.seconds) { // unplanned complete
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                        </div>
                    } else if (task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.seconds > activeProject!.lastSnapshotTimestamp!.seconds) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                        </div>
                    }
                    if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                        if (task.startSprintId === activeProject?.snapshotDataVersionId) {
                            return <div className={styles.SprintIcons}>
                                <FontAwesomeIcon className={styles.EarlyIcon} icon={faPlaySolid}/><FontAwesomeIcon
                                className={styles.EarlyIcon} icon={faStopSolid}/>
                            </div>
                        } else {
                            return <div className={styles.SprintIcons}>
                                <FontAwesomeIcon className={styles.EarlyIcon} icon={faStopSolid}/>
                            </div>
                        }
                    } else if (task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.EarlyIcon} icon={faPlaySolid}/>
                        </div>
                    }
                    return null;
            }
            } else if (task.finishSprintId === activeProject?.snapshotDataVersionId || task.startSprintId === activeProject?.snapshotDataVersionId) {
                if (task.act_start_date && task.act_start_date.seconds > activeProject!.lastSnapshotTimestamp!.seconds) { // unplanned start
                    if (task.act_end_date || task.declaredCompleteTimestamp) {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.UnplannedStartIcon} icon={faPlaySolid}/><FontAwesomeIcon className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                        </div>
                    } else {
                        return <div className={styles.SprintIcons}>
                            <FontAwesomeIcon className={styles.UnplannedStartIcon} icon={faPlaySolid}/>
                        </div>
                    }
                } else if (task.act_end_date && task.act_end_date.seconds > activeProject!.lastSnapshotTimestamp!.seconds) { // unplanned complete
                    return <div className={styles.SprintIcons}>
                        <FontAwesomeIcon className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                    </div>
                } else if (task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.seconds > activeProject!.lastSnapshotTimestamp!.seconds) {
                    return <div className={styles.SprintIcons}>
                        <FontAwesomeIcon className={styles.UnplannedFinishIcon} icon={faStopSolid}/>
                    </div>
                }
            if (task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE) {
                if (task.startSprintId === activeProject?.snapshotDataVersionId) {
                    return <div className={styles.SprintIcons}>
                        <FontAwesomeIcon className={styles.EarlyIcon} icon={faPlaySolid}/><FontAwesomeIcon
                        className={styles.EarlyIcon} icon={faStopSolid}/>
                    </div>
                } else {
                    return <div className={styles.SprintIcons}>
                        <FontAwesomeIcon className={styles.EarlyIcon} icon={faStopSolid}/>
                    </div>
                }
            } else if (task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED) {
                return <div className={styles.SprintIcons}>
                    <FontAwesomeIcon className={styles.EarlyIcon} icon={faPlaySolid}/>
                </div>
            }
                return null;
        }
      }

    // const canMakeActive = useTypedSelector(state => !state.task.predecessors.open && !state.task.successors.open);
    const loading = useTypedSelector<boolean>(
      (state) => state.task.toolbarLoadingMap[task.task_id]
    );
    const relationships = useRelationshipsSelector(task.task_id);
    const predType = useMemo(
      () =>
        relationships.length
          ? relationships[0].pred_type.replace("PR_", "")
          : "",
      [relationships]
    );
    const [delayOnMouseEnter, setDelayOnMouseEnter] = useState(null);
    const [taskInfo, setTaskInfo] = useState<string>("");
    const history = useNavigate();
    // const [underneathText, setUnderneathText] = useState<any>(null);
    const [criticalIn, setCriticalIn] = useState<any>("-");
    const [isCritical, setIsCritical] = useState<any>(false);
    const [isCriticalPath, setIsCriticalPath] = useState<any>(false);
    const [isNearCritical, setIsNearCritical] = useState<any>(false);
    const [taskOptionsOpen, setTaskOptionsOpen] = useState<boolean>(false);
    const [editReason, setEditReason] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<EventTextModel>(EventTextModel.COMPLETE_TASK);
      const [suspendReason, setSuspendReason] = useState<
          any
      >("No reason");
      const [evaluationResult, setEvaluationResult] = useState<
          EvaluationOptions | "No result"
      >("No result");
      const [defDate, setDefDate] = useState<string>("");

      // const [completeEmail, setCompleteEmail] = useState<any>(null);
    const predecessorsView = usePredeccessorsViewSelector();
    const successorsView = useSuccessorsViewSelector();
    const today = moment(new Date())
        .hours(0)
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .toDate();

    const tomorrow = moment(new Date())
        .add(1, "days")
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .toDate();

    const todayIndex = convertDateToIndex(today.getTime() / 1000, props.cpmTask.cal_id, props.calendarsMap);
    const tomorrowIndex = convertDateToIndex(tomorrow.getTime() / 1000, props.cpmTask.cal_id, props.calendarsMap);
    const workingHoursPerDay: number = props.calendarsMap.get(`whd:${cpmTask.cal_id}`) || 8;

    const convertToDate = (index) => {
        return new Date(convertIndexToSeconds(index, cpmTask.cal_id, props.calendarsMap) * 1000)
    }

    function formatTimeDifference(seconds: number, workingHoursPerDay: number, deadline: boolean) {
        if (deadline && seconds < workingHoursPerDay * 3600) {
            return "Now"
        }
        // let hours: string | number, minutes: string | number, secondsLeft: string | number, secs: string | number;
        if (Math.abs(Math.round((seconds / 3600) / workingHoursPerDay)) >= 1) {
            return `${Math.floor((seconds / 3600) / workingHoursPerDay)} day${Math.floor((seconds / 3600) / workingHoursPerDay) > 1 ? "s" : ""}`;
        } else {
            return "< - - - >";
        }
        // hours = (Math.floor(seconds / 3600)).toString().padStart(2, "0");
        // secondsLeft = seconds % 3600;
        // minutes = (Math.floor(secondsLeft / 60)).toString().padStart(2, "0");
        // secs = (Math.floor(secondsLeft % 60)).toString().padStart(2, "0");
        // if (deadline) {
        //     return hours !== '00' ? `${hours} : ${minutes} : ${secs}` : "< - - - >";
        // } else {
        //     return hours !== '00' ? `${hours} : ${minutes} : ${secs}` : "< - - - >";
        // }
    }

    function calculateDeadline(task: TaskModel) {
      let allSecs: number = 0;
      switch (task.status) {
        case TaskStatusModel.COMPLETE: {
          setCriticalIn("-")
          break;
        }
        case TaskStatusModel.DECLARED_COMPLETE: {
          setCriticalIn("-")
          break;
        }
        case TaskStatusModel.IN_PROGRESS:
        case TaskStatusModel.SUSPENDED: {
            // @ts-ignore
            allSecs = (cpmTask.lf - todayIndex) * 1800;
            setCriticalIn(formatTimeDifference(allSecs, workingHoursPerDay, true))
            break;
        }
          case TaskStatusModel.NOT_STARTED:
          case TaskStatusModel.BLOCK: {
              // @ts-ignore
              allSecs = (cpmTask.lf - todayIndex) * 1800;
              setCriticalIn(formatTimeDifference(allSecs, workingHoursPerDay, true))
              break;
          }
      }
    }

    function setTarget(task: TaskModel) {
        let actualDuration: number, originalDuration: number, hours: string | number, minutes: string | number, seconds: string | number;
        switch (task.status) {
            case TaskStatusModel.COMPLETE: {
                originalDuration = task.targetDuration;
                // @ts-ignore
                actualDuration = cpmTask.ef - cpmTask.es;
                setTaskInfo(formatTimeDifference((originalDuration - actualDuration) * 1800,
                    workingHoursPerDay,
                    false));
                if (actualDuration > originalDuration) {
                    setTaskInfoClass("negative");
                }
                break;
            }
            case TaskStatusModel.DECLARED_COMPLETE: {
                originalDuration = task.targetDuration;

                // @ts-ignore
                actualDuration = cpmTask.ef - cpmTask.es;
                setTaskInfo(formatTimeDifference((originalDuration - actualDuration) * 1800,
                    workingHoursPerDay,
                    false));
                if (actualDuration > originalDuration) {
                    setTaskInfoClass("negative");
                }
                break;
            }
            case TaskStatusModel.IN_PROGRESS:
            case TaskStatusModel.SUSPENDED: {
                const halfHours = task.targetFinishDate ?
                    convertDateToIndex(Math.floor((task.targetFinishDate.toDate().getTime() / 1000) / 1800) * 1800 , cpmTask.cal_id, props.calendarsMap)
                    - convertDateToIndex(Math.floor((new Date().getTime() / 1000) / 1800) * 1800 , cpmTask.cal_id, props.calendarsMap) :
                    task.remainingDuration;
                setTaskInfo(formatTimeDifference(halfHours * 1800, workingHoursPerDay, false));
                if (task.constraint_type !== "CS_ALAP") {
                    if (cpmTask.float! < workingHoursPerDay * 2) {
                        if (convertToDate(cpmTask.ls).getTime() < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true)
                            setIsNearCritical(false);
                        }
                    } else if (cpmTask.float! <= criticalThreshold * workingHoursPerDay * 2) {
                        if (convertToDate(cpmTask.ls).getTime() < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(false);
                            setIsNearCritical(true);
                        }
                    }
                } else {
                    if ((convertToDate(cpmTask.ls).getTime() / 1000) - (today.getTime() / 1000) < 86400) {
                        if (convertToDate(cpmTask.ls).getTime() < tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if ((convertToDate(cpmTask.ls).getTime() / 1000) - (today.getTime() / 1000) < 86400 * criticalThreshold) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                }
                break;
            }
            case TaskStatusModel.NOT_STARTED:
            case TaskStatusModel.BLOCK: {
                const halfHours = task.targetFinishDate ?
                    convertDateToIndex(Math.floor((task.targetFinishDate.toDate().getTime() / 1000) / 1800) * 1800 , cpmTask.cal_id, props.calendarsMap)
                    - convertDateToIndex(Math.floor((new Date().getTime() / 1000) / 1800) * 1800 , cpmTask.cal_id, props.calendarsMap) :
                    task.remainingDuration;
                setTaskInfo(formatTimeDifference(halfHours * 1800, workingHoursPerDay, false));
                if (task.constraint_type !== "CS_ALAP") {
                    if (cpmTask.float! < workingHoursPerDay * 2) {
                        if (convertToDate(cpmTask.ls).getTime() <= tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if (cpmTask.float! <= criticalThreshold * workingHoursPerDay * 2) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                } else {
                    if (cpmTask.float! < workingHoursPerDay * 2)  {
                        if (convertToDate(cpmTask.ls).getTime() <= tomorrow.getTime()) {
                            setIsCritical(true);
                            setIsCriticalPath(false)
                            setIsNearCritical(false);
                        } else {
                            setIsCriticalPath(true);
                            setIsNearCritical(false);
                        }
                    } else if ((convertToDate(cpmTask.ls).getTime() / 1000) - (today.getTime() / 1000) < 86400 * criticalThreshold) {
                        setIsCritical(false);
                        setIsNearCritical(true);
                    }
                }
                break;
            }
        }
    }

    const taskProgress = useMemo(() => {
      if (
        task.status === TaskStatusModel.COMPLETE ||
        task.status === TaskStatusModel.DECLARED_COMPLETE
      ) {
        return 100;
      } else {
        if (task.checklist && task.checklist.length > 0) {
          const filteredChecklist = task.checklist.filter((el) => el.isChecked);
          const percentage =
            (filteredChecklist.length / task.checklist.length) * 100;
          return percentage;
        }
        return 0;
      }
    }, [task.status, task.checklist]);

    const getActualPeriodInProcess = useCallback(() => {
      return task.enteredWorkInProcessTime
        ? daysArrayFromDuration(
            task.enteredWorkInProcessTime.toDate(),
            new Date()
          ).length - 2
        : 0;
    }, [task.enteredWorkInProcessTime]);

    useEffect(
      () => {
          let interval
          setTarget(task)
          calculateDeadline(task)
          if (task.status == TaskStatusModel.IN_PROGRESS) {
            interval = setInterval(function () {
              const nowNearestHalfHour = Math.floor(new Date().getTime() / 1800000) * 1800;
              let isNonWorking =  convertIndexToSeconds(convertDateToIndex(nowNearestHalfHour, cpmTask.cal_id, props.calendarsMap), cpmTask.cal_id, props.calendarsMap) !== nowNearestHalfHour;
              let timeNow = new Date().getTime();
              let endDate = task.targetFinishDate ? task.targetFinishDate.toDate() : convertToDate(cpmTask.lf);
              if (!isNonWorking) {
                setSpinningIcon(true);
                if (endDate.getTime() > timeNow) {
                  setTaskInfoClass(null);
                } else if (endDate.getTime() < timeNow) {
                  setTaskInfoClass("negative");
                }
              } else {
                setSpinningIcon(false);
                if (endDate.getTime() > timeNow) {
                  setTaskInfoClass(null);
                } else if (endDate.getTime() < timeNow) {
                  setTaskInfoClass("negative");
                }
              }
                calculateDeadline(task)
                setTarget(task)
            }, 1000);
            } else if (cpmTask.flow) {
              setSpinningIcon(false);
          }
        return () => {
          clearInterval(interval);
        };
      },
      [task]
    );

    const formatCriticalInTimeDifference = (days) => {
      if (days <= 0) {
        return "0 days";
      } else if (days === 1) {
        return "1 day";
      } else if (days <= 5) {
        return `${days} days`;
      } else if (days <= 20) {
        const weeks = Math.floor(days / 5);
        return `${weeks} week${weeks > 1 ? "s" : ""}`;
      } else {
        const months = Math.floor(days / 20);
        return `${months} month${months > 1 ? "s" : ""}`;
      }
    };

    const checkUserSeen = (task: any, currentUser: any) => {
      const userInTask =
        task.users &&
        task.users.find((el: any) => el.userId === currentUser?.userId);
      return userInTask && !userInTask.seen;
    }

    const selectActiveTask = () => {
        if (activeTask && activeTask.task_id === task.task_id &&
            !taskOptionsOpen &&
            !editReason &&
            !showEventModal &&
            !progressChanged
        ) {
            dispatch(taskActions.Actions.setActiveTask(null));
            const matchPathname = matchPath( {path: '/:projectId/flow/:task_id'}, window.location.pathname);
            const params: any = matchPathname
                ? matchPathname.params
                : {};
            const activeProjectId = params.project_id || localStorage.getItem('projectId');
            if (params.task_id) {
                history(`/${activeProjectId}/${RouteNameConstants.FLOW}`);
            }
        } else {
            if (openPredecessors && goToPred) {
                goToPred();
            }
            else if (openSuccessors && goToSuc) {
                goToSuc();
            }
            else {
                dispatch(taskActions.Actions.setActiveTask(task));
                console.log("task", cpmTask, {
                    es: new Date(convertIndexToSeconds(cpmTask.es, cpmTask.cal_id, props.calendarsMap) * 1000),
                    ef: new Date(convertIndexToSeconds(cpmTask.ef, cpmTask.cal_id, props.calendarsMap) * 1000),
                    ls: new Date(convertIndexToSeconds(cpmTask.ls, cpmTask.cal_id, props.calendarsMap) * 1000),
                    lf: new Date(convertIndexToSeconds(cpmTask.lf, cpmTask.cal_id, props.calendarsMap) * 1000),
                    fs: new Date(convertIndexToSeconds(cpmTask.fs, cpmTask.cal_id, props.calendarsMap) * 1000),
                    ff: new Date(convertIndexToSeconds(cpmTask.ff, cpmTask.cal_id, props.calendarsMap) * 1000),
                });
            }
        }
    }

    const lightSelectTask = useCallback(
      (task: Maybe<TaskModel>) => {
        clearTimeout(delayOnMouseEnter as any);
        setDelayOnMouseEnter(
          // @ts-ignore
          setTimeout(function () {
            // @ts-ignore
            dispatch(taskActions.Actions.lightSelectedTask(task));
          }, 500)
        );
      },
      [delayOnMouseEnter, dispatch]
    );

    const handleDelayReason = (reason: string, type: string) => {
        setModalMessage(EventTextModel.LOG_ISSUE)
        setSuspendReason(reason);
        setShowEventModal(true);
        return type === "suspend" ?
        FirebaseUsage.updateDoc(COLLECTIONS.TASKS, task.task_id, {suspendReason: reason})
            .catch((e) => console.error(e)) : null
    }

    const targetFronts = activeProject ? activeProject?.targetFronts[activeProject.calendarDict![task.calendar_id]] : 0

    const taskNarrativeText = taskNarrative(task);

    return (
      <div
        className={classNames(styles.Container, {
          [styles.Active]: Boolean(
            activeTask && activeTask.task_id === task.task_id
          ),
          [styles.MorePadding]: successorsView || predecessorsView,
        })}
        onClick={selectActiveTask}
        onMouseEnter={() => lightSelectTask(task)}
        onMouseLeave={() => lightSelectTask(null)}
      >
        {(task.status !== TaskStatusModel.COMPLETE &&
          task.status !== TaskStatusModel.DECLARED_COMPLETE) &&
            task.task_type !== TaskType.TT_FIN_MILE &&
            task.task_type !== TaskType.TT_MILE && (
          <p className={styles.index}>{props.index ? props.index : cpmTask.index}</p>
        )}
        {goToPred && !props.hidePassBtn && (
          <div
            className={classNames(styles.GoToRelationBtn, styles.GoToPred)}
            onClick={() => goToPred()}
            onMouseEnter={() => setTaskOptionsOpen(true)}
            onMouseLeave={() => setTaskOptionsOpen(false)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        )}
        {checkUserSeen(task, currentUser) ? (
          <div className={styles.Taskforcemessage}>
            <FontAwesomeIcon icon={faHandshakeAngle} />

          </div>
        ) : null}
        {currentUser &&
          task.taskForce.some((el) => el === currentUser.userId) && (
            <div className={`${checkUserSeen(task, currentUser) ? styles.Taskforcemessage : styles.Taskforce}`}>
              <FontAwesomeIcon icon={faHandshakeAngle} />
            </div>
          )}
        {task.task_type !== TaskType.TT_TASK && task.task_type !== TaskType.TT_RSRC ? (
          <div className={styles.IconWrap}>
            <TaskOptions showPopupHeader={false}
                 task={task}
                 type={props.type}
                 setTaskOptionsOpen={setTaskOptionsOpen}
                 isModalOpened={showEventModal}
                 setIsModalOpened={setShowEventModal}
                 setEventText={setModalMessage}
                setSuspendReason={setSuspendReason}
                setEvaluationResult={setEvaluationResult}
                setDefDate={setDefDate}
                 defDate={defDate}>
              <>
                {task.status === TaskStatusModel.IN_PROGRESS &&
                !spinningIcon ? (
                  <FontAwesomeIcon
                    className={styles.SnoozeIcon}
                    icon={faSnooze}
                  />
                ) : null}
                {loading ? (
                  <Loader active inline size="small" />
                ) : (
                  <TaskIcon
                    taskListType={task.taskListType}
                    cpmTask={cpmTask}
                    task={task}
                    spinningIcon={spinningIcon}
                    targetFronts={targetFronts}
                  />
                )}
              </>
            </TaskOptions>
            <div className={styles.ProgressBarWrap}>
              <div className={styles.ProgressBar}>
                <div
                  style={cpmTask.flow && task.checklist.length === 0 ? {
                      height: "100%"} : {
                    height: `${taskProgress}%`,
                  }}
                  className={task.status !== TaskStatusModel.DECLARED_COMPLETE && task.status !== TaskStatusModel.COMPLETE && task.checklist.length === 0 ?
                      cpmTask.predStatus === 1 ? styles.LineGreen : cpmTask.predStatus === 2 ? styles.LineYellow : styles.LineRed : styles.LineBlue}
                />
              </div>
            </div>
          </div>
        ) : (
          <TaskOptions showPopupHeader={false}
                       task={task}
                       type={props.type}
                       setTaskOptionsOpen={setTaskOptionsOpen}
                       isModalOpened={showEventModal}
                       setIsModalOpened={setShowEventModal}
                       setEventText={setModalMessage}
                       setSuspendReason={setSuspendReason}
                       setEvaluationResult={setEvaluationResult}
                       setDefDate={setDefDate}
                       defDate={defDate}>
            <>
              <div className={styles.IconWrap}>
                {task.status === TaskStatusModel.IN_PROGRESS &&
                !spinningIcon ? (
                  <FontAwesomeIcon
                    className={styles.SnoozeIcon}
                    icon={faSnooze}
                  />
                ) : null}
                {loading ? (
                  <Loader active inline size="small" />
                ) : (
                  <TaskIcon
                    taskListType={task.taskListType}
                    cpmTask={cpmTask}
                    task={task}
                    spinningIcon={spinningIcon}
                    targetFronts={targetFronts}
                  />
                )}
                <div className={styles.ProgressBarWrap}>
                  <div className={styles.ProgressBar}>
                    <div
                        style={(task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED || cpmTask.flow)
                        && task.checklist.length === 0 ? {
                            height: "100%"} : {
                            height: `${taskProgress}%`,
                        }}
                        className={task.status !== TaskStatusModel.DECLARED_COMPLETE && task.status !== TaskStatusModel.COMPLETE && task.checklist.length === 0 ?
                            cpmTask.predStatus === 1 ? styles.LineGreen : cpmTask.predStatus === 2 ? styles.LineYellow : styles.LineRed : styles.LineBlue}
                    />
                  </div>
                </div>
              </div>
            </>
          </TaskOptions>
        )}
          <div className={styles.Description}>
              <div className={styles.TaskDetails}>
                  <div className={styles.TaskName}>
                    <div className="d-flex">
                      <h4>{task.wbs}</h4>
                          {task.suspendReason ? (
                              <Dropdown scrolling icon={null}
                                        value={task.suspendReason}
                                        onChange={(e, {value}) =>
                                            handleDelayReason(value as string, "suspend")}
                                        options={activeProject!.issueCategories.map((el) => ({
                                            key: el.reason,
                                            text: el.text,
                                            value: el.reason,
                                        }))}
                                        trigger={
                                            <span
                                                onMouseEnter={() => setEditReason(true)}
                                                onMouseLeave={() => setEditReason(false)}
                                                className={styles.Reason}>{`${task.suspendReason}${
                                                task.status !== TaskStatusModel.COMPLETE && task.status !== TaskStatusModel.DECLARED_COMPLETE ?
                                                    task.reasonDate ? ` (${Math.floor((new Date().getTime() - (task.reasonDate.seconds * 1000)) / 86400000)} day${Math.floor((new Date().getTime() - (task.reasonDate.seconds * 1000)) / 86400000) > 1 ? "s" : ""})` : "" : ""}
                                            `}</span>}/>
                          ) : task.overdueReason ? (
                              <Dropdown scrolling icon={null}
                                        value={task.overdueReason}
                                        onChange={(e, {value}) =>
                                            handleDelayReason(value as string, "overdue")}
                                        options={activeProject!.issueCategories.map((el) => ({
                                            key: el.reason,
                                            text: el.text,
                                            value: el.reason,
                                        }))}
                                        trigger={
                                            <span
                                                onMouseEnter={() => setEditReason(true)}
                                                onMouseLeave={() => setEditReason(false)}
                                                className={styles.Reason}>{`${task.overdueReason}${
                                                    task.status !== TaskStatusModel.COMPLETE && task.status !== TaskStatusModel.DECLARED_COMPLETE ? 
                                                        task.reasonDate ? ` (${Math.floor((new Date().getTime() - (task.reasonDate.seconds * 1000)) / 86400000)} day${Math.floor((new Date().getTime() - (task.reasonDate.seconds * 1000)) / 86400000) > 1 ? "s" : ""})` : "" : ""}
                                            `}</span>}/>
                          ) : null}
                          {activeProject!.snapshotDataVersionId &&
                              selectSprintIcon(task)
                          }
                      </div>
                      <h5>
                          {`${task.task_name}`}
                          <span className={styles.TaskCode}>{` (${task.task_code})`}{
                              task.progress && task.status === TaskStatusModel.IN_PROGRESS &&
                              ` - (${sliderValue}%)`}
                          </span>
                      </h5>
                  </div>
              </div>
              {activeTask && task.task_id === activeTask.task_id && task.status === TaskStatusModel.IN_PROGRESS &&
                  (task.taskForce.includes(currentUser!.userId) || task.progress?.length) && (
                  <span className={styles.TaskProgress} style={{display: "grid", gridTemplateColumns: "95% 5%", marginTop: "5px"}}
                  >
                      <ProgressSlider
                          sliderValue={sliderValue}
                          setSliderValue={setSliderValue}
                          setProgressChanged={setProgressChanged}
                          task={task}
                          currentUser={currentUser}
                          setShowProgressModal={setShowProgressModal}
                          setModalMessage={setModalMessage}
                          setShowEventModal={setShowEventModal}
                          progressChanged={progressChanged}
                      />
                    <p className={styles.SliderValue}>{sliderValue}%</p>
                </span>
              )}
          </div>
          {
              showEventModal &&
              (<TaskEventModal
                  eventText={modalMessage}
                  opened={showEventModal}
                  setOpened={setShowEventModal}
                  task={task}
                  suspendReason={suspendReason}
                  navigateToTask={props.navigateToTask}
                  // evaluationResult={evaluationResult}
                  defaultDate={defDate}/>)
          }
          {showProgressModal && (
              <TaskProgressModal
                  sliderValue={sliderValue}
                  task={task}
                  setShowModal={setShowProgressModal}
                  setProgressChanged={setProgressChanged}
                  setSliderValue={setSliderValue}
              />
          )}
        {props.showPredType && predType && (
          <div className={styles.PredTypeLabel}>{predType} - </div>
        )}
          <div className={styles.rightFilters}>
              {/*{taskNarrativeText && viewWidth >= 1400 && <div className={styles.TaskNarrative}>*/}
              {/*    {taskNarrativeText}*/}
              {/*</div>}*/}
              {viewWidth >= 1200 && <div
                  className={task.startReforecastCount ? styles.TaskInfoDatesReforecast : styles.TaskInfoDates}
                  style={{textAlign: "center", minWidth: "100px"}}>
                  {moment(task.act_start_date ? task.act_start_date.toDate() : new Date(convertIndexToSeconds(cpmTask.fs, cpmTask.cal_id, props.calendarsMap) * 1000))
                  .format("DD-MMM-YY")}
                  {task.startReforecastCount &&
                      <span className={styles.ReforecastIcon}>
                          {task.startReforecastCount}
                      </span>}
              </div>}
              {viewWidth >= 1200 && <div
                  className={task.endReforecastCount ? styles.TaskInfoDatesReforecast : styles.TaskInfoDates}
                  style={{textAlign: "center", minWidth: "100px"}}>
                  {moment(task.status === TaskStatusModel.COMPLETE || task.status === TaskStatusModel.DECLARED_COMPLETE ?
                  task.act_end_date ? task.act_end_date.toDate() : task.declaredCompleteTimestamp!.toDate() :
                      new Date(convertIndexToSeconds(task.status === TaskStatusModel.IN_PROGRESS || task.status === TaskStatusModel.SUSPENDED ? cpmTask.ef : cpmTask.ff, cpmTask.cal_id, props.calendarsMap) * 1000))
                  .format("DD-MMM-YY")}
                  {task.endReforecastCount &&
                      <span className={styles.ReforecastIcon}>
                          {task.endReforecastCount}
                      </span>}
              </div>}
              <div className={styles.TaskInfo} style={{textAlign: "right", minWidth: "100px"}}>
                  <div className={taskInfoClass ? styles.Negative : ""}>
                      {taskInfo}
                      <br/>
                      {taskInfoClass === "negative" && !task.overdueReason ?
                          <div className={taskInfoClass ? styles.Negative : ""}
                               style={{
                                   display: "flex",
                                   flexDirection: "column",
                                   justifyContent: "center",
                                   alignItems: "center",
                                   height: "100%",
                                   marginRight: "-20px",
                                   fontSize: "12px",
                               }}
                          >
                              <Dropdown scrolling text={"Log issue"}
                                        onChange={(e, {value}) =>
                                            handleDelayReason(value as string, "overdue")}
                                        options={activeProject!.issueCategories.map((el) => ({
                                            key: el.reason,
                                            text: el.text,
                                            value: el.reason,
                                        }))}
                              />
                          </div>
                          :
                          // <span onClick={() => setEditReason(true)} className={styles.Reason}>{task.overdueReason}</span> :
                          null
                      }
                  </div>
              </div>
              <div className={styles.criticalIn}  style={{textAlign: "right"}}>
                  <p>{criticalIn}</p>
                  {isCritical ? <p className={classNames(styles.critical,
                          {
                              [styles.Blinking]: task.status === TaskStatusModel.BLOCK || task.status === TaskStatusModel.SUSPENDED
                          })}>Critical</p>
                      : isCriticalPath ? <p className={classNames(styles.criticalPath,
                          {
                              [styles.Blinking]: task.status === TaskStatusModel.BLOCK || task.status === TaskStatusModel.SUSPENDED
                          })}>Critical path</p> : null}
                  {isNearCritical && (
                      <p className={classNames(styles.nearCritical,
                          {
                              [styles.Blinking]: task.status === TaskStatusModel.BLOCK || task.status === TaskStatusModel.SUSPENDED
                          })}>Near-critical</p>
                  )}
              </div>
          </div>
          {!props.hidePassBtn && goToSuc && (
              <div
                  className={classNames(styles.GoToRelationBtn, styles.GoToSuc)}
                  onClick={() => goToSuc()}
                  onMouseEnter={() => setTaskOptionsOpen(true)}
                  onMouseLeave={() => setTaskOptionsOpen(false)}
              >
                  <FontAwesomeIcon icon={faAngleRight}/>
              </div>
          )}
      </div>
    );
  }
);
