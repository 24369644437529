import { UserDocModel } from "../../models/responses/user.model";
import FirebaseUsage from "../firebase.usage";
import { ProjectFunctions } from "../../models/firebase-functions.model";
import ProjectModel, { CausedBy } from "../../models/responses/project.model";
import { Omit } from "@martin_hotell/rex-tils";
import VListModel from "../../models/responses/v_list.model";
import { CancelUpdating } from "../../utils/cancel-updating-strategy.model";
import CpmFunctionsController from "../../utils/cpm-functions/cpm-functions.controller";
import XerToJsonController from "../../utils/xer-to-json/xer-to-json.controller";

export default class FirebaseProjectFunctions {
  public static async uploadNewProject(args: {
    project: Omit<
      ProjectModel,
      "projectId" | "enableLogging" | "grade" | "curves" | "racingLine"
    > & { racingLine: string };
    userId: string | null;
    tasksJsonStr: string;
    causedBy: CausedBy | null;
    email: string;
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.UPLOAD_PROJECT)({
      ...args,
    });
  }

  public static async updateProjectFloat(args: {
    projectId: string;
    float: string;
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.UPDATE_PROJECT_FLOAT)(
      {
        ...args,
      }
    );
  }

  public static async updateTargetFrontFactor(args: {
    projectId: string;
    tfFactor: string;
  }) {
    return await FirebaseUsage.functions(
      ProjectFunctions.UPDATE_TARGET_FRONT_FACTOR
    )({
      ...args,
    });
  }

  public static async updatePredStatuses(args: { projectId: string }) {
    return await FirebaseUsage.functions(ProjectFunctions.UPDATE_PRED_STATUSES)(
      {
        ...args,
      }
    );
  }

  public static async shareReportItemToAnotherProject(args: {
    projectId: string;
    reportId: string;
  }) {
    return await FirebaseUsage.functions(
      ProjectFunctions.SHARE_REPORT_ITEM_TO_ANOTHER_PROJECT
    )({
      ...args,
    });
  }

  public static async curvesCalculation(args: {
    racingLine: {
      date: number;
      cumEarlyTasks: number;
      cumLateTasks: number;
      racingLine: number;
    }[];
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.CURVES_CALCULATION)({
      ...args,
    });
  }

  public static async calculateRacingLine(args: {
    early: number[];
    late: number[];
    actual: number[];
    remain: number[];
    inProgress: number;
  }) {
    return await FirebaseUsage.functions(
      ProjectFunctions.CALCULATE_RACING_LINE
    )({
      ...args,
    });
  }

  public static async uploadVList(args: {
    projectId: string;
    vList: VListModel[];
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.UPLOAD_V_LIST)({
      ...args,
    });
  }

  public static async projectDeletion(args: {
    projectIds: string[];
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.PROJECT_DELETION)({
      ...args,
    });
  }

  public static async getGoogleCloudSignedURL(args: { fileName: string }) {
    return await FirebaseUsage.functions(
      ProjectFunctions.GET_GOOGLE_CLOUD_SIGNED_URL
    )({
      ...args,
    });
  }

  public static async cancelProjectUploading(args: {
    projectNames: string[];
    user: UserDocModel;
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.CANCEL_UPLOADING)({
      ...args,
    });
  }

  public static async cancelUploadStrategy(args: {
    keepingData: CancelUpdating;
    projectId: string;
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.CANCEL_UPDATE)({
      ...args,
    });
  }

  public static async transformXerToJson(body: any) {
    return await FirebaseUsage.functions(
      ProjectFunctions.TRANSFORM_XER_TO_JSON
    )(body);
  }

  public static async setCPMMapToRAM(projectId: string) {
    return await FirebaseUsage.functions(ProjectFunctions.SET_CPM_MAP_TO_RAM)({
      projectId: projectId,
    });
  }

  public static async fullCPMCalc(
    projectId: string,
    initialRun: string,
    dispatch: any,
    cpmRefresh: boolean
  ) {
    return await CpmFunctionsController.calcRacingLine(
      { project_id: projectId, initial_run: initialRun, cpm_refresh: cpmRefresh },
      dispatch
    );
  }

  public static async taskCPM(
    taskId: string,
    projectId: string,
    eventText: string,
    eventTimestamp: string,
    userId: string | null,
    userEmail: string,
    expiryDate: string,
    suspendReason: string
  ) {
    return await FirebaseUsage.functions(ProjectFunctions.TASK_CPM)({
      task_id: taskId,
      project_id: projectId,
      event_type: eventText,
      seconds: eventTimestamp,
      user_id: userId,
      user_email: userEmail,
      expiry_date: expiryDate,
      suspend_reason: suspendReason,
    });
  }

  public static async xerToJson(
    content: string,
    user_id: string | null,
    projectId: string | null,
    isUpdate: boolean | undefined,
    fileName: string,
    fileBlob: any
  ) {
    return await XerToJsonController.xerToJson({
      content: content,
      userId: user_id,
      projectId: projectId,
      isUpdate: isUpdate,
      fileName: fileName,
      fileBlob: fileBlob,
    });
  }

  public static async transformXerToJsonFromURL(args: { fileName: string }) {
    return await FirebaseUsage.functions(
      ProjectFunctions.TRANSFORM_XER_TO_JSON_FROM_URL
    )(args);
  }

  public static async confirmUpload(
    projectId: string,
    isUpdate: boolean | null,
    projectName: string,
    oldProjectId: any,
    clientId: string
  ) {
    return await XerToJsonController.confirmUpload({
      project_id: projectId,
      is_update: isUpdate,
      project_name: projectName,
      old_project_id: oldProjectId,
      clientId,
    });
  }

  public static async assignBaselineToProject(
    projectId: string,
    content: any,
    baselineName: string,
    userId: string
  ) {
    return await FirebaseUsage.functions(
      ProjectFunctions.ASSIGN_BASELINE_TO_PROJECT
    )({
      projectId,
      content,
      baselineName,
      userId,
    });
  }

  public static async makeBaselineCurrent(baselineId: string) {
    return await FirebaseUsage.functions(
      ProjectFunctions.MAKE_BASELINE_CURRENT
    )({
      baselineId,
    });
  }

  public static async sendMails(args: {
    email: string;
    firstName: string;
    lastName: string;
    otherData?: {
      phoneNumber?: string;
      jobTitle?: string;
      company?: string;
    };
  }) {
    return await FirebaseUsage.functions(ProjectFunctions.SEND_MAILS)({
      ...args,
    });
  }

  public static async getClients() {
    return await FirebaseUsage.functions(ProjectFunctions.GET_CLIENTS)();
  }
}
