import React, { useCallback } from 'react';
import { Modal, Button, Header, Icon } from 'semantic-ui-react';
import ProjectModel from "../../../../models/responses/project.model";

interface Props {
    deleteProject: () => void;
    modalOpen: boolean;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    project: ProjectModel;
}

export const DeleteProjectModal: React.FC<Props> = ({ deleteProject, modalOpen, setModalOpen, project }) => {


    return (
        <Modal 
            // trigger={<Button onClick={deleteProject} color='green'>Delete Project</Button>}
            basic
            size='small'
            onClose={() => setModalOpen(false)}
            open={modalOpen}
        >
            <Header icon='eraser' content={`Delete ${project.name}`} />
            <Modal.Content>
                <p>
                    Are you sure you want to delete {project.name}? It will no longer be accessible to all users.
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setModalOpen(false)} basic color='red' inverted>
                    <Icon name='remove' /> No
                </Button>
                <Button onClick={deleteProject} color='green' inverted>
                    <Icon name='checkmark' /> Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
}