import {useEffect, useState} from "react";

export default function useCountdown(initialSeconds: number) {
    const [countdown, setCountdown] = useState(initialSeconds);

    useEffect(() => {
        if (countdown <= 0) {
            return;
        }
        const interval = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [countdown]);


    return { countdown };
}