import React, {useMemo} from 'react';
import { BarStack, Bar } from '@visx/shape';
// import { SeriesPoint } from '@visx/shape/lib/types';
import { Group } from '@visx/group';
import { Grid } from '@visx/grid';
import {AxisBottom, AxisLeft, AxisRight} from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { timeParse, timeFormat } from '@visx/vendor/d3-time-format';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
// import { LegendOrdinal } from '@visx/legend';
// import { localPoint } from '@visx/event';
import {
    useActiveDataVersionSelector, useActiveProjectCalendarsSelector, useActiveProjectSelector, useCpmMapSelector,
    useDataVersionsSelector,
    useSprintDataSelector
} from "../../../../store/selectors/project.selectors";
import moment from "moment";
import {SprintCategory, SprintDataModel} from "../../../../models/sprint.model";
import TaskModel from "../../../../models/responses/task.model";
import filterTaskList from "../../../../utils/task-filtering";
import {TaskType} from "../../../../models/task-type";
import * as projectActions from "../../../../store/actions/project.actions";
import {useIncludeMilestonesSelector, useSearchParamatersSelector} from "../../../../store/selectors/search.selectors";
import {useAllTaskListSelector} from "../../../../store/selectors/task/task.selectors";
import {useDispatch} from "react-redux";


export type BarStackProps = {
    width: number;
    height: number;
    margin?: { top: number; right: number; bottom: number; left: number };
    events?: boolean;
};

const parseDate = timeParse('%Y-%m-%d');
const format = timeFormat('%b %d');
const formatDate = (date: string) => format(parseDate(date) as Date);

let tooltipTimeout: number;

export default function SprintChart({
                                    width,
                                    height,
                                    events = false,
                                }: BarStackProps) {
    const { tooltipOpen, tooltipLeft, tooltipTop, hideTooltip, showTooltip } =
        useTooltip();

    const sprintData: SprintDataModel[] = useSprintDataSelector();
    const dataVersions = useDataVersionsSelector();
    const searchParams = useSearchParamatersSelector();
    const activeDataVersion = useActiveDataVersionSelector();
    const cpmMap = useCpmMapSelector();
    const projectCalendars = useActiveProjectCalendarsSelector();
    const allTasks = useAllTaskListSelector();
    const includeMilestones = useIncludeMilestonesSelector();
    const dispatch = useDispatch();
    const [hoveredBar, setHoveredBar] = React.useState<any>(null);
    const [toolTipData, setToolTipData] = React.useState<any>(null);
    const margin = { top: 30, right: 20, bottom: 0, left: 40 };
    const tooltipStyles = {
        ...defaultStyles,
        minWidth: 60,
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: 'white',
    };

    const sprintDataMap: any = useMemo(() => {
        let sprintDataMap = new Map<string, SprintDataModel>();
        if (sprintData.length > 0) {
            sprintData.forEach((sprint: SprintDataModel) => {
                let tasksMap = new Map<string, SprintCategory>();
                sprint.sprintTasks.forEach((task) => {
                    tasksMap.set(task.taskCode, task.type);
                });
                sprintDataMap.set(sprint.dataVersionId, {
                    ...sprint, sprintTasks: tasksMap
                });
            });
        }
        return sprintDataMap;
    }, [sprintData]);

    const convertSprintKeyToString = (key: string) => {
        switch (key) {
            case 'unplannedStartedAhead':
                return 'Non-sprint tasks started ahead';
            case 'sprintStartedAhead':
                return 'Sprint tasks started ahead';
            case 'unplannedStarted':
                return 'Non-sprint tasks started';
            case 'actualStart':
                return 'Sprint tasks started';
            case 'unplannedFinishedAhead':
                return 'Non-sprint tasks finished ahead';
            case 'sprintFinishedAhead':
                return 'Sprint tasks finished ahead';
            case 'unplannedFinished':
                return 'Non-sprint tasks finished';
            case 'actualFinish':
                return 'Sprint tasks finished';
            case 'incompleteStart':
                return 'Sprint start target';
            case 'incompleteFinish':
                return 'Sprint finish target';
        }
    }

    const convertKeyToColour = (key: string) => {
        switch (key) {
            case 'unplannedStartedAhead':
                return '#D7D7D7FF';
            case 'sprintStartedAhead':
                return '#D7D7D7FF';
            case 'unplannedStarted':
                return '#BFF5DAFF';
            case 'actualStart':
                return '#0F9D58FF';
            case 'unplannedFinishedAhead':
                return '#D7D7D7FF';
            case 'sprintFinishedAhead':
                return '#D7D7D7FF';
            case 'unplannedFinished':
                return '#C1D3EFFF';
            case 'actualFinish':
                return '#4285F4FF';
            case 'incompleteStart':
                return '#D7D7D7FF';
            case 'incompleteFinish':
                return '#D7D7D7FF';
        }
    }

    const isSprint = (key: string) => {
        switch (key) {
            case 'unplannedStartedAhead':
                return false;
            case 'sprintStartedAhead':
                return true;
            case 'unplannedStarted':
                return false;
            case 'actualStart':
                return true;
            case 'unplannedFinishedAhead':
                return false;
            case 'sprintFinishedAhead':
                return true;
            case 'unplannedFinished':
                return false;
            case 'actualFinish':
                return true;
            case 'incompleteStart':
                return true;
            case 'incompleteFinish':
                return true;
        }
    }

    const toolTipNumber = (key: string, data: any) => {
        switch (key) {
            case 'unplannedStartedAhead':
                return data.unplannedStartedAhead
            case 'sprintStartedAhead':
                return `${data.sprintStartedAhead} / ${data.dueToStart}`
            case 'unplannedStarted':
                return data.unplannedStarted
            case 'actualStart':
                return `${data.actualStart} / ${data.dueToStart}`
            case 'unplannedFinishedAhead':
                return data.unplannedFinishedAhead
            case 'sprintFinishedAhead':
                return `${data.sprintFinishedAhead} / ${data.dueToFinish}`
            case 'unplannedFinished':
                return data.unplannedFinished
            case 'actualFinish':
                return `${data.actualFinish} / ${data.dueToFinish}`
            case 'incompleteStart':
                return `(${data.dueToStart - data.actualStart - data.sprintStartedAhead} remaining)`
            case 'incompleteFinish':
                return `(${data.dueToFinish - data.actualFinish - data.sprintFinishedAhead} remaining)`
            default:
                return data[key]
        }
    }

    const activeProject = useActiveProjectSelector();

    const sprintChartData = useMemo(() => {
        let sprintChartData: any = [];
        if (sprintDataMap.size > 0 && dataVersions.length > 0) {
            dataVersions.forEach((activeDataVersion) => {
                const periodStart = new Date((Math.floor(activeDataVersion.dataVersionDate.seconds / 86400) * 86400) * 1000);
                const cutOffDate = new Date(periodStart.getTime() + (7 * 86400000));
                let tasksStartCount = 0;
                let tasksFinishCount = 0;
                let startedTasksCount = 0;
                let finishedTasksCount = 0;
                let sprintFinishedAhead = 0;
                let unplannedFinished = 0;
                let unplannedStarted = 0;
                let unplannedFinishedAhead = 0;
                let sprintStartedAhead = 0;
                let unplannedStartedAhead = 0;

                const allTasksList = filterTaskList(allTasks, searchParams, cpmMap, projectCalendars)
                const activeSprint = sprintDataMap.get(activeDataVersion.dataVersionId);

                allTasksList
                    .filter((task: TaskModel) => {
                            const taskInSprint = activeProject?.snapshotDataVersionId !== activeDataVersion.dataVersionId || activeProject?.sprintLocked ?
                                activeSprint ? activeSprint.sprintTasks.has(task.task_code) : false : !!cpmMap.get(task.task_id).sprintCategory
                            if (!taskInSprint
                                && !(task.act_end_date && task.act_end_date.toDate().getTime() >= periodStart.getTime() && task.act_end_date.toDate().getTime() < cutOffDate.getTime())
                                && !(task.act_start_date && task.act_start_date.toDate().getTime() >= periodStart.getTime() && task.act_start_date.toDate().getTime() < cutOffDate.getTime())
                                && !(task.declaredCompleteTimestamp && task.declaredCompleteTimestamp.toDate().getTime() >= periodStart.getTime() && task.declaredCompleteTimestamp.toDate().getTime() < cutOffDate.getTime())
                                && !(task.finishSprintId && task.finishSprintId === activeDataVersion.dataVersionId)
                                && !(task.startSprintId && task.startSprintId === activeDataVersion.dataVersionId)
                            ) return false;
                            return !(!includeMilestones && task.task_type !== TaskType.TT_RSRC && task.task_type !== TaskType.TT_TASK);
                        }
                    )
                    .forEach((task: TaskModel) => {
                        const taskInSprint = activeProject?.snapshotDataVersionId !== activeDataVersion.dataVersionId || activeProject?.sprintLocked ?
                            activeSprint ? activeSprint.sprintTasks.has(task.task_code) : false : !!cpmMap.get(task.task_id).sprintCategory
                        const taskActualEndDate = task.act_end_date ? task.act_end_date : task.declaredCompleteTimestamp ? task.declaredCompleteTimestamp : null;
                        const taskActualStartDate = task.act_start_date
                        const sprintCategory = activeProject?.snapshotDataVersionId !== activeDataVersion.dataVersionId || activeProject?.sprintLocked ?
                            activeSprint?.sprintTasks.get(task.task_code) : cpmMap.get(task.task_id).sprintCategory;

                        if (taskInSprint) {
                            if (sprintCategory === SprintCategory.START || sprintCategory === SprintCategory.START_AND_FINISH) {
                                tasksStartCount++;
                                if (taskActualStartDate && taskActualStartDate.toDate().getTime() >= periodStart.getTime() &&
                                    taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                                    startedTasksCount++;
                                } else if (taskActualStartDate && taskActualStartDate.toDate().getTime() < periodStart.getTime()) {
                                    sprintStartedAhead++;
                                }
                                if (sprintCategory !== SprintCategory.START_AND_FINISH && taskActualEndDate &&
                                    taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                    taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                    unplannedFinished++;
                                }
                            }
                            if (sprintCategory === SprintCategory.FINISH || sprintCategory === SprintCategory.START_AND_FINISH) {
                                tasksFinishCount++;
                                if (taskActualEndDate && taskActualEndDate.toDate().getTime() >= periodStart.getTime() &&
                                    taskActualEndDate.toDate().getTime() < cutOffDate.getTime()) {
                                    finishedTasksCount++;
                                } else if (taskActualEndDate && taskActualEndDate.toDate().getTime() < periodStart.getTime()) {
                                    sprintFinishedAhead++;
                                } else {
                                }
                            }
                        } else {
                            if (taskActualStartDate && taskActualStartDate.toDate().getTime() < periodStart.getTime()
                                && task.startSprintId === activeDataVersion.dataVersionId) {
                                unplannedStartedAhead++;
                            } else if (taskActualStartDate && taskActualStartDate.toDate().getTime() >= periodStart.getTime() &&
                                taskActualStartDate.toDate().getTime() < cutOffDate.getTime()) {
                                unplannedStarted++;
                            }
                            if (taskActualEndDate &&
                                taskActualEndDate.toDate().getTime() < cutOffDate.getTime() &&
                                taskActualEndDate.toDate().getTime() >= periodStart.getTime()) {
                                unplannedFinished++;
                            }
                            if (taskActualEndDate && taskActualEndDate.toDate().getTime() < periodStart.getTime()) {
                                unplannedFinishedAhead++;
                            }
                        }
                    })

                sprintChartData.push({
                    date: periodStart,
                    dataVersionId: activeDataVersion.dataVersionId,
                    dueToStart: tasksStartCount,
                    dueToFinish: tasksFinishCount,
                    actualStart: startedTasksCount,
                    actualFinish: finishedTasksCount,
                    sprintStartedAhead,
                    sprintFinishedAhead,
                    unplannedStartedAhead,
                    unplannedFinishedAhead,
                    unplannedStarted,
                    unplannedFinished,
                    incompleteStart: tasksStartCount - startedTasksCount - sprintStartedAhead,
                    incompleteFinish: tasksFinishCount - finishedTasksCount - sprintFinishedAhead
                });
            })}
        return sprintChartData.sort((a: any, b: any) => a.date - b.date);
        }, [searchParams, sprintDataMap, allTasks]);

    const data  = sprintChartData.slice(sprintChartData.length -12, sprintChartData.length);

    const getDate = (d: any) => moment(d.date).format('YYYY-MM-DD');

    const dateScale = scaleBand<string>({
        domain: data.map(getDate),
        padding: 0,
    })

    const sprintDataScale = scaleLinear<number>({
        domain: [0, Math.max(...data.map(d => Math.max(d.dueToStart, d.actualStart + d.unplannedStarted, d.dueToFinish, d.actualFinish + d.unplannedFinished)) || 0)],
        nice: true
    })

    const colourScaleStart = scaleOrdinal<string>({
        domain: ['sprintStartedAhead', 'actualStart', 'incompleteStart', 'unplannedStarted', 'unplannedStartedAhead'],
        range: ['#D7D7D7FF','#0F9D58FF', 'rgb(241, 240, 240, 0.6)', '#BFF5DAFF', '#D7D7D7FF'],
    })

    const colourScaleFinish = scaleOrdinal<string>({
        domain: ['sprintFinishedAhead', 'actualFinish', 'incompleteFinish', 'unplannedFinished', 'unplannedFinishedAhead'],
        range: ['#D7D7D7FF', '#4285F4FF', 'rgb(241, 240, 240, 0.6)', '#C1D3EFFF', '#D7D7D7FF'],
    })

    // const keysStart = ['sprintStartedAhead', 'actualStart', 'incompleteStart' , 'unplannedStarted', 'unplannedStartedAhead'];
    const keysStart = ['actualStart', 'unplannedStarted'];


    // const keysFinish = ['sprintFinishedAhead', 'actualFinish', 'incompleteFinish', 'unplannedFinished', 'unplannedFinishedAhead'];
    const keysFinish = ['actualFinish', 'unplannedFinished'];


    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        scroll: true,
    });

    function getHeightOfOverallStartBar (index: number) {
        return data[index].sprintStartedAhead + data[index].unplannedStartedAhead + data[index].unplannedStarted + data[index].dueToStart;
    }

    function getHeightOfOverallFinishBar (index: number) {
        return data[index].sprintFinishedAhead + data[index].unplannedFinishedAhead + data[index].unplannedFinished + data[index].dueToFinish;
    }

    const handleTooltipHide = () => {
        hideTooltip();
    }

    const handleTooltip = (event, data, type, key) => {
        // if (tooltipTimeout) clearTimeout(tooltipTimeout);
        const clientY = event.clientY;
        const left = event.clientX;
        showTooltip({
            tooltipTop: clientY,
            tooltipLeft: left,
        });
        setToolTipData({...data, type, key});
    };

    if (width < 10) return null;
    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - 50;

    const gap = 5;

    dateScale.rangeRound([0, xMax]);
    sprintDataScale.range([yMax, 0]);

    function handleChangeOfActiveDataVersion (dataVersionId: string, i: number) {
        let indexOfDV = 0
        const dataVersion = dataVersions.find((dataVersion, index) => {
            if (dataVersion.dataVersionId === dataVersionId) {
                indexOfDV = index;
                return true;
            }
        });
        if (dataVersion) dispatch(projectActions.Actions.setActiveDataVersion({version: dataVersion, index: indexOfDV}));
    }

    const chart = width < 10 ? null : (
        <div style={{ position: 'relative'}}>
            <svg ref={containerRef} width={width} height={height}>
                <rect x={0} y={0} width={width} height={height} fill={'white'} rx={14} />
                <Grid
                    top={margin.top}
                    left={margin.left}
                    xScale={dateScale}
                    yScale={sprintDataScale}
                    width={xMax}
                    height={yMax + 30}
                    stroke="black"
                    strokeOpacity={0.1}
                    xOffset={dateScale.bandwidth() / 2}
                />
                <rect
                    x={dateScale(getDate({date: activeDataVersion.version.dataVersionDate.toDate()}))! + margin.left}
                    y={30}
                    width={dateScale.bandwidth()}
                    height={yMax + 35}
                    fill={'#D9001BFF'}
                    stroke={'#D9001BFF'}
                    strokeWidth={2}
                    // rx={5}
                    fillOpacity={0.2}
                />
                <Group top={margin.top}>
                    <BarStack
                        data={data}
                        keys={keysStart}
                        x={getDate}
                        xScale={dateScale}
                        yScale={sprintDataScale}
                        // @ts-ignore
                        color={colourScaleStart}
                    >
                        {(barStacks) =>
                            barStacks.map((barStack, i) =>
                                barStack.bars.map((bar) => (
                                    <rect
                                        key={`bar-stack-${barStack.index}-${bar.index}`}
                                        x={bar.x + gap + margin.left}
                                        y={bar.y}
                                        cursor={'pointer'}
                                        height={bar.height}
                                        width={(bar.width / 2) - gap}
                                        fill={bar.color}
                                        stroke={hoveredBar === `bar-stack-${barStack.index}-${bar.index}` ? 'black' : 'none'}
                                        //@ts-ignore
                                        strokeWidth={1.5}
                                        onClick={() => handleChangeOfActiveDataVersion(data[bar.index].dataVersionId, bar.index)}
                                        onMouseLeave={() => {
                                            handleTooltipHide();
                                            setHoveredBar(null);
                                        }}
                                        onMouseEnter={(event) => {
                                            setHoveredBar(`bar-stack-${barStack.index}-${bar.index}`)
                                            handleTooltip(event, data[bar.index], 'start', bar.key);
                                        }}
                                    />
                                ))
                            )
                        }
                    </BarStack>
                    <BarStack
                        data={data}
                        keys={keysFinish}
                        x={getDate}
                        xScale={dateScale}
                        yScale={sprintDataScale}
                        // @ts-ignore
                        color={colourScaleFinish}
                    >
                        {(barStacks) =>
                            barStacks.map((barStack, i) =>
                                barStack.bars.map((bar) => (
                                    <rect
                                        key={`bar-stack-${barStack.index}-${bar.index}-finish`}
                                        x={bar.x + bar.width / 2 + 2 + margin.left}
                                        y={bar.y}
                                        height={bar.height}
                                        width={(bar.width / 2) - gap - 2}
                                        fill={bar.color}
                                        cursor={'pointer'}
                                        stroke={hoveredBar === `bar-stack-${barStack.index}-${bar.index}-finish` ? 'black' : 'none'}
                                        //@ts-ignore
                                        strokeWidth={1.5}
                                        onClick={() => handleChangeOfActiveDataVersion(data[bar.index].dataVersionId, bar.index)}
                                        onMouseLeave={() => {
                                            handleTooltipHide();
                                            setHoveredBar(null);
                                        }}
                                        onMouseEnter={(event) => {
                                            setHoveredBar(`bar-stack-${barStack.index}-${bar.index}-finish`)
                                            handleTooltip(event, data[bar.index], 'finish', bar.key);
                                        }}
                                    />
                                ))
                            )
                        }
                    </BarStack>
                    {data.map((d, i) => (
                        <rect
                            key={`outline-bar-${i}`}
                            // @ts-ignore
                            x={dateScale(getDate(d)) + gap + margin.left}
                            y={sprintDataScale(d.dueToStart)}
                            width={(dateScale.bandwidth() / 2) - gap - 1.5}
                            height={yMax - sprintDataScale(d.dueToStart)}
                            fill={'none'}
                            stroke={'#0F9D58FF'}
                            // strokeDasharray={'2'}
                            strokeWidth={hoveredBar === `outline-bar-${i}` ? 4 : 2}
                            onClick={() => handleChangeOfActiveDataVersion(data[i].dataVersionId, i)}
                            onMouseEnter={(e) => {
                                setHoveredBar(`outline-bar-${i}`)
                                handleTooltip(e, data[i], 'start', 'incompleteStart');
                            }}
                            onMouseLeave={() => {
                                setHoveredBar(null)
                                handleTooltipHide();
                            }}
                            cursor={'pointer'}
                        />
                    ))}
                    {data.map((d, i) => (
                        <rect
                            key={`outline-bar-${i}-finish`}
                            // @ts-ignore
                            x={dateScale(getDate(d)) + dateScale.bandwidth() / 2 + 1.5 + margin.left}
                            y={sprintDataScale(d.dueToFinish)}
                            width={(dateScale.bandwidth() / 2) - gap}
                            height={yMax - sprintDataScale(d.dueToFinish)}
                            fill={'none'}
                            stroke={'#4285F4FF'}
                            // strokeDasharray={'2'}
                            strokeWidth={hoveredBar === `outline-bar-${i}-finish` ? 4 : 2}
                            onClick={() => handleChangeOfActiveDataVersion(data[i].dataVersionId, i)}
                            onMouseEnter={(e) => {
                                setHoveredBar(`outline-bar-${i}-finish`)
                                handleTooltip(e, data[i], 'finish', 'incompleteFinish');
                            }}
                            onMouseLeave={() => {
                                setHoveredBar(null)
                                handleTooltipHide();
                            }}
                            cursor={'pointer'}
                        />
                    ))}
                </Group>
                {/*<AxisRight scale={sprintDataScale} top={margin.top} />*/}
                <AxisBottom
                    top={yMax + margin.top}
                    left={margin.left}
                    scale={dateScale}
                    labelClassName={'sprint-chart-axis-label'}
                    tickFormat={formatDate}
                    tickLength={0}
                    stroke={'#7F7F7FFF'}
                    tickStroke={'#7F7F7FFF'}
                    tickLabelProps={{
                        fill: '#7F7F7FFF',
                        fontSize: 12,
                        textAnchor: 'middle',
                        // cursor: 'pointer',
                        dy: '0.8em',
                    }}
                />
                <AxisLeft
                    scale={sprintDataScale}
                    top={margin.top}
                    left={margin.left}
                    numTicks={10}
                    stroke={'#7F7F7FFF'}
                    tickStroke={'#7F7F7FFF'}
                />
            </svg>
            <div
                style={{
                    position: 'absolute',
                    top: margin.top / 2 - 10,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '14px',
                }}
            >
                {/*<LegendOrdinal scale={colourScaleStart} direction="row" labelMargin="0 15px 0 0" />*/}
            </div>

            {tooltipOpen && toolTipData && (
                <TooltipInPortal top={tooltipTop! - 30} left={tooltipLeft} style={tooltipStyles}>
                    <strong>Sprint: {moment(toolTipData.date).format('MMM DD')}</strong>
                    <div
                        style={{color: convertKeyToColour(toolTipData.key), marginTop: "5px"}}
                    >{convertSprintKeyToString(toolTipData.key)}</div>
                    {toolTipData.key === 'incompleteStart' || toolTipData.key === 'incompleteFinish' ?
                        <div
                        style={{color: convertKeyToColour(toolTipData.key), marginTop: "5px"}}
                        >
                            <strong>{toolTipData.key === 'incompleteStart' ? toolTipData.dueToStart : toolTipData.dueToFinish }</strong>
                        </div> : null}
                    {toolTipData.key === 'incompleteStart' || toolTipData.key === 'incompleteFinish' ? <div
                        style={{color: convertKeyToColour(toolTipData.key), marginTop: "5px"}}
                    >{toolTipNumber(toolTipData.key, toolTipData)}
                    </div> :<div
                        style={{color: convertKeyToColour(toolTipData.key), marginTop: "5px"}}
                    ><strong>{toolTipNumber(toolTipData.key, toolTipData)}</strong>
                    </div>}
                </TooltipInPortal>
            )}
        </div>
    );

    return (
        <div className="ContainerExpended">
            <div className={"Content"}>
                {chart}
            </div>
        </div>
    );
}