import React, {useEffect } from "react";
import {
    useActiveProjectSelector,
    useCpmCompleteSelector,
    useProjectViewSelector, useViewWidthSelector
} from "../../../../store/selectors/project.selectors";
import {useAllTaskListSelector, useTaskListLoadCountSelector} from "../../../../store/selectors/task/task.selectors";
import {Loader} from "semantic-ui-react";
import {TaskList} from "./components/TaskList";
import FirebaseProjectFunctions from "../../../../firebase/firebase-functions/project-service";
import { useDispatch } from 'react-redux';
import * as projectActions from "../../../../store/actions/project.actions";
import * as taskActions from "../../../../store/actions/task.actions";
import FirebaseUsage from "../../../../firebase/firebase.usage";
import {useUserSelector} from "../../../../store/selectors/authorization.selectors";
import {useProjectMemberListSelector} from "../../../../store/selectors/team.selectors";
import {COLLECTIONS} from "../../../../firebase/constants";
import QualityPage from "../QualityPage/QualityPage";
import GanttChart from "../../components/GanttChart/GanttChart";
import {matchPath} from "react-router-dom";
import {SearchActions} from "../../../../store/actions/search.actions";
import {SprintTaskList} from "./components/SprintTaskList";
import ChangeAnalysisPage from "../ChangeAnalysisPage/ChangeAnalysisPage";
import {subsManager} from "../../../../store/middleware/subs-manager/subs-manager";

const FlowPage: React.FC = () => {
    const activeProject = useActiveProjectSelector();
    const [oldActiveProject, setOldActiveProject]: any = React.useState(null);
    const cpmFinished = useCpmCompleteSelector()
    const dispatch = useDispatch();
    // const taskListLoadCount = useTaskListLoadCountSelector();
    const user = useUserSelector()
    const projectMembersList = useProjectMemberListSelector();
    const projectView = useProjectViewSelector();
    const allTasks = useAllTaskListSelector();
    const [pageLoaded, setPageLoaded] = React.useState<any>(0);

    useEffect(() => {
        if (allTasks.length > 0 && activeProject && activeProject.projectId !== oldActiveProject) {
            let cpmRefresh = true;
            // const beginningOfToday = new Date().setHours(0, 0, 0, 0) / 1000;
            // if (activeProject.lastUpdate && activeProject.lastUpdate.seconds > beginningOfToday) {
            //     cpmRefresh = false;
            // }
            console.log("running CPM on load")
            FirebaseProjectFunctions.fullCPMCalc(activeProject.projectId, "false", dispatch, cpmRefresh)
                .then(() => {
                    console.log("cpm run on load - successful")
                    dispatch(projectActions.Actions.setCpmComplete(true))
                    if (user && projectMembersList) {
                        const member = projectMembersList.find((member) => member.userId === user.userId)
                        const memberId = member!.memberId
                        FirebaseUsage.updateDoc(COLLECTIONS.PROJECT_MEMBERS, memberId, {
                            lastViewed: FirebaseUsage.timestamp(),
                            totalViews: member!.totalViews ? member!.totalViews + 1 : 1,
                            live: true
                        }).then(() => subsManager.subscribeProjectPermission(user!.userId))
                            .catch((err) => console.log(err))
                    }
                    const matchPathname = matchPath( {path: '/:projectId/flow/:task_id'}, window.location.pathname);
                    const params: any = matchPathname
                        ? matchPathname.params
                        : {};
                    const taskId = params.task_id;
                    if (taskId) {
                        const task = allTasks.find(task => task.task_id === taskId);
                        const taskByCode = allTasks.find(task => task.task_code === taskId);
                        if (task) {
                            dispatch(taskActions.Actions.setActiveTask(task));
                            dispatch(SearchActions.addChip([{ type: "filter", text: task.task_code }]));
                            dispatch(projectActions.Actions.setLedgerOpen(true));
                        }
                        else if (taskByCode) {
                            dispatch(taskActions.Actions.setActiveTask(taskByCode));
                            dispatch(SearchActions.addChip([{ type: "filter", text: taskByCode.task_code }]));
                            dispatch(projectActions.Actions.setLedgerOpen(true));
                        }
                    }
                })
                .catch((err) => console.log("cpm run on load unsuccessful - ", err))
            setOldActiveProject(activeProject.projectId);
        }
    }, [allTasks]);

    useEffect(() => {
        const flowPageDocument = document.getElementById('flow-page-id');
        if (flowPageDocument) {
            // add event to trigger if width of document changes
            dispatch(projectActions.Actions.setViewWidth(flowPageDocument.clientWidth));
        } else {
            setTimeout(() => setPageLoaded(prevState => prevState + 1), 0)
        }
    }, [pageLoaded]);


    return cpmFinished ? (
        <div className="flow-page-container" id="flow-page-id">
            {projectView === "task" ? <TaskList/> :
                projectView === "quality" ? <QualityPage/> :
                    projectView === "gantt" ? <GanttChart/> :
                        projectView === "sprint" ? <SprintTaskList/> :
                            projectView === "change" ? <ChangeAnalysisPage/> :
                        <TaskList/>
            }
        </div>
    ) : <Loader active content={"Project data loading..."} size={"large"}/>
};

export default FlowPage;
