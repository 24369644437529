import React, {useEffect} from "react";
import { useTypedSelector } from "../../../../store/selectors/selectors.utils";
import rocketIcon from '../.././../../images/rocketIcon.svg';
import store from "../../../../store/store";
import generateCurves from "../../../../utils/generate-curves";
import {useSearchParamatersSelector} from "../../../../store/selectors/search.selectors";
import filterTaskList from "../../../../utils/task-filtering";
import {useActiveProjectCalendarsSelector, useCpmMapSelector} from "../../../../store/selectors/project.selectors";
import FlowChartNew from "./components/FlowChartNew";


export function FlowChart () {
    const [data, setData] = React.useState<any>(null);
    const searchParams = useSearchParamatersSelector();
    const cpmMap = useCpmMapSelector();
    const calendarsMap = useActiveProjectCalendarsSelector();
    const activeProject = useTypedSelector(state => state.project.activeProject);
    const customViewStart = activeProject?.customViewStart;
    const customViewFinish = activeProject?.customViewFinish;

    useEffect(() => {
        if (activeProject) {
            const taskList = store.getState().task.tasks
            const filteredTaskList = filterTaskList(taskList, searchParams, cpmMap, calendarsMap);
            generateCurves(filteredTaskList, activeProject.projectId, cpmMap, calendarsMap).then(res => {
                let resOut = res;
                if (customViewStart) {
                    if (customViewFinish) {
                        resOut = {
                            early: res.early.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                            late: res.late.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                            expected: res.expected.filter(el => el.date.seconds >= customViewStart.seconds && el.date.seconds <= customViewFinish.seconds),
                        }
                    } else {
                        resOut = {
                            early: res.early.filter(el => el.date.seconds >= customViewStart.seconds),
                            late: res.late.filter(el => el.date.seconds >= customViewStart.seconds),
                            expected: res.expected.filter(el => el.date.seconds >= customViewStart.seconds),
                        }
                    }
                } else if (customViewFinish) {
                    resOut = {
                        early: res.early.filter(el => el.date.seconds <= customViewFinish.seconds),
                        late: res.late.filter(el => el.date.seconds <= customViewFinish.seconds),
                        expected: res.expected.filter(el => el.date.seconds <= customViewFinish.seconds),
                    }
                }
                setData(resOut)
            })
        }
    }, [activeProject, searchParams]);

    // const rocketImage = useMemo(() => {
    //     const outImage = new Image(25, 25);
    //         outImage.src = rocketIcon;
    //         outImage.className = 'rocket-icon-chart';
    //         return outImage;
    // }, []);

    // rocketImage.src = `${faRocket.icon[4]}`;
    // const rocketIcon = new Icon(faRocket.icon);


    return (
        <div className="ContainerExpended">
            <div className={"Content"}>
                {/*
                // @ts-ignore */}
                {/*<Line height={90} data={data} options={options} />*/}
                {data && <FlowChartNew width={window.innerWidth} height={250} data={data}/>}
            </div>
        </div>
    )
}
