export enum COLLECTIONS {
  PROJECTS = "projects",
  CALENDAR = "calendar",
  TASKS = "tasks",
  RELATIONSHIPS = "relationships",
  LEDGER_ENTRY = "ledger_entry",
  PROJECT_MEMBERS = "project_members",
  WORK_BREAKDOWN_STRUCTURE = "wbs",
  USERS = "users",
  REPORTS = "reports",
  EVENT_LOG = "event_log",
  IMAGES = "project_images",
  GEO_DATA = "geo_data",
  TRACKED_MILESTONES = "tracked_milestones",
  CLIENTS = "clients",
  BASELINES = "baselines",
  BASELINE_TASKS = "baseline_tasks",
  PROJECT_FILES = "project_files",
  PROJECT_STORAGE = "project_storage",
  PENDING_MEMBERSHIPS = "pending_memberships",
  GLOBAL_GROUPS = "global_groups",
  ISSUE_LOGS = "issue_logs",
  TASK_SNAPSHOTS = "task_snapshots",
  DATA_VERSIONS = "data_versions",
  SPRINT_DATA = "sprint_data",
  PROJECT_UPLOADS = "project_uploads",
}

export enum SUB_COLLECTIONS {
  CURVES = "curves",
  CALENDARS = "calendars",
}

export const FIREBASE_WRITES_LIMIT = 200;
