import React, {useEffect} from 'react';
import styles from './TaskContainer.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFastForward } from "@fortawesome/pro-light-svg-icons";
import EventTextModel from "../../../../../../models/event-text-model.enum";

export default function ProgressSlider(props: any) {

    const {
        sliderValue,
        setSliderValue,
        setProgressChanged,
        progressChanged,
        task,
        currentUser,
        setShowProgressModal,
        setShowEventModal,
        setModalMessage
    } = props;
    const areaListener = new AbortController();

    function addListenerToSlider() {
        window.addEventListener('mousemove', (e) => {
            const slider = document.getElementById('slider-container');
            if (slider) {
                const sliderRect = slider.getBoundingClientRect();
                const sliderWidth = sliderRect.width;
                const sliderX = sliderRect.x;
                const mouseX = e.clientX
                const mouseXRelative = mouseX - sliderX
                const sliderValue = Math.round((mouseXRelative / sliderWidth) * 100)
                const finalSliderValue = sliderValue > 100 ? 100 : sliderValue < 0 ? 0 : sliderValue
                setProgressChanged(true)
                setSliderValue(finalSliderValue)
            }
        }, {signal: areaListener.signal})
        window.addEventListener('mouseup', () => {
            window.removeEventListener('mousemove', () => {})
            window.removeEventListener('mouseup', () => {})
                if (sliderValue < 100) {
                    setShowProgressModal(true)
                } else if (sliderValue === 100) {
                    setModalMessage(EventTextModel.COMPLETE_TASK)
                    setShowEventModal(true);
                }
            areaListener.abort()
        }, {signal: areaListener.signal})
    }

    function selectStyleFromPredStatus() {
        switch (task.predStatus) {
            case 1:
                return styles.SliderProgressGreen
            case 2:
                return styles.SliderProgressYellow
            case 3:
                return styles.SliderProgressRed
            default:
                return styles.SliderProgressGreen
        }
    }

    function selectButtonStyleFromPredStatus() {
        switch (task.predStatus) {
            case 1:
                return styles.SliderButtonGreen
            case 2:
                return styles.SliderButtonYellow
            case 3:
                return styles.SliderButtonRed
            default:
                return styles.SliderButtonGreen
        }
    }

    return (
        <div className={styles.SliderContainer} id="slider-container">
            <div className={selectStyleFromPredStatus()} style={{width: `${sliderValue}%`}}>
            </div>
            {task.taskForce.includes(currentUser.userId)  &&
                <button className={selectButtonStyleFromPredStatus()} onMouseDown={() => addListenerToSlider()}>
                    <FontAwesomeIcon icon={faFastForward}/>
                </button>}
        </div>
    )
}