import {SafeMap} from "../../../utils/safe-map";
import ProjectModel from "../../../models/responses/project.model";
import store from "../../store";
import * as projectsActions from "../../actions/project.actions";
import * as teamActions from "../../actions/team.actions";
import * as tasksActions from "../../actions/task.actions";
import * as wbsActions from "../../actions/wbs.actions";
import * as ledgerActions from "../../actions/ledger.actions";
import * as relationshipsActions from "../../actions/relationships.actions";
import * as authorizationActions from "../../actions/authorization.actions";
import * as searchActions from "../../actions/search.actions";
import * as eventLogActions from "../../actions/event-log.actions";
import {ProjectSubscriptions} from "../../../firebase/subscriptions/projectSubscriptions";
import {AvailableSubscriptions} from "./subs-manage.types";
import TaskModel, {TaskSnapshot} from "../../../models/responses/task.model";
import {TaskListSubscriptionParameters, TaskSubscriptions,} from "../../../firebase/subscriptions/taskSubscriptions";
import {WBSSubscriptions} from "../../../firebase/subscriptions/wbsSubscriptions";
import {WbsModel} from "../../../models/responses/wbs.model";
import {MemberSubscriptions} from "../../../firebase/subscriptions/memberSubscriptions";
import ProjectMembersModel from "../../../models/responses/project-members.model";
import MessageModel from "../../../models/responses/message.model";
import {LedgerSubscriptions} from "../../../firebase/subscriptions/ledgerSubscriptions";
import {ReportItem} from "../../store.types";
import {RelationshipsSubscriptions} from "../../../firebase/subscriptions/relationshipsSubscriptions";
import {RelationshipsModel} from "../../../models/responses/relationships.model";
import EventLog from "../../../models/responses/event-log-model";
import {EventLogSubscriptions} from "../../../firebase/subscriptions/eventLogSubscriptions";
import {Omit} from "redux-form";
import {CalendarModel} from "../../../models/responses/calendar.model";
import {CalendarSubscriptions} from "../../../firebase/subscriptions/calendarSubscriptions";
import {TaskListSectionModel} from "../../../models/task-list-section.model";
import TrackedMilestoneModel from "../../../models/responses/tracked-milestone.model";
import {PendingMembershipsModel} from "../../../models/pending-memberships.model";
import TaskStatusModel from "../../../models/responses/task-status.model";

class SubsManager {
  unsubscribe: SafeMap<AvailableSubscriptions, () => void> = new SafeMap();
  // use multiUnsubscribe in case you need multiple subscriptions of the same type
  // multiUnsubscribe = new SafeMap<AvailableSubscriptions, Array<() => void>>();

  private static handlerProjectList = (projectList: ProjectModel[]) => {
    const activeProject = store.getState().project.activeProject;
    store.dispatch(projectsActions.Actions.setProjectList(projectList.filter(el => {
        if (activeProject && activeProject.projectId === el.projectId) {
            store.dispatch(projectsActions.Actions.setActiveProject(el));
        }
        return !el.archived && !el.deleted;
    })));
    store.dispatch(projectsActions.Actions.setArchiveProjectList(projectList.filter(el => el.archived)));
  };
  private static handlerInProgressTaskList = (taskList: TaskModel[]) => {
      taskList = taskList.sort((a, b) => a.index! - b.index!)
    store.dispatch(tasksActions.Actions.setInProgressTaskList(taskList));
  };
  private static handlerCalendar = (calendar: CalendarModel | null) =>
    store.dispatch(tasksActions.Actions.setCalendar(calendar));
  private static handlerConfirmedCompleteTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setConfirmedCompleteTaskList(taskList));
  private static handlerDeclaredCompleteTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setDeclaredCompleteTaskList(taskList));
  private static handlerPendingTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setPendingTaskList(taskList));
  private static handlerAllTaskList = (taskList: TaskModel[]) =>
    store.dispatch(tasksActions.Actions.setAllTaskList(taskList));

  private static handlerTaskSnapshot = (taskSnapshot: Map <string, TaskSnapshot>) =>
      store.dispatch(tasksActions.Actions.setTaskSnapshot(taskSnapshot))

private static handlerSprintData = (sprintData: any) => {
    store.dispatch(projectsActions.Actions.setSprintData(sprintData.sort((a, b) => a.periodStart.seconds - b.periodStart.seconds)));
}

private static handlerNextSnapshot = (taskSnapshot: Map <string, TaskSnapshot>) =>
    store.dispatch(tasksActions.Actions.setNextSnapshot(taskSnapshot));

  private static handlerDataVersions = (dataVersions: any[]) => {
      const sortedVersions = dataVersions.sort((a, b) => b.dataVersionDate.seconds - a.dataVersionDate.seconds);
        store.dispatch(projectsActions.Actions.setDataVersions(sortedVersions));
        if (sortedVersions.length > 0) store.dispatch(projectsActions.Actions.setActiveDataVersion({version: sortedVersions[0], index: 0}));
  }

  private static handlerTrackedMilestones = (milestones: any[]) => {
      let outMilestones: TrackedMilestoneModel[] = milestones.filter((el) => {
          if (el.visibleToAll) {
              return true;
          } else if (el.userId === store.getState().authorization.user?.userId) {
              return true;
          }
          return false;
      });
      store.dispatch(projectsActions.Actions.setTrackedMilestones(outMilestones));
  }

  private static handlerGeoData = (geoData: any[]) => {
      store.dispatch(projectsActions.Actions.setGeoData(geoData));
  }

  private static handlerUploads = (uploads: any[]) => {
      store.dispatch(projectsActions.Actions.setProjectUploads(
          uploads.filter(el => el.status !== 'deleted')
      ));
  }

  private static handlerQueuedTaskList = (taskList: TaskModel[]) => {
      taskList = taskList.sort((a, b) => a.index! - b.index!)

    store.dispatch(tasksActions.Actions.setQueuedTaskList(taskList));
  };

  private static handlerPredecessorsList = (taskList: TaskModel[]) =>
    store.dispatch(
      tasksActions.Actions.setRelatedTaskList(taskList, "predecessors")
    );
  private static handlerSuccessorsList = (taskList: TaskModel[]) =>
    store.dispatch(
      tasksActions.Actions.setRelatedTaskList(taskList, "successors")
    );
  private static handlerWbsList = (wbsList: WbsModel[]) => {

      let outWbsList: WbsModel[] = []
      function addChildren(wbs: WbsModel) {
          if (!wbs) return
          let children = wbs.children
          if (children.length) {
              children = children.sort((a, b) => {
                    const wbsA = wbsMap.get(a)
                    const wbsB = wbsMap.get(b)
                    if (wbsA && wbsB) {
                        return wbsA.wbs_short_name.localeCompare(wbsB.wbs_short_name)
                    }
                    return 0
              })
              for (const child of children) {
                  const childWbs = wbsMap.get(child)
                  if (childWbs) {
                      outWbsList.push(childWbs)
                      addChildren(childWbs)
                  }
              }
          }
      }
      let wbsMap = new Map()
      wbsList = wbsList.sort((a, b) => {
          wbsMap.set(b.wbs_id, b)
          wbsMap.set(a.wbs_id, a)
          return a.level - b.level
      })
      for (const wbs of wbsList) {
          if (wbs.level !== 0) {
              break
          }
          outWbsList.push(wbs)
          let children = wbs.children
          // sort children by wbs_short_name alphabetically
          children = children.sort((a, b) => {
                const wbsA = wbsMap.get(a)
                const wbsB = wbsMap.get(b)
                if (wbsA && wbsB) {
                    return wbsA.wbs_short_name.localeCompare(wbsB.wbs_short_name)
                }
                return 0
          })
          if (wbs.children.length) {
              for (const child of children) {
                  const childWbs = wbsMap.get(child)
                  outWbsList.push(childWbs)
                  addChildren(childWbs)
              }
          }
      }

    store.dispatch(wbsActions.Actions.setWBSList(outWbsList))
  };
  private static handlerLedgerList = (ledgerList: MessageModel[]) =>
    store.dispatch(ledgerActions.Actions.setLedgerList(ledgerList));
  private static handlerRelationshipsList = (
    relationshipsList: RelationshipsModel[]
  ) =>
    store.dispatch(
      relationshipsActions.Actions.setRelationshipsList(relationshipsList)
    );
  private static handlerProjectPermissions = (members: ProjectMembersModel[]) =>
    store.dispatch(authorizationActions.Actions.setMemberPermissions(members));
  private static handlerMemberList = (memberList: ProjectMembersModel[]) =>
    store.dispatch(teamActions.Actions.setMemberList(memberList));
    private static handlerPendingMemberList = (memberList: PendingMembershipsModel[]) =>
    store.dispatch(teamActions.Actions.setPendingMemberList(memberList));
  private static handlerReportList = (reportList: ReportItem[]) =>
    store.dispatch(searchActions.SearchActions.setReportList(reportList));
  private static handlerEventLogList = (eventLogList: EventLog[]) =>
    store.dispatch(eventLogActions.Actions.setEventLogList(eventLogList));
    private static handlerProjectMember = (member: ProjectMembersModel) =>
        store.dispatch(teamActions.Actions.setProjectMember(member));
    private static handlerRecentLedgerList = (ledgerList: MessageModel[]) =>
        store.dispatch(ledgerActions.Actions.setRecentUserItems(ledgerList));

  private safeSubscribe(type: AvailableSubscriptions, unsubscribe) {
    this.unsubscribe.forEachKey(type, (u) => u);
    this.unsubscribe.set(type, unsubscribe);
  }

  async subscribeProjectList(userId: string | null) {
    this.safeSubscribe(
      "project-list",
      await ProjectSubscriptions.projectList(
        userId,
        SubsManager.handlerProjectList
      )
    );
    this.safeSubscribe(
        "project-uploads",
        await ProjectSubscriptions.projectUploads(
            userId!,
            SubsManager.handlerUploads
        )
    )
  }

  subscribeWbsList(project_id: string) {
    this.safeSubscribe(
      "wbs-list",
      WBSSubscriptions.wbsList(project_id, SubsManager.handlerWbsList)
    );
  }

  async subscribeInProgressTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
      if (params.alert) {
        this.safeSubscribe(
          "in-progress-task-list",
          await TaskSubscriptions.taskList(
            {
              ...params,
              // taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                status: TaskStatusModel.IN_PROGRESS
            },
            SubsManager.handlerInProgressTaskList
          )
        );
      } else {
        this.safeSubscribe(
            "in-progress-task-list",
            await TaskSubscriptions.taskList(
                {
                  ...params,
                  taskListType: TaskListSectionModel.WORK_IN_PROCESS,
                    status: TaskStatusModel.IN_PROGRESS
                },
                SubsManager.handlerInProgressTaskList
            )
        );
      }
  }

  subscribeCalendar(projectId: string, calendarId: string) {
    this.safeSubscribe(
      "calendar",
      CalendarSubscriptions.calendar(
        projectId,
        calendarId,
        SubsManager.handlerCalendar
      )
    );
  }

  async subscribeConfirmedCompleteTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
      this.safeSubscribe(
        "confirmed-complete-task-list",
        await TaskSubscriptions.taskList(
          {
            ...params,
            taskListType: TaskListSectionModel.CONFIRMED_COMPLETE,
              status: TaskStatusModel.COMPLETE
          },
          SubsManager.handlerConfirmedCompleteTaskList
        )
      );
  }

  async subscribeDeclaredCompleteTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
    this.safeSubscribe(
      "declared-complete-task-list",
      await TaskSubscriptions.taskList(
        {
          ...params,
          taskListType: TaskListSectionModel.DECLARED_COMPLETE,
            status: TaskStatusModel.DECLARED_COMPLETE
        },
        SubsManager.handlerDeclaredCompleteTaskList
      )
    );
  }

  async subscribeQueuedTaskList(
    params: Omit<TaskListSubscriptionParameters, "taskListType">
  ) {
    // console.log('params subscribeQueuedTaskList from sub-manger', params)
      this.safeSubscribe(
          "queued-task-list",
          await TaskSubscriptions.taskList(
              {
                ...params,
                taskListType: TaskListSectionModel.QUEUED,
                    status: TaskStatusModel.NOT_STARTED
              },
              SubsManager.handlerQueuedTaskList
          )
      );
  }

    async subscribePendingTaskList(
        params: Omit<TaskListSubscriptionParameters, "taskListType">
    ) {
        this.safeSubscribe(
            "pending-task-list",
            await TaskSubscriptions.taskList(
                {
                    ...params,
                    taskListType: TaskListSectionModel.PENDING,
                },
                SubsManager.handlerPendingTaskList
            )
        );
    }

    async subscribeAllTasklist(projectId: string) {
      console.log('subscribing to all task list')
      this.safeSubscribe(
          "all-task-list",
          TaskSubscriptions.completeTaskList(projectId, SubsManager.handlerAllTaskList)
      )
    }

    subscribeTaskSnapshot(dataVersionId: string, contractId: string) {
        this.safeSubscribe(
            "task-snapshot",
            TaskSubscriptions.taskSnapshot(dataVersionId, contractId, SubsManager.handlerTaskSnapshot)
        );
    }

    subscribeNextSnapshot(dataVersionId: string, contractId: string) {
        this.safeSubscribe(
            "next-task-snapshot",
            TaskSubscriptions.taskSnapshot(dataVersionId, contractId, SubsManager.handlerNextSnapshot)
        );
    }

    subscribeDataVersions(contractId: string) {
        this.safeSubscribe(
            "data-versions",
            ProjectSubscriptions.getDataVersions(contractId, SubsManager.handlerDataVersions)
        );
    }

    subscribeSprintData(projectId: string) {
        this.safeSubscribe(
            "sprint-data",
            ProjectSubscriptions.getSprintData(projectId, SubsManager.handlerSprintData)
        );
    }

  subscribeSuccessorsList(project_id: string, taskId: string) {
    this.safeSubscribe(
      "successors-list",
      TaskSubscriptions.successorsList(
        {
          project_id,
          taskId,
        },
        SubsManager.handlerSuccessorsList
      )
    );
  }

  subscribePredecessorsList(project_id: string, taskId: string) {
    this.safeSubscribe(
      "predecessors-list",
      TaskSubscriptions.predecessorsList(
        {
          project_id,
          taskId,
        },
        SubsManager.handlerPredecessorsList
      )
    );
  }

  subscribeLedgerList(taskId: string) {
    this.safeSubscribe(
      "ledger-list",
      LedgerSubscriptions.ledgerList(taskId,
          store.getState().project.activeProject!.projectId,
          SubsManager.handlerLedgerList)
    );
  }

  subscribeRecentLedgerList(userId: string, projectId: string) {
    this.safeSubscribe(
      "recent-ledger-list",
      LedgerSubscriptions.recentLedgerList(userId, projectId, SubsManager.handlerRecentLedgerList)
    );
  }

  subscribeTrackedMilestones(projectId: string) {
    this.safeSubscribe(
      "tracked-milestones",
      ProjectSubscriptions.trackedMilestones(
        projectId,
        SubsManager.handlerTrackedMilestones
      )
    );
  }

  subscribeGeoData(projectId: string) {
    this.safeSubscribe(
      "geo-data",
      ProjectSubscriptions.getGeoData(
        projectId,
        SubsManager.handlerGeoData
      )
    );
  }

  subscribeRelationshipsList(projectId: string) {
    this.safeSubscribe(
      "relationships-list",
      RelationshipsSubscriptions.relationshipsList(
        projectId,
        SubsManager.handlerRelationshipsList
      )
    );
  }

  subscribeProjectPermission(userId: string | null) {
    this.safeSubscribe(
      "project-permissions",
      MemberSubscriptions.projectPermission(
        userId,
        SubsManager.handlerProjectPermissions
      )
    );
  }

  async subscribeMemberList(projectId: string) {
    this.safeSubscribe(
      "member-list",
      await MemberSubscriptions.memberList(projectId, SubsManager.handlerMemberList)
    );
  };

  subscribeProjectMember(projectId: string, userId: string) {
    this.safeSubscribe(
      "project-member",
      MemberSubscriptions.projectMember(projectId, userId, SubsManager.handlerProjectMember)
    );
  }

    subscribePendingMemberList(projectId: string) {
        this.safeSubscribe(
            "pending-members",
            MemberSubscriptions.pendingMembers(projectId, SubsManager.handlerPendingMemberList)
        );
    }

  subscribeReportList(projectId: string) {
    this.safeSubscribe(
      "report-list",
      MemberSubscriptions.reportList(projectId, SubsManager.handlerReportList)
    );
  }

  subscribeEventLogList(taskId: string) {
    this.safeSubscribe(
      "event-log-list",
      EventLogSubscriptions.eventLogList(
        taskId,
        store.getState().project.activeProject?.projectId,
        SubsManager.handlerEventLogList
      )
    );
  }

  removeSubscription(type: AvailableSubscriptions) {
    const unsubscribe = this.unsubscribe.get(type);
    if (unsubscribe) {
        try {
            unsubscribe();
        } catch (e) {
        }
        this.unsubscribe.delete(type);
    }
  }

  removeTaskListSubscription(type: AvailableSubscriptions) {
    const unsubscribe = this.unsubscribe.get(type);
    if (unsubscribe) {
      unsubscribe()
      this.unsubscribe.delete(type);
    }
  }

  removeAllSubscriptions() {
    this.unsubscribe.forEach((u, key) => this.removeSubscription(key));
  }
}

export const subsManager = new SubsManager();
