import FirebaseUsage from "../../../../firebase/firebase.usage";
import {COLLECTIONS} from "../../../../firebase/constants";
import checkRelationValidity from "./checkLinkValidity";
import TaskStatusModel from "../../../../models/responses/task-status.model";
import {CpmTaskModel} from "../../../../models/responses/cpm-task.model";

export default async function generateCpmTask(
    calendarsMap: Map<any, any>,
    task,
    cpmMap,
    latestDate,
    calendarDict: any,
) {

    const tasksMap: Map<string, any> = new Map(cpmMap)
    const timeStart = new Date().getTime() / 1000
    const dataDate = Math.floor(timeStart / 86400) * 86400

    const masterCalendarDict = (seconds, calendarId) => {
        return parseInt(calendarsMap.get(`mcd:${seconds}:${calendarId}`))
    }

    const masterWorkPatternDict = (index, calendarId) => {
        return parseInt(calendarsMap.get(`mwp:${index}:${calendarId}`))
    }

    function convertDateToIndex(date, calendarId) {
        let index = masterCalendarDict(date, calendarId)
        if (isNaN(index)) {
            return convertOutOfRangeDateToIndex(date, calendarId)
        }
        return index
    }

    function convertOutOfRangeDateToIndex(date, calendarId) {
        const maxDate = parseInt(calendarsMap.get(`swp:maxDate:${calendarId}`))
        const maxIndex = parseInt(calendarsMap.get(`swp:maxIndex:${calendarId}`))
        const halfHours = parseInt(calendarsMap.get(`swp:wi:count:${calendarId}`))
        const weeks = Math.floor((date - maxDate) / 604800)
        const maxWeekIndex = parseInt(calendarsMap.get(`swp:maxWeekIndex:${calendarId}`))
        const relativeMaxWeekIndex = masterWorkPatternDict(maxWeekIndex, calendarId)
        const remainingHalfHours = Math.floor((date - maxDate) / 1800) - (weeks * 336)
        const newMasterIndex = remainingHalfHours + maxWeekIndex > 336 ? (remainingHalfHours + maxWeekIndex) - 336 : remainingHalfHours + maxWeekIndex
        const newCalendarSpecificIndex = masterWorkPatternDict(newMasterIndex, calendarId)

        return remainingHalfHours + maxWeekIndex > 336 ? maxIndex + (weeks * halfHours) + (halfHours - relativeMaxWeekIndex) + (newCalendarSpecificIndex) :
            maxIndex + (weeks * halfHours) + (newCalendarSpecificIndex - relativeMaxWeekIndex)
    }


    const handleConstraint = {
        'CS_MSOA': ['s', 'y', 'n'],
        'CS_MEO': ['f', 'y', 'y'],
        'CS_MSO': ['s', 'y', 'y'],
        'CS_MEOA': ['f', 'y', 'n'],
        'CS_MEOB': ['f', 'n', 'y'],
        'CS_MSOB': ['s', 'n', 'y'],
        'CS_MANDFIN': ['f', 'y', 'y'],
        'CS_MANDSTART': ['s', 'y', 'y'],
        'CS_ALAP': ['f', 'n', 'n'],
        'RESUME': ['s', 'y', 'n'],
    }

    const handleTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 1,
        'TT_Mile': 0,
        'TT_Task': 0,
        'TT_Rsrc': 0,
        'TT_WBS': 0,
        'TT_TASK': 0,
        'TT_RSRC': 0,
        'TT_MILE': 0,
        'TT_FINMILE': 1,
    }

    const handleWorkingTypes = {
        'TT_LOE': 0,
        'TT_FinMile': 0,
        'TT_Mile': 0,
        'TT_Task': 1,
        'TT_Rsrc': 1,
        'TT_WBS': 0,
        'TT_TASK': 1,
        'TT_RSRC': 1,
        'TT_MILE': 0,
        'TT_FINMILE': 0,
    }

    task.calendar_id = calendarDict[task.calendar_id]
    const dataDateIndex = convertDateToIndex(dataDate, task.calendar_id)
    const latestIndex = convertDateToIndex(latestDate, task.calendar_id)

    console.log(dataDateIndex, latestIndex)

    function getActualStart(task) {
        if (!task.act_start_date) {
            return dataDateIndex
        } else {
            const actualStart = new Date(task.act_start_date.seconds * 1000)
            return convertDateToIndex(Math.floor(((actualStart.getTime() - (actualStart.getTimezoneOffset() * 60000)) / 1000) / 1800) * 1800, task.calendar_id)
        }
    }

    function getActualEnd(task) {
        if (!task.act_end_date) {
            return dataDateIndex + parseInt(task.targetDuration)
        } else {
            const actualEnd = new Date(task.act_end_date.seconds * 1000)
            const endOffset = task.act_end_date.seconds !== task.act_start_date.seconds ? 1800 * 1000 : 0
            return convertDateToIndex(Math.floor(((actualEnd.getTime() - (actualEnd.getTimezoneOffset() * 60000) - endOffset) / 1000) / 1800) * 1800, task.calendar_id)
        }
    }

    function lookForStartConstraints(task) {
        if (task.constraint_type === '') {
            return convertDateToIndex(dataDate, task.calendar_id)
        } else {
            const recalcDate = new Date(dataDate * 1000)
            const cstr = handleConstraint[task.constraint_type]
            if (cstr[1] === 'y') {
                if (cstr[0] === 's') {
                    const cstrDate = task.constraint_date.toDate()
                    return Math.max(convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id),
                        convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id))
                } else {
                    const cstrDate = task.constraint_date.toDate()
                    return Math.max(convertDateToIndex(Math.floor(cstrDate.getTime()/ 1800000) * 1800, task.calendar_id) -
                        Math.round(parseFloat(task.remainingDuration) * 2),
                        convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id))
                }
            } else {
                return convertDateToIndex(Math.floor(recalcDate.getTime() / 1800000) * 1800, task.calendar_id)
            }
        }
    }

    function lookForFinishConstraints(task) {
        if (task.constraint_type === '') {
            return ''
        } else {
            const cstr = handleConstraint[task.constraint_type]
            if (cstr[2] === 'y') {
                const cstrDate = task.constraint_date.toDate()
                if (cstr[0] === 'f') {
                    return convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id) - 1
                } else {
                    return convertDateToIndex(Math.floor(cstrDate.getTime() / 1800000) * 1800, task.calendar_id) -
                        Math.round(parseFloat(task.remainingDuration) * 2) - 1
                }
            } else {
                return ''
            }
        }
    }

    let preds: any[] = []
    let succs: any[] = []
    let p_cnt = 0
    let s_cnt = 0

    task.relations.forEach(relation => {
        if (checkRelationValidity(relation.task_id === task.task_id ?
                task :
                tasksMap.get(relation.task_id),
            relation.pred_task_id === task.task_id ?
                task :
                tasksMap.get(relation.pred_task_id),
            relation)) {
            const relationId = `${relation.pred_task_id}:${relation.task_id}:${relation.pred_type}`
            tasksMap.set(relationId, {
                task_id: relation.task_id,
                link_id: relationId,
                pred_task_id: relation.pred_task_id,
                pred_type: relation.pred_type,
                lag_hr_cnt: relation.lag,
                ad: '',
                af: '',
            })
            if (relation.task_id === task.task_id) {
                preds.push(relationId)
                p_cnt += 1
            } else {
                succs.push(relationId)
                s_cnt += 1
            }
            if (relation.task_id === task.task_id) {
                let predTask = tasksMap.get(relation.pred_task_id)
                predTask.s_cnt += 1
                let predTaskSuc = tasksMap.get(`${relation.pred_task_id}:succs`)
                predTaskSuc.push(relationId)
                tasksMap.set(relation.pred_task_id, predTask)
                tasksMap.set(`${relation.pred_task_id}:succs`, predTaskSuc)
            } else {
                let succTask = tasksMap.get(relation.task_id)
                succTask.p_cnt += 1
                let succTaskPred = tasksMap.get(`${relation.task_id}:preds`)
                succTaskPred.push(relationId)
                tasksMap.set(relation.task_id, succTask)
                tasksMap.set(`${relation.task_id}:preds`, succTaskPred)
            }
        } else {
            const relationId = `${relation.pred_task_id}:${relation.task_id}:${relation.pred_type}`
            tasksMap.set(relationId, {
                task_id: relation.task_id,
                link_id: relationId,
                pred_task_id: relation.pred_task_id,
                pred_type: relation.pred_type,
                lag_hr_cnt: relation.lag,
                ad: '',
                af: '',
            })
            if (relation.task_id === task.task_id) {
                preds.push(relationId)
                // p_cnt += 1
            } else {
                // succs.push(relationId)
                s_cnt += 1
            }
        }
    })
    tasksMap.set(`${task.task_id}:preds`, preds)
    tasksMap.set(`${task.task_id}:succs`, succs)

    let cpmTask: CpmTaskModel = {
        task_code: task.task_id,
        es: getActualStart(task),
        ef: getActualEnd(task),
        ls: '',
        lf: '',
        ad: lookForStartConstraints(task) - handleTypes[task.task_type],
        af: lookForFinishConstraints(task),
        alap: '',
        p6_id: task.task_code,
        task_type: task.task_type,
        status_code: task.status,
        cal_id: task.calendar_id,
        duration: task.task_type !== "TT_LOE" ? parseInt(task.remainingDuration) : 0,
        di: 1,
        p_cnt: p_cnt,
        s_cnt: s_cnt,
        ec: task.constraint_type !== "" ? handleConstraint[task.constraint_type][2] === 'y' ? lookForFinishConstraints(task) : 'n' : 'n',
        cstr_type: task.constraint_type,
        p_sv: p_cnt,
        s_sv: s_cnt,
        task_name: `${task.task_code} - ${task.task_name}`,
        wbs: task.wbs,
        pi: task.predIndex,
        doc_ref: task.docRef,
        predStatus: task.predStatus,
    }

    console.log({...cpmTask})

    tasksMap.set(task.task_id, cpmTask)

    return tasksMap
}